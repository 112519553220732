import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';
import { backgroundCheckReportCheckerGetReportRequest } from '../../../../redux/actions/products/backgroundCheck/reportChecker';
import NotificationToast from '../../../../components/utils/notifToast';
import { Spinner } from 'react-bootstrap';
import moment from 'moment';
import logo from "../../../../assets/logo.png"
// import { replaceUnderscores } from '../../../../components/utils';

export default function BackgroundCheckReportCheckerPage() {
    const [notifVal, setNotifVal] = useState(false)
    const [notif, setNotif] = useState("")
    const [notifTitle, setNotifTitle] = useState("")
    const [refInput, setRefInput] = useState("")
    const [checkStatus, setCheckStatus] = useState(false)
    // const [checkData, setCheckData] = useState(
    //     {detail:
    //         {
    //             "candidate_name": "hhk ius hkd",
    //             "candidate_email": "jssi@test.com",
    //             "candidate_phone_number": "9090989899",
    //             "profile_img": "https://prembly-main-bucket-log.s3.amazonaws.com/67ba8706-e42.png",
    //             "verified": true,
    //             "report_package":"sample package",
    //             "report_pdf_url": "https://prembly-main-bucket-log.s3.amazonaws.com/4523e250-e635-4bb3-9c8d-03afb51320d7.pdf",
    //             "type_of_check": "drivers license",
    //             "date": "2024-09-27T13:01:15.044Z"
    //         }
    //     }
    // )


    const reportCheckerGetReportState = useSelector((state: RootState) => state.backgroundCheckReportCheckerGetReportReducer);

    const dispatch = useDispatch()


    let getRefReport = (e: any) => {
        e.preventDefault()
        if (!refInput) {
            setNotifTitle("Error")
            setNotif("Please input a reference ID")
            setNotifVal(true)
            return
        }

        const callback = (data: any) => {
            if (data.status) {
                setCheckStatus(true)
            }
            else {
                setNotifTitle("Error")
                setNotif(data.detail)
                setNotifVal(true)
            }
        };
        let data: any = {
            values: {
                reference: refInput
            },
            callback,
        };
        dispatch(backgroundCheckReportCheckerGetReportRequest(data))
    }

    console.log(reportCheckerGetReportState)
    return (
        <div>
            {notif && notifVal && (
                <NotificationToast
                    title={notifTitle}
                    message={notif}
                    closeNotif={() => setNotifVal(!notifVal)}
                />
            )}
            <div className="container py-4">
                <div className='pb-5' style={{ width: '130px'}}>
                    <img src={logo} alt="" className="w-100" />
                </div>

                {!checkStatus &&
                <div className="d-flex align-items-center justify-content-center" style={{height:"75vh"}}>
                    <div className='col-md-6 mx-auto text-center'>
                        <h3 style={{color:"#0489E5"}}>Report Checker</h3>
                        <form onSubmit={getRefReport}>
                            <div className="input-group my-4">
                                <input style={{ borderRadius: "30px 0 0 30px", borderRight: "none", border: "1px solid #0489E5" }}
                                    type="text" className="form-control" onChange={e => setRefInput(e.target.value)}
                                    disabled={reportCheckerGetReportState?.isLoading}
                                />
                                <div className="input-group-append pt-3 pb-2"
                                    style={{
                                        borderRadius: "0 30px 30px 0",
                                        // borderRight:"none", 
                                        border: "1px solid #0489E5",
                                        color: "#0489E5"
                                        // height: "50px" 
                                    }}
                                >
                                    {reportCheckerGetReportState?.isLoading ?
                                        <div className='px-3 pb-2'>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        </div>
                                        :
                                        <span className='px-3' onClick={getRefReport} >
                                            <i className='ri-search-line ri-xl' />
                                        </span>
                                    }
                                </div>
                            </div>
                        </form>
                        

                        {reportCheckerGetReportState?.error &&
                            <div className='mb-5'>
                                <p style={{color:"red"}}>{reportCheckerGetReportState?.error}</p>
                            </div>
                        }


                        <a href="https://api.whatsapp.com/send?phone=254796111020&text=Hello%2C%20I%27m%20from%20the%20Prembly%20website%20and%20I%20have%20an%20enquiry" 
                            className='btn btn-green py-2' target="_blank" rel="noreferrer"
                        >
                            For Customer Support, Click here to chat with us on Whatsapp
                        </a>
                    </div>
                </div>
                }


                {(checkStatus && !reportCheckerGetReportState?.isLoading) &&
                    <div className='col-md-5 mx-auto'>
                        <div className="text-center mb-5">
                            <img src={reportCheckerGetReportState?.resp?.detail?.profile_img}
                                className='mx-auto' width="200px" alt="" style={{ borderRadius: "50%" }}
                            />
                        </div>

                        <div className="row mb-3" style={{ borderBottom: "1px solid #0489E5" }}>
                            <div className="col-md-6">
                                <h6 style={{color:"#0489E5"}}>Name</h6>
                            </div>
                            <div className="col-md-6 text-md-end">
                                <p>{reportCheckerGetReportState?.resp?.detail?.candidate_name}</p>
                            </div>
                        </div>
                        <div className="row mb-3" style={{ borderBottom: "1px solid #0489E5" }}>
                            <div className="col-md-6">
                                <h6 style={{color:"#0489E5"}}>Date</h6>
                            </div>
                            <div className="col-md-6 text-md-end">
                                <p>{moment.utc(reportCheckerGetReportState?.resp?.detail?.date).format('lll')}</p>
                            </div>
                        </div>
                        <div className="row mb-3" style={{ borderBottom: "1px solid #0489E5" }}>
                            <div className="col-md-6">
                                <h6 style={{color:"#0489E5"}}>Report Package</h6>
                            </div>
                            <div className="col-md-6 text-md-end">
                                <p>{reportCheckerGetReportState?.resp?.detail?.report_package}</p>
                            </div>
                        </div>
                        <div className="row mb-3" style={{ borderBottom: "1px solid #0489E5" }}>
                            <div className="col-md-6">
                                <h6 style={{color:"#0489E5"}}>Report Status</h6>
                            </div>
                            <div className="col-md-6 text-md-end">
                                {reportCheckerGetReportState?.resp?.detail?.verified &&
                                    <div className='d-flex justify-content-end align-items-center'>
                                        <div className='me-3 mb-3' style={{borderRadius:"50%", width:10, height:10, background:"green"}}></div>
                                        <p>Verified</p>
                                    </div>
                                }

                                {!reportCheckerGetReportState?.resp?.detail?.verified &&
                                    <div className='d-flex justify-content-end align-items-center'>
                                        <div className='me-3 mb-3' style={{borderRadius:"50%", width:10, height:10, background:"red"}}></div>
                                        <p>Unverified</p>
                                    </div>
                                }
                            </div>
                        </div>

                        <a className='btn btn-green' href={reportCheckerGetReportState?.resp?.detail?.report_pdf_url}
                            download
                        >Download Report</a>
                    </div>
                }
            </div>
        </div>
    )
}