import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Mainloader, { AlertTag, CompletedTag, EmptyStateComp, InProgressTag } from '../../../../utils'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import NotificationToast from '../../../../utils/notifToast';
import { backgroundCheckRequestFileUploadRequest, backgroundCheckRequestReportAdjudicationRequest, backgroundCheckRequestReportDownloadRequest, backgroundCheckRequestReportOverviewRequest, backgroundCheckRequestReportSetStatusRequest } from '../../../../../redux/actions/products/backgroundCheck/request/checks';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/reducers';
import CandidateDetailedReportPage from './detailedReport';
import avatar from '../../../../../assets/avatar.png'
import { Spinner } from 'react-bootstrap';

export default function CandidateReportPage(props: any) {
    const [notifVal, setNotifVal] = useState(false)
    const [notif, setNotif] = useState("")
    const [notifTitle, setNotifTitle] = useState("")
    const [checkStatus, setCheckStatus] = useState("")
    const [stopCheckModal, setStopCheckModal] = useState(false)
    const [downloadModal, setDownloadModal] = useState(false)
    const [reportPage, setReportPage] = useState(1)
    const [merchantAdjudicationStatus, setMerchantAdjudicationStatus] = useState("")
    const [merchantAdjudicationComment, setMerchantAdjudicationComment] = useState("")
    const [merchantAdjudicationCandStat, setMerchantAdjudicationCandStat] = useState("")
    const [doc, setDoc] = useState("")
    const [docData, setDocData] = useState<any>(null)
    const [adjudicationCofirmModal, setAdjudicationCofirmModal] = useState(false)

    const reportOverviewState = useSelector((state: RootState) => state.backgroundCheckRequestReportOverviewReducer);
    const checkStatusState = useSelector((state: RootState) => state.backgroundCheckRequestReportSetStatusReducer);
    const reportChecklistState = useSelector((state: RootState) => state.backgroundCheckRequestReportChecklistReducer);
    const reportDetailState = useSelector((state: RootState) => state.backgroundCheckRequestReportDetailReducer);
    const reportDownloadState = useSelector((state: RootState) => state.backgroundCheckRequestReportDownloadReducer)
    const uploadFileState = useSelector((state: RootState) => state.backgroundCheckRequestFileUploadReducer)
    const reportAdjudicationState = useSelector((state: RootState) => state.backgroundCheckRequestReportAdjudicationReducer)


    const dispatch = useDispatch()
    const { ref } = useParams();

    useEffect(() => {
        getReportSummary()
    }, [])


    let getReportSummary = () => {
        const callback = (data: any) => {
            if (!data.status) {
                setNotifTitle("Error")
                setNotif(data.detail)
                setNotifVal(true)
            }
        };
        let data: any = {
            values: {
                request_id: ref,
            },
            callback,
        };
        dispatch(backgroundCheckRequestReportOverviewRequest(data))
    }


    let changeCheckStatus = () => {
        const callback = (data: any) => {
            if (data.status) {
                setNotifTitle("Success")
                setNotif(`You have successfully ${checkStatus === "true" ? "stopped" : "continued"} this check `)
                setNotifVal(true)
                setStopCheckModal(false)
                getReportSummary()
            }
            else {
                setNotifTitle("Error")
                setNotif(data.detail)
                setNotifVal(true)
            }
        };
        let data: any = {
            values: {
                request_id: ref,
                checkStatus: checkStatus === "true" ? "stop" : "start"
            },
            callback,
        };
        dispatch(backgroundCheckRequestReportSetStatusRequest(data))
    }

    let changePage = (page: any) => {
        setReportPage(page)
    }


    let getReportRequestDownload  = () => {
        const callback = (data: any) => {
            if (data.status) {
                downloadFunc(data?.detail)
            }
            else {
                setNotifTitle('Error')
                setNotif(data.detail)
                setNotifVal(true)
            }
        }
        let data: any = {
            values: {
                request_id: ref,
            },
            callback,
        }
        dispatch(backgroundCheckRequestReportDownloadRequest(data))
    }


    let downloadFunc = (val: string) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = val;
        downloadLink.download = `bgc-report`;
        document.body.appendChild(downloadLink)
        downloadLink.click()
        setTimeout(() => {
            document.body.removeChild(downloadLink)
            // window.URL.revokeObjectURL(data.url);
        }, 100);
    }


    let uploadDoc = (val:any) => {
        var file = val?.target?.files[0]
        let reader = new FileReader()

        if (val?.target?.files?.length > 0) {
            reader.readAsDataURL(file)
            reader.onload = () => {
                let imgBase: any = reader.result
                if (file && (
                    (file.type !== "image/png") &&
                    (file.type !== "image/jpeg") &&
                    (file.type !== "image/jpg") &&
                    (file?.type !== "application/pdf")
                )) {
                    setNotifTitle('Error')
                    setNotif(`Unsupported Document format Uploaded`)
                    setNotifVal(true)
                    // return
                }
                else if (file && (file?.size > 15200000)) {
                    setNotifTitle('Error')
                    setNotif(`Document is larger than 15MB`)
                    setNotifVal(true)

                    setTimeout(() => {
                        setNotif("")
                    }, 3000);
                    // return
                }
                else {
                    const callback = (data: any) => {
                        if (data?.status) {
                            setDocData(file)
                            setDoc(data?.detail)
                        }
                        else {
                            setNotifTitle("Error")
                            setNotif(data.detail)
                            setNotifVal(true)
                        }
                    };
                    let data: any = {
                        values: {
                            file: imgBase,
                        },
                        callback,
                    };
                    dispatch(backgroundCheckRequestFileUploadRequest(data))
                }
            }
        }
    }

    let validateAdjudication  = () => {
        if(!merchantAdjudicationStatus){
            setNotifTitle('Error')
            setNotif("Please Accept or Reject this Check status")
            setNotifVal(true)
            return
        }
        if(!merchantAdjudicationCandStat){
            setNotifTitle('Error')
            setNotif("Please Accept or Reject this Check status")
            setNotifVal(true)
            return
        }
        if(!merchantAdjudicationComment){
            setNotifTitle('Error')
            setNotif("Please Select Adjudicated candidate status")
            setNotifVal(true)
            return
        }
        if( merchantAdjudicationStatus === "REJECTED" && !doc){
            setNotifTitle('Error')
            setNotif("Upload a document to support your claim")
            setNotifVal(true)
            return
        }
        setAdjudicationCofirmModal(true)
    }
    
    let submitAdjudication  = () => {
        const callback = (data: any) => {
            if (data.status) {
                setNotifTitle('Success')
                setNotif("Adjudication Successfully updated")
                setNotifVal(true)
                getReportSummary()
                setMerchantAdjudicationStatus("")
                setMerchantAdjudicationComment("")
                setDoc("")
                setDocData(null)
                setAdjudicationCofirmModal(false)
            }
            else {
                setNotifTitle('Error')
                setNotif(data.detail)
                setNotifVal(true)
            }
        }
        let data: any = {
            values: {
                request_id: ref,
                merchant_adjudication_status: merchantAdjudicationStatus,
                merchant_adjudication_comment: merchantAdjudicationComment,
                merchant_adjudication_criteria: merchantAdjudicationCandStat,
                adjudication_supporting_document: doc
            },
            callback,
        }
        dispatch(backgroundCheckRequestReportAdjudicationRequest(data))
    }

    // console.log(reportOverviewState)


    return (
        <div className='candidate-report-area'>

            {(notif && notifVal) && <NotificationToast title={notifTitle} message={notif} closeNotif={() => setNotifVal(!notifVal)} />}

            {downloadModal &&
                <div className="main-modal ">
                    <div className="main-modal-content card col-md-5 col-lg-3 mx-auto">
                        <div className="card-body">
                            <div className="main-modal-body icon-modal-body text-center">

                                <i className="ri-logout-circle-line modal-back-button" onClick={() => setDownloadModal(false)} />

                                <div className='mt-3'>
                                    <p>You will be able to view a full report on this check after the download.</p>
                                    <p>Do you want to download the full report of this check ?</p>

                                    <button className='btn btn-deep-green w-100 mt-3' onClick={() => setDownloadModal(false)}>No</button>
                                    <a className='btn btn-deep-green-outline w-100 mt-3' href='' download>
                                        Yes
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            }

            {stopCheckModal &&
                <div className="main-modal ">
                    <div className="main-modal-content card col-md-5 col-lg-3 mx-auto">
                        <div className="card-body">
                            <div className="main-modal-body icon-modal-body text-center">

                                <i className="ri-logout-circle-line modal-back-button" onClick={() => setStopCheckModal(false)} />

                                <div className='mt-3'>
                                    <p>This will {checkStatus === "true" ? "stop" : "continue"}  all pending check and you will {checkStatus === "true" ? "not be charged for incomplete checks" : "now be charged for your checks"}</p>
                                    <p>Do you want to {checkStatus === "true" ? "stop" : "continue"} this check ?</p>

                                    <button className='btn btn-deep-green w-100 mt-3' onClick={() => setStopCheckModal(false)}>No</button>
                                    <button className='btn btn-deep-green-outline w-100 mt-3' onClick={changeCheckStatus}>
                                        {checkStatusState?.isLoading ? (
                                            <div>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        ) : (
                                            'Yes'
                                        )}

                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            }

            {adjudicationCofirmModal &&
                <div className="main-modal ">
                    <div className="main-modal-content card col-md-5 col-lg-3 mx-auto">
                        <div className="card-body">
                            <div className="main-modal-body icon-modal-body text-center">

                                <i className="ri-logout-circle-line modal-back-button" 
                                    onClick={() => setAdjudicationCofirmModal(false)} 
                                />

                                <div className='mt-3'>
                                    <h5>Please confirm your decision.</h5>
                                    <p> Once you select "Proceed," the adjudication cannot be changed.</p>

                                    <button className='btn btn-deep-green w-100 mt-3' 
                                        disabled={reportAdjudicationState?.isLoading} 
                                        onClick={submitAdjudication}
                                    >
                                        {reportAdjudicationState?.isLoading ? (
                                            <div>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        ) : (
                                            'Proceed'
                                        )}
                                    </button>
                                    <button className='btn btn-deep-green-outline w-100 mt-3' 
                                        disabled={reportAdjudicationState?.isLoading} 
                                        onClick={() => setAdjudicationCofirmModal(false)} 
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            }

            {(reportOverviewState?.isLoading || reportChecklistState?.isLoading || reportDetailState?.isLoading) && <Mainloader />}

            {(!reportOverviewState?.isLoading && !reportChecklistState?.isLoading && !reportDetailState?.isLoading) &&
                <div className="container-fluid px-md-4 mt-4">
                    <div className="table-header">
                        <div className="row justi">
                            <div className="col-md-4">
                                <div className="pb-2">
                                    <h5>Candidate Report</h5>
                                    <p>View your candidates reports here</p>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="pb-2">

                                    {/* <button className='btn border d-flex align-items-center ms-auto'>
                                    <i className='ri-download-2-line me-3'/>
                                    Download Report
                                </button> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    {reportPage === 1 &&
                        <>
                            <div className='row'>
                                <div className="col-md-6">
                                    <p className="d-flex align-items-center mt-4">
                                        <Link to="/BackgroundCheck/Requests" className='link'>
                                            <span style={{ cursor: 'pointer' }}>
                                                Request
                                            </span>
                                        </Link>
                                        {' '}
                                        <i className="ri-arrow-right-s-line ri-xl" />
                                        <span style={{ fontWeight: "bold" }}> Candidate Report</span>
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    {(reportOverviewState?.resp?.detail && Object.keys(reportOverviewState?.resp?.detail)?.length > 0) &&
                                        <div className="text-end mt-3">
                                            {/* <button className='btn btn-green me-3'
                                                onClick={() => setReportPage(2)}
                                            > View full report</button> */}
                                            {/* <button className='btn btn-green me-3'
                                            onClick={() => setDownloadModal(true)}
                                        > Download full report</button> */}

                                            {reportOverviewState?.resp?.detail?.is_active &&
                                                <button className='btn btn-danger'
                                                    onClick={() => { setStopCheckModal(true); setCheckStatus(reportOverviewState?.resp?.detail?.is_active?.toString()) }}
                                                > Stop Check</button>
                                            }
                                            {(reportOverviewState?.resp?.detail?.is_active === false) &&
                                                <button className='btn btn-green'
                                                    onClick={() => { setStopCheckModal(true); setCheckStatus(reportOverviewState?.resp?.detail?.is_active?.toString()) }}
                                                > Continue Check</button>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>


                            {(!reportOverviewState?.isLoading && reportOverviewState?.resp?.detail && Object.keys(reportOverviewState?.resp?.detail)?.length < 1) &&
                                <div className="my-5 py">
                                    <EmptyStateComp title={"No report yet"}
                                        ctaAction={() => { }}
                                        desc={"There is no report for this candidate yet"}
                                        ctaValue={""}
                                    />
                                </div>
                            }


                            {(!reportOverviewState?.isLoading && reportOverviewState?.resp?.detail && Object.keys(reportOverviewState?.resp?.detail)?.length > 0) &&
                                <>
                                    <div className="card mt-4">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="text-center">
                                                        <img src={reportOverviewState?.resp?.detail?.bio?.user_image || avatar} alt="" className='' style={{ borderRadius: "50%", height: "140px", width: "140px" }} />
                                                        <h6 className='pt-2'>
                                                            {`${reportOverviewState?.resp?.detail?.bio?.first_name} ${reportOverviewState?.resp?.detail?.bio?.last_name}`}
                                                        </h6>
                                                        {/* <p className=''>
                                                            {reportOverviewState?.resp?.detail?.bio?.phone_number}
                                                        </p> */}
                                                        <small className='p-2 rounded text-white' style={{ background: "#0489E5" }}>{reportOverviewState?.resp?.detail?.bio?.email}</small>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div style={{ width: "200px" }} className='mx-auto'>
                                                        <CircularProgressbar
                                                            maxValue={100}
                                                            strokeWidth={20}
                                                            value={reportOverviewState?.resp?.detail?.percentage || 0}
                                                            backgroundPadding={0}
                                                            text={`${reportOverviewState?.resp?.detail?.percentage || 0}%`}
                                                            styles={buildStyles({
                                                                rotation: 1.0,
                                                                strokeLinecap: 'butt',
                                                                textSize: '12px',
                                                                pathTransitionDuration: 0.5,
                                                                // pathColor: `rgba(62, 152, 199, ${reportOverviewState?.resp?.detail?.percentage / 100})`,
                                                                pathColor: `rgba(62, 152, 199, .9)`,
                                                                textColor: '#000',
                                                                // trailColor: '#58BEBF',
                                                                // trailColor: '#3e98c7',
                                                                //   backgroundColor: '#3e98c7',
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="table-responsive mt-5">
                                        <table className="table">
                                            <thead className="">
                                                <tr>
                                                    <th scope="col">Check</th>
                                                    <th scope="col">Details</th>
                                                    <th scope="col">Date Completed</th>
                                                    <th scope="col">Check Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {reportOverviewState?.resp?.detail?.checks?.map((val: any, index: number) => (
                                                    <tr>
                                                        <th scope="row">{val?.check}</th>
                                                        <td>{val?.details}</td>
                                                        <td>{val?.date_completed}</td>
                                                        <td>
                                                            {val?.status === "ALERT" && <AlertTag />}
                                                            {val?.status === "IN PROGRESS" && <InProgressTag />}
                                                            {val?.status === "COMPLETED" && <CompletedTag />}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div> */}

                                    {((reportOverviewState?.resp?.detail?.request_status === "COMPLETED") ||
                                    (reportOverviewState?.resp?.detail?.request_status === "IN PROGRESS") ||
                                    (reportOverviewState?.resp?.detail?.request_status === "IN-PROGRESS") ||
                                    (reportOverviewState?.resp?.detail?.request_status === "PROCESSING")) &&


                                    <div className={`card my-4 ${(reportOverviewState?.resp?.detail?.request_status === "COMPLETED" && reportOverviewState?.resp?.detail?.adjudication_status ) && "border-0"}`}>
                                        {/* <div className="card my-4"> */}
                                            <div className="card-body">
                                                
                                                <div className="my-3 col-md-7 mx-auto">
                                                    {(reportOverviewState?.resp?.detail?.request_status === "COMPLETED" && reportOverviewState?.resp?.detail?.adjudication_status ) &&
                                                        <div>
                                                            <div>
                                                                <h6>Comment</h6>
                                                                <div className="d-flex align-items-center flex-wrap mb-2">
                                                                    <small className='me-2'>Candidate Status: </small>
                                                                    <p className='mb-0' style={{color: reportOverviewState?.resp?.detail?.adjudication_status ===  "Does not meet company's standards" ? "#F51A0A" :
                                                                            reportOverviewState?.resp?.detail?.adjudication_status ===  "Meets company standards" ? "#039855" :
                                                                            reportOverviewState?.resp?.detail?.adjudication_status ===  "Client review require" ? "#945605" :
                                                                            reportOverviewState?.resp?.detail?.adjudication_status ===  "Pending-Potential conflict" ? "#2E2AB7" :
                                                                            reportOverviewState?.resp?.detail?.adjudication_status ===  "Cancelled" ? "#4A4A4A" : ""
                                                                        }}
                                                                    > {reportOverviewState?.resp?.detail?.adjudication_status}</p>
                                                                </div>
                                                                {reportOverviewState?.resp?.detail?.comment &&
                                                                    <div className='p-3' style={{backgroundColor:"#F5FBFF"}}>
                                                                        <p>
                                                                            {reportOverviewState?.resp?.detail?.comment}
                                                                        </p>
                                                                    </div>
                                                                }

                                                                <hr />
                                                            </div>
                                                            
                                                            {(reportOverviewState?.resp?.detail?.merchant_adjudication_status &&
                                                                reportOverviewState?.resp?.detail?.merchant_adjudication_status !== "PENDING"
                                                             )&&
                                                                <div className='my-5'>
                                                                    <h6>Adjudication</h6>
                                                                    <div className="d-flex align-items-center flex-wrap mb-2">
                                                                        <small className='me-2'>Adjudication Status: </small>
                                                                        <p className='mb-0' style={{color: reportOverviewState?.resp?.detail?.merchant_adjudication_status ===  "REJECTED" ? "#F51A0A" :
                                                                                reportOverviewState?.resp?.detail?.merchant_adjudication_status ===  "ACCEPTED" ? "#039855" : ""
                                                                            }}
                                                                        > {
                                                                            reportOverviewState?.resp?.detail?.merchant_adjudication_status
                                                                        }
                                                                        </p>
                                                                    </div>

                                                                    {reportOverviewState?.resp?.detail?.merchant_adjudication_criteria &&
                                                                        <div className="d-flex align-items-center flex-wrap mb-2">
                                                                            <small className='me-2'>Status: </small>
                                                                            <p className='mb-0' style={{color: reportOverviewState?.resp?.detail?.merchant_adjudication_criteria?.toLowerCase() ===  "does not meet company's standards" ? "#F51A0A" :
                                                                                    reportOverviewState?.resp?.detail?.merchant_adjudication_criteria?.toLowerCase() ===  "meets company standards" ? "#039855" :
                                                                                    reportOverviewState?.resp?.detail?.merchant_adjudication_criteria?.toLowerCase() ===  "client review require" ? "#945605" :
                                                                                    reportOverviewState?.resp?.detail?.merchant_adjudication_criteria?.toLowerCase() ===  "pending potential conflict" ? "#2E2AB7" :
                                                                                    reportOverviewState?.resp?.detail?.merchant_adjudication_criteria?.toLowerCase() ===  "cancelled" ? "#4A4A4A" : ""
                                                                                }}
                                                                            > {reportOverviewState?.resp?.detail?.merchant_adjudication_criteria}</p>
                                                                        </div>
                                                                    }

                                                                    {reportOverviewState?.resp?.detail?.merchant_adjudication_comment &&
                                                                        <div className='border rounded-3 py-2 px-3 mb-3'>
                                                                            <p>
                                                                                {reportOverviewState?.resp?.detail?.merchant_adjudication_comment}
                                                                            </p>
                                                                        </div>
                                                                    }

                                                                    {reportOverviewState?.resp?.detail?.adjudication_supporting_document &&
                                                                        <div style={{ backgroundColor: "#eaeef6" }}             className='py-2 px-3 my-3'>
                                                                            <div className="row align-items-center">
                                                                                <div className="col-9 d-flex align-items-center">
                                                                                    <i className="ri-image-2-line ri-xl me-2" />
                                                                                    <span>
                                                                                        <p className="p-0 m-0" style={{ fontSize: "12px" }}>
                                                                                            Adjudication Document
                                                                                            {/* {reportOverviewState?.resp?.detail?.adjudication_supporting_document} */}
                                                                                        </p>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-3 d-flex justify-content-end">
                                                                                    <span>
                                                                                        <span className="d-flex align-items-center">
                                                                                            <a href={reportOverviewState?.resp?.detail?.adjudication_supporting_document}
                                                                                            className='link' download>
                                                                                                <i className="ri-download-2-line ri-xl me-2 " />
                                                                                            </a>
                                                                                        </span>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        // <div className='border'>
                                                                        //     <p>
                                                                        //         {reportOverviewState?.resp?.detail?.merchant_adjudication_comment}
                                                                        //     </p>
                                                                        // </div>
                                                                    }
                                                                    <br /> 
                                                                    <hr />
                                                                </div>

                                                            }

                                                            {(!reportOverviewState?.resp?.detail?.merchant_adjudication_status ||
                                                                reportOverviewState?.resp?.detail?.merchant_adjudication_status === "PENDING"
                                                             )&&
                                                                <div className='my-5'>
                                                                    <h6>Adjudication</h6>
                                                                    <small>Select a reply based on the comment above</small> <br />

                                                                    {merchantAdjudicationStatus !== "REJECTED" &&
                                                                        <button className='btn mt-3 w-100 py-3 text-white'
                                                                            style={{backgroundColor:"#04C870"}}
                                                                            onClick={()=>setMerchantAdjudicationStatus("ACCEPTED")}
                                                                        > Accept candidate's status
                                                                        </button>
                                                                    }

                                                                    {merchantAdjudicationStatus !== "ACCEPTED" &&
                                                                        <button className='btn mt-3 w-100 py-3 text-white' 
                                                                            style={{backgroundColor:"#BE1A0E"}}
                                                                            onClick={()=>setMerchantAdjudicationStatus("REJECTED")}
                                                                        > Reject candidate's status
                                                                        </button>
                                                                    }

                                                                    {merchantAdjudicationStatus &&
                                                                        <div>
                                                                            <button className='p-0 btn link link-underline mt-2'
                                                                                onClick={()=>{
                                                                                    setMerchantAdjudicationStatus("")
                                                                                    setMerchantAdjudicationComment("")
                                                                                    setDoc("")
                                                                                }}
                                                                            > Change Decision</button>
                                                                            <br />

                                                                            <select onChange={e=>setMerchantAdjudicationCandStat(e.target.value)}
                                                                                value={merchantAdjudicationCandStat}
                                                                                className='form-select'
                                                                            >
                                                                                <option value=""> Select status</option>
                                                                                {["","Meets company standards", "Does not meet company's standards", "Client review required", "Pending potential conflicts"]?.map((val: any) => (
                                                                                    <option value={val}> {val}</option>
                                                                                ))}
                                                                            </select>

                                                                            <textarea rows={3} className='form-control my-3'
                                                                            value={merchantAdjudicationComment}
                                                                            placeholder="Enter comment here" onChange={e=>setMerchantAdjudicationComment(e.target.value)}></textarea>

                                                                            {!docData &&
                                                                                <div className={`file-input-area p-2 my-3 `}>
                                                                                    {uploadFileState?.isLoading ? (
                                                                                        <div>
                                                                                            <Spinner
                                                                                                as="span"
                                                                                                animation="border"
                                                                                                size="sm"
                                                                                                role="status"
                                                                                                aria-hidden="true"
                                                                                            />
                                                                                            <span className="sr-only">Uploading...</span>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div className="">
                                                                                            <input type="file"
                                                                                                onChange={e=>uploadDoc(e)}
                                                                                                autoComplete="off"
                                                                                                accept=".pdf, .png, .jpg, .jpeg"
                                                                                                // value={doc}
                                                                                                draggable={false}
                                                                                                disabled={uploadFileState?.isLoading}
                                                                                            />
                                                                                            <small>Maximum file size: 15MB</small>
                                                                                            <small>Supported file types: (.pdf, .png, .jpg, .jpeg).</small>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            }


                                                                            {docData &&
                                                                                <div style={{ backgroundColor: "#eaeef6" }}             className='py-2 px-3 my-3'>
                                                                                    <div className="row align-items-center">
                                                                                        <div className="col-9 d-flex align-items-center">
                                                                                            <i className="ri-image-2-line ri-xl me-2" />
                                                                                            <span>
                                                                                                <p className="p-0 m-0" style={{ fontSize: "14px" }}>{docData?.name}</p>
                                                                                                <p className="p-0 m-0" style={{ fontSize: "13px", color: "#8D8D8D" }}>
                                                                                                    Size: {docData?.size / 1000} KB
                                                                                                </p>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="col-3 d-flex justify-content-end">
                                                                                            <span>
                                                                                                {/* <button className="btn btn-danger"
                                                                                                    onClick={e => triggerKeyUpdate(allFormVal[0], form?.endpoint, form?.form_type, form?.form_value, "", form?.form_id, form?.key, "", `${form?.form_label}`, "", (form?.newlyAdded || false), (allFormVal[1]?.multiple || false), (form?.newlyUnique || ""), (form?.required || "False"))}
                                                                                                        style={{ border: "None", borderRadius: "5px" }}
                                                                                                    > */}
                                                                                                    <span className="d-flex align-items-center"
                                                                                                        onClick={() => {
                                                                                                            setDocData(null)
                                                                                                            setDoc("")
                                                                                                        }}
                                                                                                        >
                                                                                                            <i className="ri-delete-bin-3-line ri-xl me-2 " />
                                                                                                            {/* Remove */}
                                                                                                    </span>
                                                                                                {/* </button> */}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }

                                                                            <button className='btn btn-green py-3 ' 
                                                                                onClick={validateAdjudication}
                                                                            >
                                                                                {reportAdjudicationState?.isLoading ? (
                                                                                    <div>
                                                                                        <Spinner
                                                                                            as="span"
                                                                                            animation="border"
                                                                                            size="sm"
                                                                                            role="status"
                                                                                            aria-hidden="true"
                                                                                        />
                                                                                        <span className="sr-only">Loading...</span>
                                                                                    </div>
                                                                                ) : (
                                                                                    'Submit Adjudication'
                                                                                )}
                                                                            </button>
                                                                        </div>
                                                                    }
                                                                    <hr />
                                                                </div>
                                                            }
                                                        </div>
                                                    }

                                                    <EmptyStateComp
                                                        title={
                                                            reportOverviewState?.resp?.detail?.request_status === "COMPLETED" ? 
                                                            "Report is ready for download."  :
                                                            reportOverviewState?.resp?.detail?.request_status === "IN PROGRESS" ? 
                                                            "Verification process has begun."  :
                                                            reportOverviewState?.resp?.detail?.request_status === "IN-PROGRESS" ? 
                                                            "Verification process has begun."  :
                                                            reportOverviewState?.resp?.detail?.request_status === "PROCESSING" ? 
                                                            "Verification is pending."  : ""
                                                        }
                                                        ctaAction={
                                                            reportOverviewState?.resp?.detail?.request_status === "COMPLETED" ? 
                                                            getReportRequestDownload : ()=>{}
                                                        }
                                                        desc={
                                                            reportOverviewState?.resp?.detail?.request_status === "COMPLETED" ? 
                                                            "Click the download button."  :

                                                            reportOverviewState?.resp?.detail?.request_status === "IN PROGRESS" ? 
                                                            "You will be notified as soon as the full report is ready to be downloaded."  : 

                                                            reportOverviewState?.resp?.detail?.request_status === "IN-PROGRESS" ? 
                                                            "You will be notified as soon as the full report is ready to be downloaded."  : 

                                                            reportOverviewState?.resp?.detail?.request_status === "PROCESSING" ? 
                                                            " We will begin verifying your request shortly."  : ""
                                                        }
                                                        ctaValue={
                                                            ((reportOverviewState?.resp?.detail?.request_status === "COMPLETED") &&  (reportDownloadState?.isLoading)) ? 
                                                            'Downloading...' :
                                                            reportOverviewState?.resp?.detail?.request_status === "COMPLETED" ? 
                                                            'Download Report' : ""
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </>
                            }


                        </>
                    }

                    {/* {(reportPage === 2) &&
                        <CandidateDetailedReportPage changePage={changePage}
                            summary={reportOverviewState?.resp?.detail?.bio}
                            submittedData={reportOverviewState?.resp?.detail?.submitted_data}
                            name={`${reportOverviewState?.resp?.detail?.bio?.first_name} ${reportOverviewState?.resp?.detail?.bio?.last_name}`}
                            email={reportOverviewState?.resp?.detail?.bio?.email}
                            tenantInfo={props?.tenantInfo}
                        />
                    } */}
                </div>
            }

        </div>
    )
}
