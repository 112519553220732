import React, { useEffect, useState } from 'react'
import { AvailableTag, PartialTag } from '../../utils'
import { RootState } from '../../../redux/reducers'
import { useDispatch, useSelector } from 'react-redux'
import { subPricingRequest } from '../../../redux/actions/subscription'
import NotificationToast from '../../utils/notifToast'

export default function SubPricingComp(props: any) {
    const [notifVal, setNotifVal] = useState(false)
    const [notif, setNotif] = useState('')
    const [notifTitle, setNotifTitle] = useState('')
    const [filterValue, setFilterValue] = useState('NG')
    const [priceCountries, setPriceCountries] = useState<any[]>([])


    const subPricingState = useSelector((state: RootState) => state.subPricingReducer)

    const dispatch = useDispatch()

    useEffect(() => {
        getPricing()
    }, [])

    let getPricing = () => {
        const callback = (data: any) => {
            if (data.status) {
                getPricingCountries(data?.detail)
            } else {
                setNotifTitle('Error')
                setNotif(data.detail)
                setNotifVal(true)
            }
        }
        let data: any = {
            values: {},
            callback,
        }
        dispatch(subPricingRequest(data))
    }


    let getPricingCountries = (data: any) => {
        let uniqueCountries: any = [];

        for (const val of data) {
            var countryIdx = uniqueCountries?.findIndex((typ: any) => typ?.country === val?.country)
            if (countryIdx === -1) {
                uniqueCountries?.push({ "country": val?.country, "country_name": val?.country_name });
            }
        }
        setPriceCountries([...uniqueCountries])

    }

    return (
        <div>
            {notif && notifVal && (<NotificationToast title={notifTitle} message={notif} closeNotif={() => setNotifVal(!notifVal)} />)}

            <div className="table-header mt-5">
                <div className="row">
                    <div className="col-md-7">
                        <h5>BGC Pricing</h5>
                        <p> Filter by country to see all available checks in that country </p>
                    </div>
                    <div className="col-md-5">
                        <div className="col-md-7 ms-md-auto">
                            <select className='form-select' value={filterValue} onChange={(e) => setFilterValue(e?.target?.value)}>
                                <option value="">All Countries</option>
                                {priceCountries?.sort((a: any, b: any) => a.country_name?.localeCompare(b.country_name))?.map((val: any, i: number) => (
                                    <option value={val?.country} key={i}>{val?.country_name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="d-flex py-2 px-3 w-100 text-white" style={{ backgroundColor: "#0489E5" }}>
                    <div className=" pt-1 pe-3">
                        <i className="ri-information-line ri-xl" />
                    </div>
                    <div className="col-11">
                        <small>
                            Some checks attract an additional charges (e.g Education check could vary by institution or region), 
                            where this applies, new price shall be communicated for approval
                        </small>
                    </div>
                </div>
            </div>


            <div className="table-responsive mt-5">
                <table className="table">
                    <thead className="">
                        <tr>
                            <th scope="col">SN</th>
                            <th scope="col">CHECK NAME</th>
                            <th scope="col">COUNTRY</th>
                            <th scope="col">AMOUNT</th>
                            <th scope="col">STATUS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {subPricingState?.resp?.detail?.filter((data: any) => data?.country === filterValue)?.sort((a: any, b: any) => a?.check_name?.localeCompare(b?.check_name))?.map((val: any, index: number) => (
                            <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>{val?.check_name?.replaceAll("_", " ")}</td>
                                <td>{val?.country_name}</td>
                                <td>{`${val?.currency} ${val?.price}`}</td>
                                <td>
                                    <AvailableTag />
                                    {/* {val?.status === "UNAVAILABLE" && <PartialTag />} */}
                                    {/* {val?.status === "AVAILABLE" && <AvailableTag />} */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
