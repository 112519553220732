import React, { useEffect, useState } from 'react'
import NotificationToast from '../../../../../../../components/utils/notifToast'
import { backgroundCheckRequestAnswerUploadRequest, backgroundCheckRequestFileUploadRequest, backgroundCheckRequestGetCandidateFormRequest, backgroundCheckRequestGetPackageCandidateFormRequest, backgroundCheckRequestGetSubmittedFormRequest, backgroundCheckRequestUpdateSubmittedFormRequest } from '../../../../../../../redux/actions/products/backgroundCheck/request/checks'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../redux/reducers'
import CheckSummaryComp from '../../../../../../../components/products/backgroundCheck/request/checks/checkSummary'
import { Player } from '@lottiefiles/react-lottie-player'
import successVerifGif from '../../../../../../../assets/successVerif.json'
import cancelGif from '../../../../../../../assets/cancel.json'
import Mainloader, { removeLetters, replaceUnderscores } from '../../../../../../../components/utils'
import { useLocation } from 'react-router-dom'
import BackgroundCheckFaceVerificationComp from '../../../../../../../components/products/backgroundCheck/request/checks/faceVerification'
import { countryList } from '../../../../../../../components/utils/countries'
// import { getCountryFlag } from '../../../../../../../components/utils/flags'
import { parsePhoneNumber } from 'awesome-phonenumber'
import { Spinner } from 'react-bootstrap'
// import CandidatePaymentComp from '../../../../../../../components/products/backgroundCheck/request/checks/candidatePayment'
import Select from 'react-select'
import CandidatePaymentCompBolt from '../../../../../../../components/products/backgroundCheck/request/checks/externals/bolt/candidatePayment'
import { backgroundCheckRequestConsentRequest } from '../../../../../../../redux/actions/products/backgroundCheck/request/general'
import "../bolt/index.css"




interface IcurrentForm {
    forms: any;
}
const customStyles = {
    option: (provided: any, state: any) => ({
        ...provided,
        color: state.isSelected ? '#fff' : '',
        backgroundColor: state.isSelected ? '#0489E5' : state.isFocused ? '#DEEBFF' : '',
    }),
}


export default function BackgroundCheckRequestUpdateSubmissionPageBolt() {
    const [notifVal, setNotifVal] = useState(false)
    const [notif, setNotif] = useState("")
    const [notifTitle, setNotifTitle] = useState("")
    const [allForm, setAllForm] = useState<any>([]);
    const [allMergedForm, setAllMergedForm] = useState<any>([]);
    const [formPage, setFormPage] = useState(1)
    const [currentStep, setCurrentStep] = useState(0);
    const [currentForm, setCurrentForm] = useState<any | IcurrentForm>({});
    // const [requestId, setRequestId] = useState("")
    const [orgId, setOrgId] = useState("")

    const [payloadData, setPayloadData] = useState<any>({})
    // const [previewData, setPreviewData] = useState({})
    const [previewReformat, setPreviewReformat] = useState({})
    const [payloadDataArr, setPayloadDataArr] = useState<any[]>([])

    const [facialDataValues, setFacialDataValues] = useState({})
    const [phoneCountryCode, setPhoneCountryCode] = useState("AF");
    const [activeUploadingKey, setActiveUploadingKey] = useState("");

    const [successModal, setSuccessModal] = useState(false)
    const [errorDataArr, setErrorDataArr] = useState<any[]>([])

    const [consentModal, setConsentModal] = useState(false)


    // const candidateFormState = useSelector((state: RootState) => state.backgroundCheckRequestGetCandidateFormReducer)
    // const consentState = useSelector((state: RootState) => state.backgroundCheckRequestConsentReducer)
    const backgroundCheckRequestUpdateSubmissionState = useSelector((state: RootState) => state.backgroundCheckRequestUpdateSubmittedFormReducer);
    const submittedFormState = useSelector((state: RootState) => state.backgroundCheckRequestGetSubmittedFormReducer)
    const uploadFileState = useSelector((state: RootState) => state.backgroundCheckRequestFileUploadReducer)

    const location = useLocation();
    const queryParams = new URLSearchParams(window.location.search)
    // let packageId = queryParams.get('packageRef') || ""
    let requestId = queryParams.get("ref") || ""

    const bioData = location.state;

    const dispatch = useDispatch()

    useEffect(() => {
        getSubmittedForm()
        // getCandidateForm()
    }, [])

    useEffect(() => {
        getFormAt(currentStep)
    }, [currentStep, submittedFormState?.resp?.detail])


    // console.log(payloadData)

    let getSubmittedForm = () => {
        const callback = (data: any) => {

            if (data.status) {
                // setRequestId(data?.request_id)
                setOrgId(data?.org_id)

                // if (data?.detail?.rejected) {
                //     setFormPage(6)
                //     return
                // }
                // if (data?.detail?.request_completed) {
                //     setFormPage(4)
                //     return
                // }


                data?.detail && Object?.entries(data?.detail?.form)?.forEach((parent: any) => {
                    parent[1]?.forms?.forEach((val: any) => {
                        let keyDataArr: any = payloadDataArr
                        if (val?.form_value === "phone_number") {
                            triggerKeyUpdate(parent[0], val?.endpoint, val?.form_type, val?.form_value, "KE", val?.form_id, val?.key, val?.regex, val?.form_label, "phoneCountryCode", false, false, "", (val?.required || "False"))
                            triggerKeyUpdate(parent[0], val?.endpoint, val?.form_type, val?.form_value, val?.submitted_value, val?.form_id, val?.key, val?.regex, val?.form_label, "", false, (parent[1]?.multiple || false), "", (val?.required || "False"))

                        }

                        // if (val?.form_type === 'tel') {

                        //     let filteredKeyDataArr = keyDataArr?.filter((item: any) => ((item?.form_value === val?.form_value) && (item?.title === parent[0]) && (item?.key === ( val?.form_id))))

                        //     // if (filteredKeyDataArr?.length > 0) {
                        //     //     if (specKey === 'phoneCountryCode') {
                        //     //         filteredKeyDataArr[0]['phoneCountryCode'] = val
                        //     //     }
                        //     //     else {
                        //     //         filteredKeyDataArr[0]["form_data"] = val
                        //     //     }
                        //     // }
                        //     // else {
                        //     //     if (specKey === 'phoneCountryCode') {
                        //     //         keyDataArr.push({ "title": parent[0], "endpoint": val?.endpoint, "form_value": val?.form_value, "form_data": "", "form_id": val?.form_id, "key": val?.key, "regex": val?.regex, "phoneCountryCode": "KE", "label": val?.form_label, "newlyAdded": "", "multipleValue": (parent[1]?.multiple || false), "newlyUnique": "", "required": (val?.required || "False") })
                        //     //     }
                        //     //     else {
                        //     //         keyDataArr.push({ "title": parent[0], "endpoint": val?.endpoint, "form_value": val?.form_value, "form_data": val, "form_id": val?.form_id, "key": val?.key, "regex": val?.regex, "phoneCountryCode": "", "label": val?.form_label, "newlyAdded": "", "multipleValue": (parent[1]?.multiple || false), "newlyUnique": "", "required": (val?.required || "False") })
                        //     //     }
                        //     // }

                        //     setPayloadDataArr(keyDataArr)
                        //     reformatData()

                        // }
                        else {
                            let keyDataIndex = payloadDataArr?.findIndex(item => ((item?.form_value === val?.form_value) && (item?.title === parent[0]) && (item?.key === (val?.key))))

                            if (keyDataIndex !== -1) {
                                keyDataArr.splice(keyDataIndex, 1)
                                keyDataArr.push({ "title": parent[0], "endpoint": val?.endpoint, "form_value": val?.form_value, "form_data": val?.submitted_value, "form_id": val?.form_id, "key": val?.key, "regex": val?.regex, "label": val?.form_label, "newlyAdded": "", "multipleValue": (parent[1]?.multiple || false), "newlyUnique": "", "required": (val?.required || "False") })
                            }
                            else {
                                keyDataArr.push({ "title": parent[0], "endpoint": val?.endpoint, "form_value": val?.form_value, "form_data": val?.submitted_value, "form_id": val?.form_id, "key": val?.key, "regex": val?.regex, "label": val?.form_label, "newlyAdded": "", "multipleValue": (parent[1]?.multiple || false), "newlyUnique": "", "required": (val?.required || "False") })
                            }

                            setPayloadDataArr(keyDataArr)
                            reformatData()
                        }

                        // triggerKeyUpdate(parent[0], val?.endpoint, val?.form_type, val?.form_value, val?.submitted_value, val?.form_id, val?.key, val?.regex, val?.form_label, "", false, (parent[1]?.multiple || false), "", (val?.required || "False"))


                        // triggerKeyUpdate(parent[0], val?.endpoint, val?.form_type, val?.form_value, val?.submitted_value, val?.form_id, val?.key, val?.regex, val?.form_label, "phoneCountryCode", false, false, "", (val?.required || "False"))
                        // if (val?.form_value === "phone_number") {
                        //     triggerKeyUpdate("bio_data", val?.endpoint, val?.form_type, val?.form_value, "KE", val?.form_id, val?.key, val?.regex, val?.form_label, "phoneCountryCode", false, false, "", (val?.required || "False"))
                        // }
                    })
                    // console.log(parent[1])
                })

                // data?.detail?.form?.bio_data?.forms?.forEach((val: any,) => {
                //     if (val?.form_value === "nationality") {
                //         triggerKeyUpdate("bio_data", val?.endpoint, val?.form_type, val?.form_value, bioData?.nationality || data?.detail?.nationality || "Kenyan", val?.form_id, val?.key, val?.regex, val?.form_label, "", false, false, "", (val?.required || "False"))
                //     }
                //     if (val?.form_value === "phone_number") {
                //         triggerKeyUpdate("bio_data", val?.endpoint, val?.form_type, val?.form_value, "KE", val?.form_id, val?.key, val?.regex, val?.form_label, "phoneCountryCode", false, false, "", (val?.required || "False"))
                //     }
                // })
                // data?.detail?.form?.criminal_check_kenya?.forms?.forEach((val: any,) => {
                //     if (val?.form_value === "endpoint_country") {
                //         triggerKeyUpdate("criminal_check_kenya", val?.endpoint, val?.form_type, val?.form_value, "KE", val?.form_id, val?.key, val?.regex, val?.form_label, "", false, false, "", (val?.required || "False"))
                //     }
                // })
                // data?.detail?.form?.driving_license_kenya?.forms?.forEach((val: any,) => {
                //     if (val?.form_value === "endpoint_country") {
                //         triggerKeyUpdate("driving_license_kenya", val?.endpoint, val?.form_type, val?.form_value, "KE", val?.form_id, val?.key, val?.regex, val?.form_label, "", false, false, "", (val?.required || "False"))
                //     }
                // })

            }
            else {
                // setConsentPage(3)
                setNotifTitle("Error")
                setNotif(data.detail)
                setNotifVal(true)
            }
        };
        let data: any = {
            values: {
                form_id: requestId,
            },
            callback,
        };
        dispatch(backgroundCheckRequestGetSubmittedFormRequest(data))
    }

    // console.log(payloadDataArr)




    let getFormAt = async (index: number) => {
        if (!submittedFormState?.isLoading && submittedFormState?.resp?.detail) {
            if (index === 0) {
                setAllForm(Object?.entries(submittedFormState?.resp?.detail?.form)?.reverse())
                setCurrentForm(Object?.entries(submittedFormState?.resp?.detail?.form)?.reverse()[index])
            }
            else {
                setCurrentForm(allForm[index])
            }


            let mergedData: any = [...allMergedForm]
            Object?.entries(submittedFormState?.resp?.detail?.form)?.reverse()?.forEach((item: any) => {
                mergedData?.push(...item[1]?.forms);
                setAllMergedForm([...mergedData])
            });

        }
    }


    let triggerKeyUpdate = (title: any, endpoint: any, formType: any, key: any, val: any, id: any, uniqueId: any, regex: any, label: any, specKey: any, newlyAdded: any, multipleValue: any, newlyUnique: any, required: any) => {

        let keyDataArr: any = payloadDataArr


        if (formType === 'file') {
            var file = val?.target?.files[0]
            let reader = new FileReader()

            if (val?.target?.files?.length > 0) {
                reader.readAsDataURL(file)
                reader.onload = () => {
                    let imgBase: any = reader.result
                    let keyDataIndex = payloadDataArr?.findIndex(item => ((item?.form_value === key) && (item?.title === title) && (item?.key === (uniqueId))))


                    if (file && (
                        (file.type !== "image/png") &&
                        (file.type !== "image/jpeg") &&
                        (file.type !== "image/jpg") &&
                        (file?.type !== "application/pdf")
                    )) {
                        setNotifTitle('Error')
                        setNotif(`Unsupported ${label} format Uploaded`)
                        setNotifVal(true)
                        // return
                    }
                    else if (file && (file?.size > 15200000)) {
                        // else if (file && (file?.size > 710000)) {

                        setNotifTitle('Error')
                        setNotif(`${label} is larger than 15MB`)
                        setNotifVal(true)

                        setTimeout(() => {
                            setNotif("")
                        }, 3000);
                        // return
                    }
                    else {

                        const callback = (data: any) => {
                            if (data?.status) {
                                if (keyDataIndex !== -1) {
                                    keyDataArr.splice(keyDataIndex, 1)
                                    keyDataArr.push({ "title": title, "endpoint": endpoint, "form_value": key, "form_data": data?.detail, "form_id": id, "key": uniqueId, "regex": regex, "file_front_Data": file, "label": label, "newlyAdded": newlyAdded, "multipleValue": multipleValue, "newlyUnique": newlyUnique, "required": required })
                                }
                                else {
                                    keyDataArr.push({ "title": title, "endpoint": endpoint, "form_value": key, "form_data": data?.detail, "form_id": id, "key": uniqueId, "regex": regex, "file_front_Data": file, "label": label, "newlyAdded": newlyAdded, "multipleValue": multipleValue, "newlyUnique": newlyUnique, "required": required })
                                }

                                setPayloadDataArr(keyDataArr)
                                reformatData()
                            }
                            else {
                                setNotifTitle("Error")
                                setNotif(data.detail)
                                setNotifVal(true)
                            }
                        };
                        let data: any = {
                            values: {
                                file: imgBase,
                            },
                            callback,
                        };
                        dispatch(backgroundCheckRequestFileUploadRequest(data))

                    }
                }
            }
            else {
                let keyDataIndex = payloadDataArr?.findIndex(item => ((item?.form_value === key) && (item?.title === title) && (item?.key === (uniqueId))))
                // setFileCheck(imgBase)

                if (keyDataIndex !== -1) {
                    keyDataArr.splice(keyDataIndex, 1)
                    // keyDataArr.push({ "title": "", "endpoint": "", "form_value": "", "form_data": "", "form_id": "", "key": "", "regex": "" })
                }
                // else {
                //     keyDataArr.push({ "title": "", "endpoint": "", "form_value": "", "form_data": "", "form_id": "", "key": "", "regex": "" })
                // }

                setPayloadDataArr(keyDataArr)
                reformatData()
            }
        }
        // else if (formType === 'select'){
        //     let keyDataIndex = payloadDataArr?.findIndex(item => ((item?.form_value === key) && (item?.title === title) && (item?.key === (uniqueId))))

        //     if (keyDataIndex !== -1) {
        //         keyDataArr.splice(keyDataIndex, 1)
        //         keyDataArr.push({ "title": title, "endpoint": endpoint, "form_value": key, "form_data": val?.value, "form_id": id, "key": uniqueId, "regex": regex, "label": label, "newlyAdded": newlyAdded, "multipleValue": multipleValue, "newlyUnique": newlyUnique, "required": required })
        //     }
        //     else {
        //         keyDataArr.push({ "title": title, "endpoint": endpoint, "form_value": key, "form_data": val?.value, "form_id": id, "key": uniqueId, "regex": regex, "label": label, "newlyAdded": newlyAdded, "multipleValue": multipleValue, "newlyUnique": newlyUnique, "required": required })
        //     }
        //     setPayloadDataArr(keyDataArr)
        //     reformatData()
        // }
        else if (formType === 'tel') {

            let filteredKeyDataArr = keyDataArr?.filter((item: any) => ((item?.form_value === key) && (item?.title === title) && (item?.key === (uniqueId))))

            if (filteredKeyDataArr?.length > 0) {
                if (specKey === 'phoneCountryCode') {
                    filteredKeyDataArr[0]['phoneCountryCode'] = val
                }
                else {
                    filteredKeyDataArr[0]["form_data"] = val
                }
            }
            else {
                if (specKey === 'phoneCountryCode') {
                    keyDataArr.push({ "title": title, "endpoint": endpoint, "form_value": key, "form_data": "", "form_id": id, "key": uniqueId, "regex": regex, "phoneCountryCode": val, "label": label, "newlyAdded": newlyAdded, "multipleValue": multipleValue, "newlyUnique": newlyUnique, "required": required })
                }
                else {
                    keyDataArr.push({ "title": title, "endpoint": endpoint, "form_value": key, "form_data": val, "form_id": id, "key": uniqueId, "regex": regex, "phoneCountryCode": "", "label": label, "newlyAdded": newlyAdded, "multipleValue": multipleValue, "newlyUnique": newlyUnique, "required": required })
                }
            }
            // keyDataArr?.forEach((item: any) => {

            //     if ((item?.form_value === key) && (item?.title === title) && (item?.key === (uniqueId))) {
            //         console.log("e dey there")

            //         if (specKey === 'phoneCountryCode') {
            //             keyDataObject[0]['phoneCountryCode'] = val
            //             item["phoneCountryCode"] = val
            //         }
            //         else {
            //             keyDataObject[0]["form_data"] = val
            //             item["form_data"] = val
            //         }
            //     }
            // });


            // if (keyDataIndex === -1) {
            //     if (specKey === 'phoneCountryCode') {
            //         keyDataArr.push({ "title": title, "endpoint": endpoint, "form_value": key, "form_data": "", "form_id": id, "key": uniqueId, "regex": regex, "phoneCountryCode": val, "label": label })
            //     }
            //     else {
            //         keyDataArr.push({ "title": title, "endpoint": endpoint, "form_value": key, "form_data": val, "form_id": id, "key": uniqueId, "regex": regex, "phoneCountryCode": "", "label": label })
            //     }
            // }
            setPayloadDataArr(keyDataArr)
            reformatData()

            // console.log(payloadData)

        }
        else {
            let keyDataIndex = payloadDataArr?.findIndex(item => ((item?.form_value === key) && (item?.title === title) && (item?.key === (uniqueId))))

            if (keyDataIndex !== -1) {
                keyDataArr.splice(keyDataIndex, 1)
                keyDataArr.push({ "title": title, "endpoint": endpoint, "form_value": key, "form_data": val, "form_id": id, "key": uniqueId, "regex": regex, "label": label, "newlyAdded": newlyAdded, "multipleValue": multipleValue, "newlyUnique": newlyUnique, "required": required })
            }
            else {
                keyDataArr.push({ "title": title, "endpoint": endpoint, "form_value": key, "form_data": val, "form_id": id, "key": uniqueId, "regex": regex, "label": label, "newlyAdded": newlyAdded, "multipleValue": multipleValue, "newlyUnique": newlyUnique, "required": required })
            }

            // setPayloadData(keyData)
            setPayloadDataArr(keyDataArr)
            reformatData()
        }

    }


    let reformatData = () => {
        const correctData: any = {};
        const correctPreData: any = {};

        payloadDataArr?.forEach((obj) => {
            correctPreData[obj.title] = payloadDataArr?.filter((item: any) => item?.title === obj?.title)
        });

        // console.log(parsePhoneNumber( dat?.form_data, { regionCode: phoneCountryCode }))

        // console.log(correctPreData)

        // setPayloadData({ ...correctData })
        // setPreviewData({ ...correctPreData })

        setPayloadData({ ...correctPreData })

        // this is submission reformat, for the new submission structure 
        setPreviewReformat(finalReformat({ ...correctPreData }))
    }

    let finalReformat = (payload: any) => {
        let newPayload: any = { ...payload };

        // Find the category with 'multipleValue' set to true
        const categoryWithMultiple = Object.keys(payload).find(category => {
            return payload[category].some((obj: any) => obj.multipleValue === true);
        });

        if (categoryWithMultiple) {
            const categoryArray = newPayload[categoryWithMultiple];
            const groupedByUnique: any = {};
            // const newlyAddedFalse:any = [];
            // const newlyAddedTrue:any = [];

            categoryArray.forEach((obj: any) => {
                // if (obj.multipleValue === true) {
                //     if (obj.newlyAdded === false) {
                //         newlyAddedFalse.push(obj);
                //     } else if (obj.newlyAdded === true) {
                //         newlyAddedTrue.push(obj);
                //     }
                // } else {
                //     // Include non-multipleValue objects directly
                //     newlyAddedFalse.push(obj);
                // }
                if (obj.multipleValue === true) {
                    const uniqueKey: any = obj.newlyUnique || 'default';
                    if (!groupedByUnique[uniqueKey]) {
                        groupedByUnique[uniqueKey] = [];
                    }
                    groupedByUnique[uniqueKey].push(obj);
                } else {
                    // Include non-multipleValue objects directly
                    if (!groupedByUnique['default']) {
                        groupedByUnique['default'] = [];
                    }

                    groupedByUnique['default'].push(obj);
                }
            });

            // // Update the payload with the restructured category
            // newPayload[categoryWithMultiple] = [newlyAddedFalse, newlyAddedTrue];


            // Convert grouped objects into an array of arrays
            newPayload[categoryWithMultiple] = Object.values(groupedByUnique);
        }

        return newPayload;

    }

    function getMissingData(arrA: any, arrB: any) {
        // const existingFormIds:any = new Set(arrB?.filter((item: any) => item.form_data)?.map((item: any) => item?.key));
        const existingFormIds: any = (arrB?.filter((item: any) => item.form_data)?.map((item: any) => item?.key));
        // return arrA.reduce((missingIds: any, item: any) => {

        //     let errDataIndex = errorDataArr.indexOf(item?.key)

        //     if (existingFormIds.includes(item?.key)) {
        //         if (errDataIndex !== -1) {
        //             errorDataArr.splice(errDataIndex, 1)
        //         }
        //     }
        //     else {
        //     // if (!existingFormIds.has(item?.key)) {
        //         if (errDataIndex !== -1) {
        //             errorDataArr.splice(errDataIndex, 1)
        //             missingIds.push(item?.key);
        //         } else {
        //             missingIds.push(item?.key);
        //         }
        //     }
        //     return missingIds;

        // }, errorDataArr);

        let missingData: any = [...errorDataArr]
        arrA.forEach((item: any) => {
            let errDataIndex = missingData.indexOf(item?.key)

            if (existingFormIds?.includes(item?.key)) {
                if (errDataIndex !== -1) {
                    missingData.splice(errDataIndex, 1)
                }
            }
            else {
                // if (!existingFormIds.has(item?.key)) {
                if (errDataIndex !== -1) {
                    missingData.splice(errDataIndex, 1)
                    missingData.push(item?.key);
                } else {
                    missingData.push(item?.key);
                }
            }
            setErrorDataArr([...missingData])
        })


    }


    let nextForm = () => {



        // setErrorDataArr([])
        // let currentPageData = payloadDataArr?.filter((val: any) => val?.title === formName)

        if ((payloadDataArr?.length < 1) || (allMergedForm?.filter((val: any) => val?.required === "True")?.length !== (payloadDataArr?.filter((val: any) => val?.required === "True")?.length))
            ||
            (allMergedForm.filter((val: any) => val?.required === "True")?.length === (payloadDataArr?.filter((val: any) => val?.required === "True")?.length)
                &&
                payloadDataArr.filter((val: any) => val?.required === "True")?.some((val: any) => val?.form_data === "")
            )
        ) {
            getMissingData(allMergedForm.filter((val: any) => val?.required === "True"), payloadDataArr)

            setNotifTitle('Error')
            setNotif('Please fill in all necessary fields')
            setNotifVal(true)
            return
        }
        // else if (formName === allForm[allForm?.length - 1][0]) {
        //     currentPageData?.forEach((dat: any) => {
        //         if (!new RegExp(dat?.regex)?.test(dat?.form_data)) {
        //             setNotifTitle('Error')
        //             setNotif(`Invalid ${dat?.label} format`)
        //             setNotifVal(true)
        //             return
        //         }
        //         else if (dat?.form_value === "phone_number" && (!parsePhoneNumber(dat?.form_data, { regionCode: dat?.phoneCountryCode })?.valid)) {
        //             setNotifTitle('Error')
        //             setNotif(`Invalid ${dat?.label} format`)
        //             setNotifVal(true)
        //             return
        //         }

        //     })
        //     if ((!currentPageData?.some((val: any) => new RegExp(val?.regex)?.test(val?.form_data) === false))
        //         &&
        //         (!currentPageData?.some((val: any) => val?.form_value === "phone_number" && (parsePhoneNumber(val?.form_data, { regionCode: val?.phoneCountryCode })?.valid) === false))
        //     ) {
        //         reformatData()
        //         if (candidateFormState?.resp?.detail?.face_capture === true) {
        //             goToFormPage(2)
        //         }
        //         else {
        //             goToFormPage(3)
        //         }
        //     }
        // }
        else {
            payloadDataArr?.forEach((dat: any) => {
                if ((dat?.required === "True") && !new RegExp(dat?.regex)?.test(dat?.form_data)) {
                    setNotifTitle('Error')
                    setNotif(`Invalid ${dat?.label} format`)
                    setNotifVal(true)
                    return
                }
                else if ((dat?.required === "True") &&  dat?.form_value === "national_id_number" && !new RegExp("^.{6,9}$")?.test(dat?.form_data)) {
                    setNotifTitle('Error')
                    setNotif(`Invalid ${dat?.label} format`)
                    setNotifVal(true)
                    return
                }
                else if ((dat?.required === "True") && dat?.form_value === "phone_number" && (!parsePhoneNumber(dat?.form_data, { regionCode: dat?.phoneCountryCode || phoneCountryCode })?.valid)) {
                    setNotifTitle('Error')
                    setNotif(`Invalid ${dat?.label} format`)
                    setNotifVal(true)
                    return
                }
            })

            // if ((!payloadDataArr?.some((val: any) => new RegExp(val?.regex)?.test(val?.form_data) === false ))
            //     &&
            //     (!payloadDataArr?.some((val: any) => val?.form_value === "phone_number" && (parsePhoneNumber(val?.form_data, { regionCode: val?.phoneCountryCode || phoneCountryCode })?.valid) === false))
            // ) {
            if((!payloadDataArr?.some((val: any) => new RegExp(val?.regex)?.test(val?.form_data) === false && val?.required === "True"))
                &&
                (!payloadDataArr?.some((val: any) => val?.form_value === "national_id_number" && new RegExp("^.{6,9}$")?.test(val?.form_data) === false) )
                &&
                (!payloadDataArr?.some((val: any) => val?.form_value === "phone_number" && (parsePhoneNumber(val?.form_data, { regionCode: val?.phoneCountryCode || phoneCountryCode })?.valid) === false))
            ){
                reformatData()
                submitForm()

                // setCurrentStep(currentStep + 1)

                // if (submittedFormState?.resp?.detail?.face_capture === true) {
                //     goToFormPage(2)
                // }
                // // else {
                // //     goToFormPage(3)
                // // }
                // else if (submittedFormState?.resp?.detail?.allow_candidate_pay_for_a_check === true) {
                //     goToFormPage(5)
                // }
                // else {
                //     submitForm()
                // }
            }
        }
    }


    let proceedFromFaceVerification = (val: any) => {
        setFacialDataValues(val)
        setFormPage(3)
    }


    // let signConsent = () => {

    //     let firstName = payloadData?.bio_data?.find((val:any)=> val.form_value === "first_name")?.form_data
    //     let middleName = payloadData?.bio_data?.find((val:any)=> val.form_value === "middle_name")?.form_data
    //     let lastName = payloadData?.bio_data?.find((val:any)=> val.form_value === "last_name")?.form_data

    //     const callback = (data: any) => {
    //         if (data.status) {
    //             // setNotifTitle("Success")
    //             // setNotif("Successfully consented to this terms and condition")
    //             // setNotifVal(true)

    //             // if (submittedFormState?.resp?.detail?.allow_candidate_pay_for_a_check === true) {
    //             //     goToFormPage(5)
    //             // }

    //             submitForm()

    //         }
    //         else {
    //             setNotifTitle("Error")
    //             setNotif(data.detail)
    //             setNotifVal(true)
    //         }
    //     };

    //     let data: any = {
    //         values: {
    //             request_id: requestId,
    //             organisation: orgId,
    //             // full_name:`${firstName} ${middleName && middleName} ${lastName}`,
    //             full_name:`${firstName} ${middleName && middleName} ${lastName}`,
    //             date: new Date().toJSON().slice(0, 10)

    //         },
    //         callback,
    //     };
    //     dispatch(backgroundCheckRequestConsentRequest(data))
    // }



    let submitForm = () => {
        const callback = (data: any) => {
            if (data?.status) {
                // setNotifTitle("Success")
                // setNotif("Response successfully sent")
                // setNotifVal(true)
                

                if (submittedFormState?.resp?.detail?.allow_candidate_pay_for_a_check === true && 
                    submittedFormState?.resp?.detail?.payment_status === false) {
                    goToFormPage(5)
                }
                else{
                    setFormPage(4)
                }

                // setTimeout(() => {
                //     setFormPage(4)
                // }, 1000);
                // toggleSuccessModal()
            }
            else {
                setNotifTitle("Error")
                setNotif(data.detail)
                setNotifVal(true)
            }
        };
        let data: any = {
            values: {
                request_id: requestId,
                ids: (submittedFormState?.resp?.detail?.face_capture === true) ? { ...previewReformat, ...facialDataValues } : previewReformat

            },
            callback,
        };
        dispatch(backgroundCheckRequestUpdateSubmittedFormRequest(data))
    }

    let goToFormPage = (val: any) => {
        setFormPage(val)
    }

    let getValueForKey = (id: any) => {
        const mainValue = payloadDataArr?.filter((val: any) => ((val.key === id)))[0];
        return mainValue?.file_front_Data || mainValue?.form_data;
    }


    let getValueForPhoneCountry = (id: any) => {
        const mainValue = payloadDataArr?.filter((val: any) => ((val.key === id)))[0];
        return mainValue?.phoneCountryCode;
    }



    let toggleSuccessModal = () => {
        setSuccessModal(!successModal)
    }



    function findFirstUniqueValue(arr: any, key: any) {
        const seenValues = new Set();
        const firstOccurrences = [];

        for (let i = 0; i < arr.length; i++) {
            const item = arr[i];
            const value = item[key];

            if (value !== undefined) {
                if (!seenValues.has(value)) {
                    seenValues.add(value);
                    firstOccurrences.push(item);
                }
            }
        }

        return firstOccurrences;
    }

    function countOccurrences(arr: any, property: any, value: any) {
        let count = 0;
        for (let obj of arr) {
            // if ((obj[property] === value) && !obj["newlyAdded"]) {
            if ((obj[property] === value)) {
                count++;
            }
        }
        return count;
    }


    let addNewForm = (newForm: any, formName: any, fieldTitle: any) => {
        let newData: any = []
        allForm?.forEach((val: any) => {
            if (val[0] === formName) {
                let getEditedOccurences = () => {
                    let returnedOccurence = countOccurrences(val[1]?.forms, "form_label", newForm[0].form_label)


                    newForm.forEach((obj: any) => {
                        if (!obj?.newlyAdded && (obj?.endpoint === "character_reference_ge" && obj?.form_value !== "cv")) {
                            newData?.push({
                                ...obj,
                                // field_title : obj.field_title + " " + countNewlyAdded++,
                                // key : obj.key + " " + countNewlyAdded++,

                                // field_title : obj.field_title,
                                // key : obj.key+countNewlyAdded+1,
                                key: obj.key + returnedOccurence,
                                "newlyAdded": true,
                                "newlyUnique": "unique" + returnedOccurence
                            })
                        }
                        if (!obj?.newlyAdded && (obj?.endpoint !== "character_reference_ge")) {
                            newData?.push({
                                ...obj,
                                key: obj.key + returnedOccurence,
                                "newlyAdded": true,
                                "newlyUnique": "unique" + returnedOccurence
                            })
                        }

                        // newData?.push(obj);
                    })
                    // console.log([...newData]);

                    return [...newData]
                }

                if (val[1] && Array.isArray(val[1].forms)) {
                    val[1].forms = val[1].forms.concat(getEditedOccurences());
                    // console.log(val[1].forms)
                    // setAllForm([...val[1].forms])
                    setAllForm([...allForm])
                } else {
                    // console.log(getEditedOccurences())
                    val[1] = { forms: getEditedOccurences() };
                    // console.log(val[1])
                    // setAllForm([...val[1]])
                    setAllForm([...allForm])
                }

            }
        })
    }

 

    let removeForm = (formName: any) => {

        let allNewForm: any = allForm;
        let newPayloadArr: any = payloadDataArr;

        allNewForm?.forEach((val: any) => {
            if (val[0] === formName) {
                const lastObject: any = val[1]?.forms[val[1]?.forms.length - 1];

                if (lastObject && lastObject.newlyUnique) {
                    const lastNewlyUniqueValue = lastObject.newlyUnique;

                    val[1].forms = val[1]?.forms?.filter((obj: any) => {
                        return !obj.newlyUnique || obj.newlyUnique !== lastNewlyUniqueValue;
                    });

                    newPayloadArr = newPayloadArr.filter((obj: any) => {
                        return !obj.newlyUnique || obj.newlyUnique !== lastNewlyUniqueValue;
                    })
                }

                setAllForm([...allNewForm])

                setPayloadDataArr([...newPayloadArr])

            }
        })

    }

    return (
        <div>

            {(notif && notifVal) && <NotificationToast title={notifTitle} message={notif} closeNotif={() => setNotifVal(!notifVal)} />}

            <div className="text-white py-4 mb-5" style={{ background: "#003E51" }}>
                <div className="container">
                    {/* <h5>Complete A Background Check</h5> */}
                    <h5>Bolt Check</h5>
                </div>
            </div>

            {submittedFormState?.isLoading && <Mainloader />}

            {formPage === 1 &&
                <>
                    {!submittedFormState?.isLoading &&
                        <div className="container">
                            {allForm?.length > 0 && allForm?.map((allFormVal: any, idxAl: number) => (
                                <>
                                    <div className='row justify-content-between '>
                                        {allForm?.length > 0 && (
                                            <div className="col-md-5 mt-">
                                                <form onSubmit={e => e.preventDefault()} autoComplete="off" className=''>

                                                    {allFormVal && allFormVal[1]?.forms?.map((form: any, i: number) => (
                                                        <span key={i}>

                                                            {findFirstUniqueValue(allFormVal[1]?.forms, "newlyUnique")?.map((title: any, ind: number) => {
                                                                if (title?.key === form?.key) {
                                                                    return (
                                                                        <hr key={ind} className='mt-5' />
                                                                    )
                                                                }
                                                            })}


                                                            <div className="mt-1" key={i}>
                                                                {(form?.form_type === "select" && (form?.form_value === "endpoint_country")) ? <></>
                                                                    :
                                                                    <label htmlFor={form?.form_label}>
                                                                        {form?.form_value === "xds_form" &&
                                                                            <>
                                                                                {`${form?.form_label} ${form?.description ?
                                                                                    ("(" + (form?.description.substring(0, 91)))
                                                                                    : ""}`
                                                                                }
                                                                                {form?.description &&
                                                                                    <a style={{ color: "#0489E5" }} href={form?.description.substring(93, 175)} target='_blank' rel="noreferrer" className='link link-underline'>
                                                                                        {/* {form?.description.substring(92, 176)} */}
                                                                                        View here
                                                                                    </a>
                                                                                }
                                                                                {")"}
                                                                            </>
                                                                        }
                                                                        {form?.form_value === "police_clearance_certificate_number/invoice_reference_number" &&
                                                                            <>
                                                                                {form?.form_label} <br /> {`${form?.description ? ("(" + form?.description + ")") : ""}`}
                                                                            </>
                                                                        }
                                                                        {(form?.form_value !== "xds_form" && form?.form_value !== "police_clearance_certificate_number/invoice_reference_number") &&
                                                                            <>
                                                                                {`${form?.form_label} ${form?.description ? ("(" + form?.description + ")") : ""}`}
                                                                            </>
                                                                        }

                                                                        {form?.required === "True" &&
                                                                            <span style={{ color: "red" }}> * </span>
                                                                        }
                                                                    </label>
                                                                }

                                                                {(form?.form_type === "select" && (form?.form_value === "endpoint_country")) ? <></>
                                                                    :
                                                                    (form?.form_type === "select" && (form?.form_value !== "endpoint_country")) ?
                                                                        <>
                                                                            {/* { console.log(JSON.stringify((form?.select_value?.find((opt:any)=>opt?.value === getValueForKey(form?.key)))) ) } */}
                                                                            {/* <Select
                                                                        name={form?.form_id}
                                                                        // value={JSON?.stringify(form?.select_value?.find((opt:any)=>getValueForKey(form?.key) === opt?.value)) }
                                                                        value={form?.select_value?.find((opt:any)=>getValueForKey(form?.key) === opt?.value) || "" }
                                                                        onChange={e => triggerKeyUpdate(allFormVal[0], form?.endpoint, form?.form_type, form?.form_value, e, form?.form_id, form?.key, form?.regex, `${form?.form_label}`, "", (form?.newlyAdded || false), (allFormVal[1]?.multiple || false), (form?.newlyUnique || ""), (form?.required || "False"))}
                                                                        options={form?.select_value?.map((opt:any) => {
                                                                            return { value: opt?.value, label: opt.name }
                                                                        })}
                                                                        placeholder={`Select ${form?.form_label}`}
                                                                        className={`${errorDataArr?.includes(form?.key) && "input-error"}`}
                                                                        styles={customStyles}
                                                                        theme={(theme) => ({
                                                                            ...theme,
                                                                            colors: {
                                                                                ...theme.colors,
                                                                                primary: '#ddd',
                                                                            },
                                                                        })}
                                                                    /> */}

                                                                            <select
                                                                                name={form?.form_id}
                                                                                className={`form-select ${errorDataArr?.includes(form?.key) && "input-error"}`}
                                                                                onChange={e => triggerKeyUpdate(allFormVal[0], form?.endpoint, form?.form_type, form?.form_value, e?.target?.value, form?.form_id, form?.key, form?.regex, `${form?.form_label}`, "", (form?.newlyAdded || false), (allFormVal[1]?.multiple || false), (form?.newlyUnique || ""), (form?.required || "False"))}
                                                                                autoComplete="off"
                                                                                value={getValueForKey(form?.key) || ""}
                                                                            >
                                                                                <option value="">Select {form?.form_label}</option>
                                                                                {form?.select_value?.map((opt: any, k: number) => (
                                                                                    <option key={k} value={opt?.value}>{opt?.name}</option>
                                                                                ))}
                                                                            </select>
                                                                        </>
                                                                        :
                                                                        form?.form_type === "file" ?
                                                                            <div>
                                                                                {!getValueForKey(form?.key) &&
                                                                                    <div className={`file-input-area p-2 ${errorDataArr?.includes(form?.key) && "input-error"}`}>
                                                                                        {(activeUploadingKey === form?.key) && uploadFileState?.isLoading ? (
                                                                                            <div>
                                                                                                <Spinner
                                                                                                    as="span"
                                                                                                    animation="border"
                                                                                                    size="sm"
                                                                                                    role="status"
                                                                                                    aria-hidden="true"
                                                                                                />
                                                                                                <span className="sr-only">Uploading...</span>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div className="">
                                                                                                <input type={form?.form_type}
                                                                                                    placeholder={form?.form_label}
                                                                                                    name={form?.form_id}
                                                                                                    onChange={e => {
                                                                                                        setActiveUploadingKey(form?.key)
                                                                                                        triggerKeyUpdate(allFormVal[0], form?.endpoint, form?.form_type, form?.form_value, e, form?.form_id, form?.key, "", `${form?.form_label}`, "", (form?.newlyAdded || false), (allFormVal[1]?.multiple || false), (form?.newlyUnique || ""), (form?.required || "False"))
                                                                                                    }}
                                                                                                    autoComplete="off"
                                                                                                    accept=".pdf, .png, .jpg, .jpeg"
                                                                                                    value={getValueForKey(form?.key) || ""}
                                                                                                    draggable={false}
                                                                                                    disabled={uploadFileState?.isLoading}
                                                                                                />
                                                                                                <small>Maximum file size: 15MB</small>
                                                                                                <small>Supported file types: (.pdf, .png, .jpg, .jpeg).</small>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                }

                                                                                {getValueForKey(form?.key) &&
                                                                                    <div style={{ backgroundColor: "#eaeef6" }} className='py-2 px-3'>
                                                                                        <div className="row align-items-center">
                                                                                            <div className="col-9 d-flex align-items-center">
                                                                                                <i className="ri-image-2-line ri-xl me-2" />
                                                                                                <span>
                                                                                                    <p className="p-0 m-0" style={{ fontSize: "14px" }}>{getValueForKey(form?.key)?.name || "Uploaded File"}</p>
                                                                                                    <p className="p-0 m-0" style={{ fontSize: "13px", color: "#8D8D8D" }}>
                                                                                                        {getValueForKey(form?.key).size && 
                                                                                                            <> Size: {getValueForKey(form?.key).size / 1000} KB </>
                                                                                                        }
                                                                                                    </p>
                                                                                                </span>

                                                                                            </div>
                                                                                            <div className="col-3 d-flex justify-content-end">
                                                                                                <span>
                                                                                                    <span className="d-flex align-items-center"
                                                                                                        onClick={e => triggerKeyUpdate(allFormVal[0], form?.endpoint, form?.form_type, form?.form_value, "", form?.form_id, form?.key, "", `${form?.form_label}`, "", (form?.newlyAdded || false), (allFormVal[1]?.multiple || false), (form?.newlyUnique || ""), (form?.required || "False"))}
                                                                                                    >
                                                                                                        <i className="ri-delete-bin-3-line ri-xl me-2 " />
                                                                                                        {/* Remove */}
                                                                                                    </span>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </div>

                                                                            :
                                                                            form?.form_type === "tel" ?
                                                                                <div className="input-group">
                                                                                    <div className="input-group-prepend">
                                                                                        <select className={`form-select ${errorDataArr?.includes(form?.key) && "input-error"}`} value={getValueForPhoneCountry(form?.key) || ""}
                                                                                            style={{ borderRadius: "5px 0px 0px 5px", maxWidth: "150px" }}
                                                                                            onChange={e => triggerKeyUpdate(allFormVal[0], form?.endpoint, form?.form_type, form?.form_value, e.target.value, form?.form_id, form?.key, form?.regex, `${form?.form_label}`, "phoneCountryCode", (form?.newlyAdded || false), (allFormVal[1]?.multiple || false), (form?.newlyUnique || ""), (form?.required || "False"))}
                                                                                        >
                                                                                            {countryList?.sort((a: any, b: any) => a?.code - b?.code)?.map((country, i) => (
                                                                                                <option key={i} value={country?.code} className='add-ellipsis'>
                                                                                                    {country?.name}
                                                                                                </option>
                                                                                            ))}
                                                                                        </select>
                                                                                    </div>
                                                                                    <input type={form?.form_type}
                                                                                        className={`form-control ${errorDataArr?.includes(form?.key) && "input-error"}`} placeholder={form?.form_label}
                                                                                        name={form?.form_id}
                                                                                        onChange={e => triggerKeyUpdate(allFormVal[0], form?.endpoint, form?.form_type, form?.form_value, removeLetters(e.target.value), form?.form_id, form?.key, form?.regex, `${form?.form_label}`, "", (form?.newlyAdded || false), (allFormVal[1]?.multiple || false), (form?.newlyUnique || ""), (form?.required || "False"))}
                                                                                        autoComplete="off"
                                                                                        value={getValueForKey(form?.key) || ""}
                                                                                    />
                                                                                </div>
                                                                                :
                                                                                <>
                                                                                    <input type={form?.form_type}
                                                                                        className={`form-control p-2 ${errorDataArr?.includes(form?.key) && "input-error"}`} placeholder={form?.form_label}
                                                                                        name={form?.form_id}
                                                                                        onChange={e => triggerKeyUpdate(allFormVal[0], form?.endpoint, form?.form_type, form?.form_value, e.target.value, form?.form_id, form?.key, form?.regex, `${form?.form_label}`, "", (form?.newlyAdded || false), (allFormVal[1]?.multiple || false), (form?.newlyUnique || ""), (form?.required || "False"))}
                                                                                        autoComplete="off"
                                                                                        value={getValueForKey(form?.key) || ""}
                                                                                    />
                                                                                </>
                                                                }

                                                                {(errorDataArr?.includes(form?.key)) &&
                                                                    <small className='text-danger'>Missing field required</small>
                                                                }
                                                            </div>
                                                        </span>
                                                    )
                                                    )}

                                                    {allFormVal[1]?.multiple &&
                                                        <>
                                                            {allFormVal[1]?.forms?.some((obj: any) => obj.newlyAdded === true) &&
                                                                <button className='btn btn-deep-green-outline px-3 my-5 me-3'
                                                                    onClick={() => {
                                                                        removeForm(allFormVal[0])
                                                                    }}
                                                                > Remove Form </button>
                                                            }

                                                            <button className='btn btn-deep-green px-3 my-5'
                                                                onClick={() => addNewForm(allFormVal[1]?.forms, allFormVal[0], allFormVal[1]?.forms[0]?.field_title)}
                                                            > Add Form </button>
                                                        </>
                                                    }


                                                    {(allForm[allForm?.length - 1][0] === allFormVal[0]) &&
                                                        <>
                                                            <div className=" mt-3">
                                                                <h5>Consent</h5>

                                                                <p className='add-2line-ellipsis'>
                                                                    By clicking "Proceed";
                                                                    I, the undersigned, hereby authorize bolt to which I have applied for a
                                                                    position, to obtain a background screening report on me in order to verify my
                                                                    particulars as provided in my curriculum vitae and/or any other application/supporting
                                                                    documents for the purpose of my application/employment by and through the Company’s service
                                                                    provider, Prembly Inc and its agents and authorized representatives,
                                                                    in accordance with the Company’s Data Privacy and Bolt’s Privacy Policy.
                                                                </p>

                                                                <small className="link link-underline" onClick={() => setConsentModal(true)}> Read More</small>

                                                            </div>

                                                            <button className='btn btn-deep-green px-5 my-5 w-100' onClick={nextForm} >
                                                                {(backgroundCheckRequestUpdateSubmissionState.isLoading) ? (
                                                                    <div>
                                                                        <Spinner
                                                                            as="span"
                                                                            animation="border"
                                                                            size="sm"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        />
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>
                                                                ) : (
                                                                    'Update'
                                                                )}
                                                            </button>
                                                        </>
                                                    }

                                                </form>
                                            </div>
                                        )}
                                    </div>
                                </>
                            ))}
                        </div>
                    }

                </>
            }

            {formPage === 2 &&
                <BackgroundCheckFaceVerificationComp proceedFromFaceVerification={proceedFromFaceVerification} goToFormPage={goToFormPage} />
            }

            {formPage === 3 &&
                <CheckSummaryComp submitForm={submitForm} goToFormPage={goToFormPage}
                    successModal={successModal} toggleSuccessModal={toggleSuccessModal}
                    faceEnabled={submittedFormState?.resp?.detail?.face_capture}
                    facialDataValues={facialDataValues} collectedData={payloadData}
                    candidatePaymentEnabled={submittedFormState?.resp?.detail?.allow_candidate_pay_for_a_check}
                />
            }

            {formPage === 5 && <CandidatePaymentCompBolt submitForm={submitForm} goToFormPage={goToFormPage} requestId={requestId} editStatus={false} />}

            {formPage === 4 &&
                <div>
                    <div className="col-md-6 col-lg-4 mx-auto">
                        <div className="card">
                            <div className="card-body">
                                <div className="main-modal-body text-center">
                                    <div className="col-md-6 mx-auto mt-4">
                                        <Player
                                            src={successVerifGif}
                                            className="player"
                                            loop
                                            autoplay
                                        />
                                    </div>
                                    <div className="my-5">
                                        <h4>Thank You</h4>
                                        <p className='my-3'>
                                            You have successfully submitted your information.
                                            A summary notification has been forwarded to your email.
                                            Please close this tab or proceed to our website
                                            {window.location.host.includes("peleza") ?
                                                <a href={"https://peleza.com"} className='link link-underline mx-1'>
                                                    here
                                                </a>
                                                :
                                                <a href={"https://prembly.com/backgroundCheck"} className='link link-underline mx-1'>
                                                    here
                                                </a>
                                            }
                                            to know more about our solutions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {formPage === 6 &&
                <div>
                    <div className="col-md-6 col-lg-4 mx-auto">
                        <div className="card">
                            <div className="card-body">
                                <div className="main-modal-body text-center">
                                    <div className="col-md-6 mx-auto mt-4">
                                        <Player
                                            src={cancelGif}
                                            className="player"
                                            loop
                                            autoplay
                                        />
                                    </div>
                                    <div className="my-5">
                                        <h4>Check Declined</h4>
                                        <p className='my-3'>
                                            You rejected this check.
                                            Please close this tab or proceed to our website
                                            {window.location.host.includes("peleza") ?
                                                <a href={"https://peleza.com"} className='link link-underline mx-1'>
                                                    here
                                                </a>
                                                :
                                                <a href={"https://prembly.com/backgroundCheck"} className='link link-underline mx-1'>
                                                    here
                                                </a>
                                            }
                                            to know more about our solutions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }


            {consentModal &&
                <div className="main-modal">
                    <div className="main-modal-content card col-md-7 col-lg-5 mx-auto ">
                        <span onClick={() => setConsentModal(false)}>
                            <i className="ri-close-line close-modal" />
                        </span>
                        <div className="card-body">
                            <div className="main-modal-body">
                                <div className="main-modal-header col-md-3 col-lg-2">
                                    <h5>Consent</h5>
                                </div>
                                <div className='mt-3' style={{ color: '#005e7a', textAlign: 'start', fontSize: '14px' }}>
                                    <p>
                                        I, the undersigned, hereby authorize Bolt to which I have applied for a
                                        position, to obtain a background screening report on me in order to verify my
                                        particulars as provided in my curriculum vitae and/or any other application/supporting
                                        documents for the purpose of my application/employment by and through the Company’s service
                                        provider, Prembly Inc and its agents and authorized representatives,
                                        in accordance with the Company’s Data Privacy and Bolt’s Privacy Policy.
                                    </p>

                                    <ol>
                                        <li>
                                            <p>
                                                I confirm that I have read and understood the Company’s Data Privacy and Prembly’s Privacy Policy.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                The above-mentioned employment background screening report will include job related information,
                                                such as identity, education history, professional qualifications and memberships, criminal history,
                                                employment history, work references, credit history, driving records, right to work verifications,
                                                tax compliance and other applicable public record information including any relevant information t
                                                hat may necessary to inform my employment.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                I hereby declare that l understand and will cooperate with persons conducting the screening and to assist
                                                in case more information is required or when approaching my referees, former employer(s), educational
                                                establishments, government agencies and any other relevant entities.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                I further authorize all current or previous employers to discuss my relevant personal and employment
                                                history with Prembly with consent to release of such information orally or in writing, and hereby release
                                                them from all liability. Further, I understand and agree that Prembly is relying on the information provided
                                                to it by my past employers/relevant sources and is not responsible or liable for accuracy or completeness of
                                                the information provided to it from such sources.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                I further authorize the Company and Prembly to share and/or disclose a copy of this authorization to any
                                                person(s), educational establishment(s), former employer(s), business entity(ies), private data source(s)
                                                or government/public body(s) (“Third Party”) and for this authorization to act as my specific consent to
                                                any such Third Party for the disclosure to the Company/ Prembly of any personal data about me.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                I hereby release the Company, Prembly and any Third Parties from any claims, costs, expenses or rights
                                                of action of any kind whatsoever (“Claims”) which I (or others on my behalf) may have against the Company
                                                and/or Prembly arising directly or indirectly out of/or in connection with the provision of the employment
                                                background screening report, save where such Claim arises directly as a result of the gross negligence of
                                                the Company or Prembly.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                I understand and consent to my information/details may be collected, stored, retained or processed anywhere
                                                in the world and not necessarily within the Republic of Kenya.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                This authorization given in this Consent Form expires automatically if my application is rejected or
                                                following termination of my employment with the Company in the event that my application is unsuccessful.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Further, I understand and consent to Prembly sharing its findings/report with the company via electronic
                                                medium or otherwise; and that the Prembly may retain the information contained in the said findings and/or
                                                report and any update thereto without any restrictions, reservation, or expiration subject to expiration
                                                terms in Clause 6 above.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Finally, I certify that the information provided in my application for employment is true and correct and
                                                that my job application and/or employment might be terminated based on any false or misleading information
                                                subject to local law provisions and jurisdiction.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                I confirm that this is my willful, express, unequivocal, free, specific and informed indication consent and
                                                authorization.
                                            </p>
                                        </li>
                                    </ol>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }



        </div>
    )
}
