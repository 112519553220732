import React, { useEffect, useState } from 'react'
import NotificationToast from '../../../../../../components/utils/notifToast'
import { backgroundCheckRequestAnswerUploadRequest, backgroundCheckRequestFileUploadRequest, backgroundCheckRequestGetCandidateFormRequest } from '../../../../../../redux/actions/products/backgroundCheck/request/checks'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../redux/reducers'
import CheckSummaryComp from '../../../../../../components/products/backgroundCheck/request/checks/checkSummary'
import { Player } from '@lottiefiles/react-lottie-player'
import successVerifGif from '../../../../../../assets/successVerif.json'
import cancelGif from '../../../../../../assets/cancel.json'
import Mainloader, { removeLetters, replaceUnderscores } from '../../../../../../components/utils'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import BackgroundCheckFaceVerificationComp from '../../../../../../components/products/backgroundCheck/request/checks/faceVerification'
import { countryList } from '../../../../../../components/utils/countries'
import { getCountryFlag } from '../../../../../../components/utils/flags'
import { parsePhoneNumber } from 'awesome-phonenumber'
import { Spinner } from 'react-bootstrap'
import CandidatePaymentComp from '../../../../../../components/products/backgroundCheck/request/checks/candidatePayment'
import global from '../../../../../../redux/constants/global'




interface IcurrentForm {
    forms: any;
}
export default function BackgroundCheckRequestMerchantCandidateFormPage() {
    const [notifVal, setNotifVal] = useState(false)
    const [notif, setNotif] = useState("")
    const [notifTitle, setNotifTitle] = useState("")
    const [allForm, setAllForm] = useState<any>([]);
    const [formPage, setFormPage] = useState(1)
    const [currentStep, setCurrentStep] = useState(0);
    const [currentForm, setCurrentForm] = useState<any | IcurrentForm>({});

    const [payloadData, setPayloadData] = useState({})
    // const [previewData, setPreviewData] = useState({})
    const [previewReformat, setPreviewReformat] = useState({})
    const [payloadDataArr, setPayloadDataArr] = useState<any[]>([])

    const [facialDataValues, setFacialDataValues] = useState({})
    const [phoneCountryCode, setPhoneCountryCode] = useState("AF");
    const [activeUploadingKey, setActiveUploadingKey] = useState("");

    const [successModal, setSuccessModal] = useState(false)
    const [errorDataArr, setErrorDataArr] = useState<any[]>([])


    const candidateFormState = useSelector((state: RootState) => state.backgroundCheckRequestGetCandidateFormReducer)
    const uploadFileState = useSelector((state: RootState) => state.backgroundCheckRequestFileUploadReducer)

    const location = useLocation();
    const queryParams = new URLSearchParams(window.location.search)
    let requestId = queryParams.get("ref") || ""

    const bioData = location.state;

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        getCandidateForm()
    }, [])

    useEffect(() => {
        getFormAt(currentStep)
    }, [currentStep, candidateFormState?.resp?.detail])


    let getCandidateForm = () => {
        const callback = (data: any) => {

            if (data.status) {
                let splittedName = data?.detail?.name?.split(" ")

                if (data?.detail?.rejected && !data?.detail?.is_a_manual_service) {
                    setFormPage(6)
                    return
                }
                if (data?.detail?.request_completed && !data?.detail?.is_a_manual_service) {
                    setFormPage(4)
                    return
                }
                data?.detail?.form?.bio_data?.forms?.forEach((val: any,) => {
                    if (val?.form_value === "first_name") {
                        triggerKeyUpdate("bio_data", val?.endpoint, val?.form_type, val?.form_value, bioData?.first_name || splittedName[0] || "", val?.form_id, val?.key, val?.regex, val?.form_label, "", false, false, "", (val?.required || "False"))
                    }
                    if (val?.form_value === "last_name") {
                        triggerKeyUpdate("bio_data", val?.endpoint, val?.form_type, val?.form_value, bioData?.last_name || splittedName[1] || "", val?.form_id, val?.key, val?.regex, val?.form_label, "", false, false, "", (val?.required || "False"))
                    }
                    if (val?.form_value === "email") {
                        triggerKeyUpdate("bio_data", val?.endpoint, val?.form_type, val?.form_value, bioData?.email || data?.detail?.email || "", val?.form_id, val?.key, val?.regex, val?.form_label, "", false, false, "", (val?.required || "False"))
                    }
                })
            }
            else {
                setNotifTitle("Error")
                setNotif(data.detail)
                setNotifVal(true)
            }
        };
        let data: any = {
            values: {
                form_id: requestId,
            },
            callback,
        };
        dispatch(backgroundCheckRequestGetCandidateFormRequest(data))
    }



    let getFormAt = async (index: number) => {
        if (!candidateFormState?.isLoading && candidateFormState?.resp?.detail) {
            if (index === 0) {
                setAllForm(Object?.entries(candidateFormState?.resp?.detail?.form)?.reverse())
                setCurrentForm(Object?.entries(candidateFormState?.resp?.detail?.form)?.reverse()[index])
            }
            else {
                setCurrentForm(allForm[index])
            }

            // setCurrentForm(Object?.entries(candidateFormState?.resp?.detail?.form)?.reverse()[index][1])
        }
    }

    let triggerKeyUpdate = (title: any, endpoint: any, formType: any, key: any, val: any, id: any, uniqueId: any, regex: any, label: any, specKey: any, newlyAdded: any, multipleValue: any, newlyUnique: any, required:any) => {

        let keyDataArr: any = payloadDataArr


        if (formType === 'file') {
            var file = val?.target?.files[0]
            let reader = new FileReader()

            if (val?.target?.files?.length > 0) {
                reader.readAsDataURL(file)
                reader.onload = () => {
                    let imgBase: any = reader.result
                    let keyDataIndex = payloadDataArr?.findIndex(item => ((item?.form_value === key) && (item?.title === title) && (item?.key === (uniqueId))))


                    if (file && (
                        (file.type !== "image/png") &&
                        (file.type !== "image/jpeg") &&
                        (file.type !== "image/jpg") &&
                        (file?.type !== "application/pdf")
                    )) {
                        setNotifTitle('Error')
                        setNotif(`Unsupported ${label} format Uploaded`)
                        setNotifVal(true)
                        // return
                    }
                    else if (file && (file?.size > 15200000)) {
                        // else if (file && (file?.size > 710000)) {

                        setNotifTitle('Error')
                        setNotif(`${label} is larger than 15MB`)
                        setNotifVal(true)

                        setTimeout(() => {
                            setNotif("")
                        }, 3000);
                        // return
                    }
                    else {

                        const callback = (data: any) => {
                            if (data?.status) {
                                if (keyDataIndex !== -1) {
                                    keyDataArr.splice(keyDataIndex, 1)
                                    keyDataArr.push({ "title": title, "endpoint": endpoint, "form_value": key, "form_data": data?.detail, "form_id": id, "key": uniqueId, "regex": regex, "file_front_Data": file, "label": label, "newlyAdded": newlyAdded, "multipleValue": multipleValue, "newlyUnique": newlyUnique, "required":required })
                                }
                                else {
                                    keyDataArr.push({ "title": title, "endpoint": endpoint, "form_value": key, "form_data": data?.detail, "form_id": id, "key": uniqueId, "regex": regex, "file_front_Data": file, "label": label, "newlyAdded": newlyAdded, "multipleValue": multipleValue, "newlyUnique": newlyUnique, "required":required })
                                }

                                setPayloadDataArr(keyDataArr)
                                reformatData()
                            }
                            else {
                                setNotifTitle("Error")
                                setNotif(data.detail)
                                setNotifVal(true)
                            }
                        };
                        let data: any = {
                            values: {
                                file: imgBase,
                            },
                            callback,
                        };
                        dispatch(backgroundCheckRequestFileUploadRequest(data))

                    }
                    // if (keyDataIndex !== -1) {
                    //     keyDataArr.splice(keyDataIndex, 1)
                    //     keyDataArr.push({ "title": title, "endpoint": endpoint, "form_value": key, "form_data": imgBase, "form_id": id, "key": uniqueId, "regex": regex, "file_front_Data": file, "label": label })
                    // }
                    // else {
                    //     keyDataArr.push({ "title": title, "endpoint": endpoint, "form_value": key, "form_data": imgBase, "form_id": id, "key": uniqueId, "regex": regex, "file_front_Data": file, "label": label })
                    // }

                    // setPayloadDataArr(keyDataArr)
                    // reformatData()
                }
            }
            else {
                let keyDataIndex = payloadDataArr?.findIndex(item => ((item?.form_value === key) && (item?.title === title) && (item?.key === (uniqueId))))
                // setFileCheck(imgBase)

                if (keyDataIndex !== -1) {
                    keyDataArr.splice(keyDataIndex, 1)
                    // keyDataArr.push({ "title": "", "endpoint": "", "form_value": "", "form_data": "", "form_id": "", "key": "", "regex": "" })
                }
                // else {
                //     keyDataArr.push({ "title": "", "endpoint": "", "form_value": "", "form_data": "", "form_id": "", "key": "", "regex": "" })
                // }

                setPayloadDataArr(keyDataArr)
                reformatData()
            }
        }
        else if (formType === 'tel') {

            let filteredKeyDataArr = keyDataArr?.filter((item: any) => ((item?.form_value === key) && (item?.title === title) && (item?.key === (uniqueId))))

            if (filteredKeyDataArr?.length > 0) {
                if (specKey === 'phoneCountryCode') {
                    filteredKeyDataArr[0]['phoneCountryCode'] = val
                }
                else {
                    filteredKeyDataArr[0]["form_data"] = val
                }
            }
            else {
                if (specKey === 'phoneCountryCode') {
                    keyDataArr.push({ "title": title, "endpoint": endpoint, "form_value": key, "form_data": "", "form_id": id, "key": uniqueId, "regex": regex, "phoneCountryCode": val, "label": label, "newlyAdded": newlyAdded, "multipleValue": multipleValue, "newlyUnique": newlyUnique, "required":required })
                }
                else {
                    keyDataArr.push({ "title": title, "endpoint": endpoint, "form_value": key, "form_data": val, "form_id": id, "key": uniqueId, "regex": regex, "phoneCountryCode": "", "label": label, "newlyAdded": newlyAdded, "multipleValue": multipleValue, "newlyUnique": newlyUnique, "required":required })
                }
            }
            // keyDataArr?.forEach((item: any) => {

            //     if ((item?.form_value === key) && (item?.title === title) && (item?.key === (uniqueId))) {
            //         console.log("e dey there")

            //         if (specKey === 'phoneCountryCode') {
            //             keyDataObject[0]['phoneCountryCode'] = val
            //             item["phoneCountryCode"] = val
            //         }
            //         else {
            //             keyDataObject[0]["form_data"] = val
            //             item["form_data"] = val
            //         }
            //     }
            // });


            // if (keyDataIndex === -1) {
            //     if (specKey === 'phoneCountryCode') {
            //         keyDataArr.push({ "title": title, "endpoint": endpoint, "form_value": key, "form_data": "", "form_id": id, "key": uniqueId, "regex": regex, "phoneCountryCode": val, "label": label })
            //     }
            //     else {
            //         keyDataArr.push({ "title": title, "endpoint": endpoint, "form_value": key, "form_data": val, "form_id": id, "key": uniqueId, "regex": regex, "phoneCountryCode": "", "label": label })
            //     }
            // }
            setPayloadDataArr(keyDataArr)
            reformatData()

            // console.log(payloadData)

        }
        else {
            let keyDataIndex = payloadDataArr?.findIndex(item => ((item?.form_value === key) && (item?.title === title) && (item?.key === (uniqueId))))

            if (keyDataIndex !== -1) {
                keyDataArr.splice(keyDataIndex, 1)
                keyDataArr.push({ "title": title, "endpoint": endpoint, "form_value": key, "form_data": val, "form_id": id, "key": uniqueId, "regex": regex, "label": label, "newlyAdded": newlyAdded, "multipleValue": multipleValue, "newlyUnique": newlyUnique, "required":required })
            }
            else {
                keyDataArr.push({ "title": title, "endpoint": endpoint, "form_value": key, "form_data": val, "form_id": id, "key": uniqueId, "regex": regex, "label": label, "newlyAdded": newlyAdded, "multipleValue": multipleValue, "newlyUnique": newlyUnique, "required":required })
            }

            // setPayloadData(keyData)
            setPayloadDataArr(keyDataArr)
            reformatData()
        }

    }


    let reformatData = () => {
        const correctData: any = {};
        const correctPreData: any = {};

        payloadDataArr?.forEach((obj) => {
            correctPreData[obj.title] = payloadDataArr?.filter((item: any) => item?.title === obj?.title)
        });

        // console.log(parsePhoneNumber( dat?.form_data, { regionCode: phoneCountryCode }))

        // console.log(correctPreData)

        // setPayloadData({ ...correctData })
        // setPreviewData({ ...correctPreData })

        setPayloadData({ ...correctPreData })

        // this is submission reformat, for the new submission structure 
        setPreviewReformat(finalReformat({ ...correctPreData }))
    }

    let finalReformat = (payload: any) => {
        let newPayload: any = { ...payload };

        // Find the category with 'multipleValue' set to true
        const categoryWithMultiple = Object.keys(payload).find(category => {
            return payload[category].some((obj: any) => obj.multipleValue === true);
        });

        if (categoryWithMultiple) {
            const categoryArray = newPayload[categoryWithMultiple];
            const groupedByUnique: any = {};
            // const newlyAddedFalse:any = [];
            // const newlyAddedTrue:any = [];

            categoryArray.forEach((obj: any) => {
                // if (obj.multipleValue === true) {
                //     if (obj.newlyAdded === false) {
                //         newlyAddedFalse.push(obj);
                //     } else if (obj.newlyAdded === true) {
                //         newlyAddedTrue.push(obj);
                //     }
                // } else {
                //     // Include non-multipleValue objects directly
                //     newlyAddedFalse.push(obj);
                // }
                if (obj.multipleValue === true) {
                    const uniqueKey: any = obj.newlyUnique || 'default';
                    if (!groupedByUnique[uniqueKey]) {
                        groupedByUnique[uniqueKey] = [];
                    }
                    groupedByUnique[uniqueKey].push(obj);
                } else {
                    // Include non-multipleValue objects directly
                    if (!groupedByUnique['default']) {
                        groupedByUnique['default'] = [];
                    }

                    groupedByUnique['default'].push(obj);
                }
            });

            // // Update the payload with the restructured category
            // newPayload[categoryWithMultiple] = [newlyAddedFalse, newlyAddedTrue];


            // Convert grouped objects into an array of arrays
            newPayload[categoryWithMultiple] = Object.values(groupedByUnique);
        }

        return newPayload;

    }

    function getMissingData(arrA:any, arrB:any) {
        const existingFormIds = new Set(arrB?.filter((item:any) => item.form_data)?.map((item:any) => item?.form_id));
      
        return arrA.reduce((missingIds:any, item:any) => {
          if (!existingFormIds.has(item.form_id)) {
            missingIds.push(item.form_id);
          }
          return missingIds;
        }, []);
      }


    let nextForm = (formName: any) => {
        setErrorDataArr([])

        let currentPageData = payloadDataArr?.filter((val: any) => val?.title === formName)

        // if ((payloadDataArr?.length < 1) || (payloadDataArr?.some((val: any) => val?.form_data === ""))
        //     ||
        //     // (currentForm[1]?.length !== (payloadDataArr?.filter((val: any) => val?.title === currentForm[0])?.length))
        //     (currentForm[1]?.forms?.length !== (payloadDataArr?.filter((val: any) => val?.title === currentForm[0])?.length))
        // ) {
        //     setNotifTitle('Error')
        //     setNotif('Please fill in all fields')
        //     setNotifVal(true)
        //     return
        // }

        // console.log(payloadDataArr.filter((val: any) => val?.required === "True" && val?.title === currentForm[0])?.some((val: any) => val?.form_data === "" ))

        if ((payloadDataArr?.length < 1) || (currentForm[1]?.forms.filter((val: any) => val?.required === "True")?.length !== (payloadDataArr?.filter((val: any) => val?.required === "True" && val?.title === currentForm[0])?.length) )
            ||
            (currentForm[1]?.forms.filter((val: any) => val?.required === "True")?.length === (payloadDataArr?.filter((val: any) => val?.required === "True" && val?.title === currentForm[0])?.length) 
            &&
            payloadDataArr.filter((val: any) => val?.required === "True" && val?.title === currentForm[0])?.some((val: any) => val?.form_data === "" )
            )
        ) {
            setErrorDataArr([...getMissingData(currentForm[1]?.forms.filter((val: any) => val?.required === "True"),payloadDataArr ) ])

            setNotifTitle('Error')
            setNotif('Please fill in all necessary fields')
            setNotifVal(true)
            return
        }
        else if (formName === allForm[allForm?.length - 1][0]) {
            currentPageData?.forEach((dat: any) => {
                // if (dat?.file_front_Data && (
                //     (dat?.file_front_Data.type !== "image/png") &&
                //     (dat?.file_front_Data.type !== "image/jpeg") &&
                //     (dat?.file_front_Data.type !== "image/jpg") &&
                //     (dat?.file_front_Data?.type !== "application/pdf")
                // )) {
                //     setNotifTitle('Error')
                //     setNotif(`Unsupported ${dat?.label} format Uploaded`)
                //     setNotifVal(true)
                //     return
                // }
                // else if (dat?.file_front_Data && (dat?.file_front_Data?.size > 75000)) {
                //     setNotifTitle('Error')
                //     setNotif(`${dat?.label} is larger than 80KB`)
                //     setNotifVal(true)
                //     return
                // }
                if (!new RegExp(dat?.regex)?.test(dat?.form_data)) {
                    setNotifTitle('Error')
                    setNotif(`Invalid ${dat?.label} format`)
                    setNotifVal(true)
                    return
                }
                else if (dat?.form_value === "phone_number" && (!parsePhoneNumber(dat?.form_data, { regionCode: dat?.phoneCountryCode })?.valid)) {
                    setNotifTitle('Error')
                    setNotif(`Invalid ${dat?.label} format`)
                    setNotifVal(true)
                    return
                }

            })
            if ((!currentPageData?.some((val: any) => new RegExp(val?.regex)?.test(val?.form_data) === false))
                &&
                (!currentPageData?.some((val: any) => val?.form_value === "phone_number" && (parsePhoneNumber(val?.form_data, { regionCode: val?.phoneCountryCode })?.valid) === false))
                // &&
                // ((currentPageData?.some((val: any) => val?.file_front_Data && (val?.file_front_Data?.size > 75000)) === false))
                // &&
                // ((currentPageData?.some((val: any) => val?.file_front_Data && (
                //     (val?.file_front_Data.type !== "image/png") &&
                //     (val?.file_front_Data.type !== "image/jpeg") &&
                //     (val?.file_front_Data.type !== "image/jpg") &&
                //     (val?.file_front_Data?.type !== "application/pdf"))) === false)
                // )
            ) {
                reformatData()
                if (candidateFormState?.resp?.detail?.face_capture === true) {
                    goToFormPage(2)
                }
                else {
                    goToFormPage(3)
                }
            }
        }
        else {
            currentPageData?.forEach((dat: any) => {
                // if (dat?.file_front_Data && (
                //     (dat?.file_front_Data.type !== "image/png") &&
                //     (dat?.file_front_Data.type !== "image/jpeg") &&
                //     (dat?.file_front_Data.type !== "image/jpg") &&
                //     (dat?.file_front_Data?.type !== "application/pdf")
                // )) {
                //     setNotifTitle('Error')
                //     setNotif(`Unsupported ${dat?.label} format Uploaded`)
                //     setNotifVal(true)
                //     return
                // }
                // else if (dat?.file_front_Data && (dat?.file_front_Data?.size > 75000)) {
                //     setNotifTitle('Error')
                //     setNotif(`${dat?.label} is larger than 70KB`)
                //     setNotifVal(true)
                //     return
                // }
                if (!new RegExp(dat?.regex)?.test(dat?.form_data)) {
                    setNotifTitle('Error')
                    setNotif(`Invalid ${dat?.label} format`)
                    setNotifVal(true)
                    return
                }
                else if (dat?.form_value === "phone_number" && (!parsePhoneNumber(dat?.form_data, { regionCode: dat?.phoneCountryCode || phoneCountryCode })?.valid)) {
                    setNotifTitle('Error')
                    setNotif(`Invalid ${dat?.label} format`)
                    setNotifVal(true)
                    return
                }
            })

            if ((!currentPageData?.some((val: any) => new RegExp(val?.regex)?.test(val?.form_data) === false))
                &&
                (!currentPageData?.some((val: any) => val?.form_value === "phone_number" && (parsePhoneNumber(val?.form_data, { regionCode: val?.phoneCountryCode || phoneCountryCode })?.valid) === false))
                // &&
                // ((currentPageData?.some((val: any) => val?.file_front_Data && (val?.file_front_Data?.size > 75000)) === false))
                // &&
                // ((currentPageData?.some((val: any) => val?.file_front_Data && (
                //     (val?.file_front_Data.type !== "image/png") &&
                //     (val?.file_front_Data.type !== "image/jpeg") &&
                //     (val?.file_front_Data.type !== "image/jpg") &&
                //     (val?.file_front_Data?.type !== "application/pdf"))) === false)
                // )
            ) {
                reformatData()
                setCurrentStep(currentStep + 1)
            }
            // verifyData()
        }
    }


    let proceedFromFaceVerification = (val: any) => {
        setFacialDataValues(val)
        setFormPage(3)
    }

    let submitForm = () => {
        const callback = (data: any) => {
            if (data?.status) {
                setNotifTitle("Success")
                setNotif("Response successfully sent")
                setNotifVal(true)

                setTimeout(() => {
                    setFormPage(4)
                }, 1000);

                setTimeout(() => {
                    window.location.href =`${global.appBaseUrl}BackgroundCheck/Requests`
                }, 4000);

                // toggleSuccessModal()
            }
            else {
                setNotifTitle("Error")
                setNotif(data.detail)
                setNotifVal(true)
            }
        };
        let data: any = {
            values: {
                request_id: requestId,
                // ids: Object.keys(facialDataValues)?.length > 0 ? {...payloadData, ...facialDataValues} : payloadData

                // ids: (candidateFormState?.resp?.detail?.face_capture === true) ? { ...payloadData, ...facialDataValues } : payloadData
                ids: (candidateFormState?.resp?.detail?.face_capture === true) ? { ...previewReformat, ...facialDataValues } : previewReformat

            },
            callback,
        };
        dispatch(backgroundCheckRequestAnswerUploadRequest(data))
    }

    let goToFormPage = (val: any) => {
        setFormPage(val)
    }

    let getValueForKey = (id: any) => {
        const mainValue = payloadDataArr?.filter((val: any) => ((val.key === id)))[0];
        return mainValue?.file_front_Data || mainValue?.form_data;
    }


    let getValueForPhoneCountry = (id: any) => {
        const mainValue = payloadDataArr?.filter((val: any) => ((val.key === id)))[0];
        // console.log(mainValue)
        return mainValue?.phoneCountryCode;
    }


    // let getValueForKey = (title: string, key: string, id: any) => {
    //     const mainValue = payloadDataArr?.find((val: any) => (val.title === title) && (val.form_value === key) && (val.form_id === id));
    //     return mainValue?.form_data;
    // }

    let toggleSuccessModal = () => {
        setSuccessModal(!successModal)
    }

    // console.log(payloadData)


    function findFirstUniqueValue(arr: any, key: any) {
        const seenValues = new Set();
        const firstOccurrences = [];

        for (let i = 0; i < arr.length; i++) {
            const item = arr[i];
            const value = item[key];

            if (value !== undefined) {
                if (!seenValues.has(value)) {
                    seenValues.add(value);
                    firstOccurrences.push(item);
                }
            }
        }

        return firstOccurrences;
    }

    function countOccurrences(arr: any, property: any, value: any) {
        let count = 0;
        for (let obj of arr) {
            // if ((obj[property] === value) && !obj["newlyAdded"]) {
            if ((obj[property] === value)) {
                count++;
            }
        }
        return count;
    }

    // function countNewData(arr:any, property:any, value:any) {

    //     let count = 0;

    //     for (let obj of arr) {
    //         if (obj.hasOwnProperty(property) && (obj[property] === true) && (obj["fieldTitle"] === value)) {
    //             count++;
    //         }
    //     }

    //     return count;
    // }

    let addNewForm = (newForm: any, formName: any, fieldTitle: any) => {
        let newData: any = []
        allForm?.forEach((val: any) => {
            if (val[0] === formName) {
                let getEditedOccurences = () => {
                    // console.log(countOccurrences(val[1]?.forms, "field_title", fieldTitle));
                    let returnedOccurence = countOccurrences(val[1]?.forms, "form_label", newForm[0].form_label)

                    // let countNewlyAdded = countNewData(val[1]?.forms, "newlyAdded", fieldTitle)


                    newForm.forEach((obj: any) => {
                        if (!obj?.newlyAdded && (
                            (obj?.endpoint === "character_reference_ge" && obj?.form_value !== "cv") 
                            || 
                            (obj?.endpoint === "performance_check_ge" && obj?.form_value !== "cv")
                        ) ) {
                            newData?.push({
                                ...obj,
                                // field_title : obj.field_title + " " + countNewlyAdded++,
                                // key : obj.key + " " + countNewlyAdded++,

                                // field_title : obj.field_title,
                                // key : obj.key+countNewlyAdded+1,
                                key: obj.key + returnedOccurence,
                                "newlyAdded": true,
                                "newlyUnique": "unique" + returnedOccurence
                            })
                        }
                        if (!obj?.newlyAdded && (
                            (obj?.endpoint !== "character_reference_ge") && 
                            (obj?.endpoint !== "performance_check_ge")  
                        ) ) {
                            newData?.push({
                                ...obj,
                                key: obj.key + returnedOccurence,
                                "newlyAdded": true,
                                "newlyUnique": "unique" + returnedOccurence
                            })
                        }

                        // newData?.push(obj);
                    })
                    // console.log([...newData]);

                    return [...newData]
                }

                if (val[1] && Array.isArray(val[1].forms)) {
                    val[1].forms = val[1].forms.concat(getEditedOccurences());
                    // console.log(val[1].forms)
                    // setAllForm([...val[1].forms])
                    setAllForm([...allForm])
                } else {
                    // console.log(getEditedOccurences())
                    val[1] = { forms: getEditedOccurences() };
                    // console.log(val[1])
                    // setAllForm([...val[1]])
                    setAllForm([...allForm])
                }


                // newData = (newForm.forEach((obj:any) => {
                //     // if (obj.field_title === fieldTitle) {
                //         obj.field_title = countOccurrences(val[1]?.forms, "field_title", fieldTitle)+1;
                //     // }
                // }))
                // {val[1]?.forms, val[1]?.forms?.concat(newForm)}
                // console.log(val)
                // console.log(val[1]?.forms?.concat(newForm))



                // if (val[1] && Array.isArray(val[1].forms)) {
                //     val[1].forms = val[1].forms.concat(newForm);
                //     setAllForm([...allForm])
                // } else {
                //     val[1] = { forms: newForm };
                //     setAllForm([...allForm])
                // }

            }
        })

        // setAllForm(newAllForms)

        // console.log("all formsss",allForm)
        // console.log("new formssss",newForm)
    }




    let removeForm = (formName: any) => {

        let allNewForm: any = allForm;
        let newPayloadArr: any = payloadDataArr;

        allNewForm?.forEach((val: any) => {
            if (val[0] === formName) {
                const lastObject: any = val[1]?.forms[val[1]?.forms.length - 1];

                if (lastObject && lastObject.newlyUnique) {
                    const lastNewlyUniqueValue = lastObject.newlyUnique;

                    val[1].forms = val[1]?.forms?.filter((obj: any) => {
                        return !obj.newlyUnique || obj.newlyUnique !== lastNewlyUniqueValue;
                    });

                    newPayloadArr = newPayloadArr.filter((obj: any) => {
                        return !obj.newlyUnique || obj.newlyUnique !== lastNewlyUniqueValue;
                    })
                }

                setAllForm([...allNewForm])

                setPayloadDataArr([...newPayloadArr])

            }
        })

    }

    return (
        <div>

            {(notif && notifVal) && <NotificationToast title={notifTitle} message={notif} closeNotif={() => setNotifVal(!notifVal)} />}

            <div className="text-white py-4 mb-5" style={{ background: "#003E51" }}>
                <div className="container">
                    <h5>Complete A Background Check</h5>
                </div>
            </div>

            {candidateFormState?.isLoading && <Mainloader />}

            {formPage === 1 &&
                <>
                    {!candidateFormState?.isLoading &&
                        <div className="container">
                            <div>
                                <small> step {currentStep + 1} of {allForm?.length}</small>
                                <h1> {replaceUnderscores(currentForm[0], "upperFirstAll")} </h1>
                                <p>
                                    Please enter your {replaceUnderscores(currentForm[0], "lower")} details 
                                    {   
                                        `
                                            ${((currentForm[0] === "duration_check") && (candidateFormState?.resp?.detail?.check_type_additional_data?.find((addData:any)=> addData?.subservice === currentForm[0]))?.duration_reference) && 
                                                " for the past " +
                                                (candidateFormState?.resp?.detail?.check_type_additional_data?.find((addData:any)=> addData?.subservice === currentForm[0]))?.duration_reference
                                                + '. Select "Add Forms" to provide more addresses '
                                            }
                                        `
                                    }.
                                    {((currentForm[0] === "address_verification") || (currentForm[0] === "duration_check"))  &&
                                        " For fields where information is not available, enter N/A."
                                    }

                                    {((currentForm[0] === "professional_reference" && (candidateFormState?.resp?.detail?.check_type_additional_data?.find((addData:any)=> addData?.subservice === currentForm[0]))?.duration_reference) ||
                                        (currentForm[0] === "character_reference_ge" && (candidateFormState?.resp?.detail?.check_type_additional_data?.find((addData:any)=> addData?.subservice === currentForm[0]))?.duration_reference) ||
                                        (currentForm[0] === "performance_check_ge" && (candidateFormState?.resp?.detail?.check_type_additional_data?.find((addData:any)=> addData?.subservice === currentForm[0]))?.duration_reference)
                                    ) &&
                                        `Provide atleast ${(candidateFormState?.resp?.detail?.check_type_additional_data?.find((addData:any)=> addData?.subservice === currentForm[0]))?.duration_reference}
                                        by selecting "Add Form"`
                                    }
                                </p>
                            </div>
                            <div className='row justify-content-between '>
                                {allForm?.length > 0 && (
                                    <div className="col-md-5 mt-5">
                                        <form onSubmit={e => e.preventDefault()} autoComplete="off" className=''>
                                            {currentForm && currentForm[1]?.forms?.map((form: any, i: number) => (
                                                    <span key={i}>
                                                        {/* {findFirstUniqueValue(currentForm[1], "field_title")?.map((title: any, ind: number) => { */}
                                                        {findFirstUniqueValue(currentForm[1]?.forms, "field_title")?.map((title: any, ind: number) => {
                                                            if (title?.key === form?.key) {
                                                                return (
                                                                    <h5 className={ind !== 0 ? "mt-5" : ""} key={ind}>{title?.field_title}</h5>
                                                                )
                                                            }
                                                        })}

                                                        {findFirstUniqueValue(currentForm[1]?.forms, "newlyUnique")?.map((title: any, ind: number) => {
                                                            if (title?.key === form?.key) {
                                                                return (
                                                                    <hr key={ind} className='mt-5' />
                                                                )
                                                            }
                                                        })}


                                                        <div className="mt-1" key={i}>
                                                            <label htmlFor={form?.form_label}>
                                                                {form?.form_value === "xds_form" &&
                                                                    <>
                                                                        {`${form?.form_label} ${form?.description ?
                                                                            ("(" +  (form?.description.substring(0, 91)) ) 
                                                                            : ""}`
                                                                        }
                                                                        {form?.description && 
                                                                            <a style={{color:"#0489E5"}} href={form?.description.substring(93, 175)} target='_blank' rel="noreferrer" className='link link-underline'>
                                                                                {/* {form?.description.substring(92, 176)} */}
                                                                                View here
                                                                            </a>
                                                                        } 
                                                                        {")"}
                                                                    </>
                                                                }
                                                                {form?.form_value !== "xds_form" &&
                                                                    <>
                                                                        {`${form?.form_label} ${form?.description ? ("(" + form?.description + ")") : ""}`}
                                                                    </>
                                                                }

                                                                {form?.required === "True" &&
                                                                    <span style={{color:"red"}}> * </span>
                                                                }
                                                            </label>
                                                            
                                                            {/* <input type={form?.form_type} defaultValue={getValueForKey(currentForm[0], form?.form_value, form.form_id + i)}  */}
                                                            {form?.form_type === "select" ?
                                                                <select
                                                                    name={form?.form_id}
                                                                    className={`form-select ${errorDataArr?.includes(form?.form_id) && "input-error"}`}
                                                                    onChange={e => triggerKeyUpdate(currentForm[0], form?.endpoint, form?.form_type, form?.form_value, e?.target?.value, form?.form_id, form?.key, form?.regex, `${form?.form_label}`, "", (form?.newlyAdded || false), (currentForm[1]?.multiple || false), (form?.newlyUnique || ""), (form?.required || "False") )}
                                                                    autoComplete="off"
                                                                    // value={getValueForKey(currentForm[0], form?.form_value, form.form_id + i) || ""}
                                                                    value={getValueForKey(form?.key) || ""}
                                                                >
                                                                    <option value="">Select {form?.form_label}</option>
                                                                    {form?.select_value?.map((opt: any, k: number) => (
                                                                        <option key={k} value={opt?.value}>{opt?.name}</option>
                                                                    ))}
                                                                </select>
                                                                :
                                                                form?.form_type === "file" ?
                                                                    <div>
                                                                        {!getValueForKey(form?.key) &&
                                                                            <div className={`file-input-area p-2 ${errorDataArr?.includes(form?.form_id) && "input-error"}`}>
                                                                                {(activeUploadingKey === form?.key) && uploadFileState?.isLoading ? (
                                                                                    <div>
                                                                                        <Spinner
                                                                                            as="span"
                                                                                            animation="border"
                                                                                            size="sm"
                                                                                            role="status"
                                                                                            aria-hidden="true"
                                                                                        />
                                                                                        <span className="sr-only">Uploading...</span>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className="">
                                                                                        <input type={form?.form_type}
                                                                                            placeholder={form?.form_label}
                                                                                            name={form?.form_id}
                                                                                            onChange={e => {
                                                                                                setActiveUploadingKey(form?.key)
                                                                                                triggerKeyUpdate(currentForm[0], form?.endpoint, form?.form_type, form?.form_value, e, form?.form_id, form?.key, "", `${form?.form_label}`, "", (form?.newlyAdded || false), (currentForm[1]?.multiple || false), (form?.newlyUnique || ""), (form?.required || "False") )
                                                                                            }
                                                                                            }
                                                                                            autoComplete="off"
                                                                                            accept=".pdf, .png, .jpg, .jpeg"
                                                                                            value={getValueForKey(form?.key) || ""}
                                                                                            draggable={false}
                                                                                        />
                                                                                        <small>Maximum file size: 15MB</small>
                                                                                        <small>Supported file types: (.pdf, .png, .jpg, .jpeg).</small>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        }

                                                                        {getValueForKey(form?.key) &&
                                                                            <div style={{ backgroundColor: "#eaeef6" }} className='py-2 px-3'>
                                                                                <div className="row align-items-center">
                                                                                    <div className="col-md-8 d-flex align-items-center">
                                                                                        <i className="ri-image-2-line ri-xl me-2" />
                                                                                        <span>
                                                                                            <p className="p-0 m-0" style={{ fontSize: "14px" }}>{getValueForKey(form?.key)?.name}</p>
                                                                                            <p className="p-0 m-0" style={{ fontSize: "13px", color: "#8D8D8D" }}>
                                                                                                Size: {getValueForKey(form?.key).size / 1000} KB
                                                                                            </p>
                                                                                        </span>

                                                                                    </div>
                                                                                    <div className="col-md-4 d-flex justify-content-md-end">
                                                                                        <span>
                                                                                            <button className="btn btn-danger"
                                                                                                onClick={e => triggerKeyUpdate(currentForm[0], form?.endpoint, form?.form_type, form?.form_value, "", form?.form_id, form?.key, "", `${form?.form_label}`, "", (form?.newlyAdded || false), (currentForm[1]?.multiple || false), (form?.newlyUnique || ""), (form?.required || "False") )}
                                                                                                style={{ border: "None", borderRadius: "5px" }}
                                                                                            >
                                                                                                <span className="d-flex align-items-center">
                                                                                                    <i className="ri-delete-bin-3-line ri-xl me-2 " />
                                                                                                    Remove
                                                                                                </span>
                                                                                            </button>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                    :
                                                                    form?.form_type === "tel" ?
                                                                        // <div className="input-group mb-3">
                                                                        <div className="input-group">
                                                                            <div className="input-group-prepend">
                                                                                <select className={`form-select ${errorDataArr?.includes(form?.form_id) && "input-error"}`} value={getValueForPhoneCountry(form?.key) || ""}
                                                                                    style={{ borderRadius: "5px 0px 0px 5px", maxWidth: "150px" }}
                                                                                    onChange={e => triggerKeyUpdate(currentForm[0], form?.endpoint, form?.form_type, form?.form_value, e.target.value, form?.form_id, form?.key, form?.regex, `${form?.form_label}`, "phoneCountryCode", (form?.newlyAdded || false), (currentForm[1]?.multiple || false), (form?.newlyUnique || ""), (form?.required || "False") )}
                                                                                >
                                                                                    {/* {countryList?.sort((a: any, b: any) => a?.dialCode - b?.dialCode)?.map((country, i) => ( */}
                                                                                    {countryList?.sort((a: any, b: any) => a?.code - b?.code)?.map((country, i) => (
                                                                                        <option key={i} value={country?.code} className='add-ellipsis'>
                                                                                            {country?.name}
                                                                                            {/* <div className="d-flex space-between">
                                                                                                <span>{getCountryFlag(country?.code)} {country?.name}</span>
                                                                                                <span>{country?.dialCode}</span>
                                                                                            </div> */}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                            </div>
                                                                            <input type={form?.form_type}
                                                                                className={`form-control ${errorDataArr?.includes(form?.form_id) && "input-error"}`} placeholder={form?.form_label}
                                                                                name={form?.form_id}
                                                                                onChange={e => triggerKeyUpdate(currentForm[0], form?.endpoint, form?.form_type, form?.form_value, removeLetters(e.target.value), form?.form_id, form?.key, form?.regex, `${form?.form_label}`, "", (form?.newlyAdded || false), (currentForm[1]?.multiple || false), (form?.newlyUnique || ""), (form?.required || "False") )}
                                                                                autoComplete="off"
                                                                                // value={getValueForKey(currentForm[0], form?.form_value, form.form_id + i) || ""}
                                                                                value={getValueForKey(form?.key) || ""}
                                                                            />
                                                                        </div>
                                                                        :
                                                                        <>
                                                                            <input type={form?.form_type}
                                                                                className={`form-control p-2 ${errorDataArr?.includes(form?.form_id) && "input-error"}`} placeholder={form?.form_label}
                                                                                name={form?.form_id}
                                                                                onChange={e => triggerKeyUpdate(currentForm[0], form?.endpoint, form?.form_type, form?.form_value, e.target.value, form?.form_id, form?.key, form?.regex, `${form?.form_label}`, "", (form?.newlyAdded || false), (currentForm[1]?.multiple || false), (form?.newlyUnique || ""), (form?.required || "False") )}
                                                                                autoComplete="off"
                                                                                value={getValueForKey(form?.key) || ""}
                                                                            />
                                                                        </>
                                                            }

                                                            {(errorDataArr?.includes(form?.form_id)) &&
                                                                <small className='text-danger'>Missing field required</small>
                                                            }
                                                        </div>
                                                    </span>
                                                )
                                            )}

                                            {currentForm[1]?.multiple &&
                                                <>
                                                    {currentForm[1]?.forms?.some((obj: any) => obj.newlyAdded === true) &&
                                                        <button className='btn btn-deep-green-outline px-3 my-5 me-3'
                                                            onClick={() => {
                                                                removeForm(currentForm[0])
                                                            }}
                                                        > Remove Form </button>
                                                    }

                                                    <button className='btn btn-deep-green px-3 my-5'
                                                        onClick={() => addNewForm(currentForm[1]?.forms, currentForm[0], currentForm[1]?.forms[0]?.field_title)}
                                                    > Add Form </button>
                                                </>
                                            }

                                            <button className='btn btn-deep-green px-5 my-5 w-100'
                                                onClick={() => nextForm(currentForm[0])}
                                            >
                                                Next
                                            </button>

                                        </form>
                                    </div>
                                )}

                                <div className="col-md-4 mt-5">
                                    <div className="card mt-4 pb-3">
                                        <div className="card-body">
                                            {candidateFormState?.resp?.detail && Object?.keys(candidateFormState?.resp?.detail?.form)?.reverse()?.map((val: any, i: number) => (
                                                <div key={i} className='d-flex align-items-center mt-3'>
                                                    {currentStep > i ?
                                                        <i className="ri-checkbox-circle-fill ri-xl me-2" />
                                                        :
                                                        <i className="ri-checkbox-blank-circle-line ri-xl me-2" />
                                                    }
                                                    <p className='p-0 m-0' style={{ cursor: "pointer" }} onClick={(currentStep > i) ? () => setCurrentStep(i) : () => { }}>
                                                        {replaceUnderscores(val, "upperFirstAll")}
                                                        {/* {val[0]?.toUpperCase() + val?.replaceAll("_", " ")?.slice(1)} */}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </>
            }

            {/* {formPage === 1 &&
                <>
                    {!candidateFormState?.isLoading &&
                        <div className="container">
                            <div>
                                <small> step {currentStep + 1} of {allForm?.length}</small>
                                <h1> {currentForm[0]?.charAt(0)?.toUpperCase() + currentForm[0]?.substring(1).replaceAll("_", " ")}</h1>
                                <p>Please enter your {currentForm[0]?.replaceAll("_", " ")} details</p>
                            </div>
                            <div className='row justify-content-between '>
                                {allForm?.length > 0 && (
                                    <div className="col-md-5 mt-5">
                                        <div className=''>
                                            {currentForm && currentForm[1]?.map((form: any, i: number) => {
                                                return (
                                                    <div className="mt-1" key={i}>
                                                        <label htmlFor="firstname">{`${form?.form_label} ${form?.description ? ("(" + form?.description + ")") : ""}`}</label>
                                                        <input type={form?.form_type}
                                                            name={form?.form_value}
                                                            className='form-control' placeholder={form?.form_label}
                                                            onChange={e => triggerKeyUpdate(currentForm[0], form?.form_value, e?.target?.value, form?.form_id, i)}
                                                            autoComplete="off"
                                                        />
                                                        {form?.form_type === "select" ?
                                                            <select
                                                                name={form?.form_id}
                                                                className='form-select'
                                                                onChange={e => triggerKeyUpdate(currentForm[0], form?.endpoint, form?.form_type, form?.form_value, e?.target?.value, form?.form_id, form?.key,)}
                                                                autoComplete="off"
                                                                value={getValueForKey(currentForm[0], form?.form_value, form.form_id + i) || ""}
                                                            >
                                                                <option value="">Select {form?.form_label}</option>
                                                                {form?.select_value?.map((opt: any, i: number) => (
                                                                    <option value={opt?.value}>{opt?.name}</option>
                                                                ))}
                                                            </select>
                                                            :
                                                            <input  
                                                                type={form?.form_type}
                                                                name={form?.form_value}
                                                                className='form-control' placeholder={form?.form_label}
                                                                onChange={e => triggerKeyUpdate(currentForm[0], form?.form_value, e?.target?.value, form?.form_id, i)}
                                                                autoComplete="off"
                                                                value={getValueForKey(currentForm[0], form?.form_value, form.form_id + i) || ""}
                                                            />
                                                        }
                                                    </div>
                                                )
                                            }
                                            )}

                                            <button className='btn btn-deep-green mt-3 px-5 mt-5 w-100'
                                                onClick={() => nextForm(currentForm[0])}
                                            >
                                                Next
                                            </button>

                                        </div>
                                    </div>
                                )}

                                <div className="col-md-4 mt-5">
                                    <div className="card mt-4 pb-3">
                                        <div className="card-body">
                                            {candidateFormState?.resp?.detail && Object?.keys(candidateFormState?.resp?.detail?.form)?.reverse()?.map((val: any, i: number) => (
                                                <div key={i} className='d-flex align-items-center mt-3'>
                                                    {currentStep > i ?
                                                        <i className="ri-checkbox-circle-fill ri-xl me-2" />
                                                        :
                                                        <i className="ri-checkbox-blank-circle-line ri-xl me-2" />
                                                    }
                                                    <p className='p-0 m-0' style={{ cursor: "pointer" }} onClick={(currentStep > i) ? () => setCurrentStep(i) : () => { }}>
                                                        {val[0]?.toUpperCase() + val?.replaceAll("_", " ")?.slice(1)}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </>
            } */}

            {formPage === 2 &&
                <BackgroundCheckFaceVerificationComp proceedFromFaceVerification={proceedFromFaceVerification} goToFormPage={goToFormPage} />
            }

            {formPage === 3 &&
                <CheckSummaryComp submitForm={submitForm} goToFormPage={goToFormPage}
                    successModal={successModal} toggleSuccessModal={toggleSuccessModal}
                    faceEnabled={candidateFormState?.resp?.detail?.face_capture}
                    facialDataValues={facialDataValues} collectedData={payloadData}
                    candidatePaymentEnabled={candidateFormState?.resp?.detail?.allow_candidate_pay_for_a_check}
                />
            }

            {formPage === 5 && <CandidatePaymentComp submitForm={submitForm} goToFormPage={goToFormPage}/>}

            {formPage === 4 &&
                <div>
                    <div className="col-md-6 col-lg-4 mx-auto">
                        <div className="card">
                            <div className="card-body">
                                <div className="main-modal-body text-center">
                                    <div className="col-md-6 mx-auto mt-4">
                                        <Player
                                            src={successVerifGif}
                                            className="player"
                                            loop
                                            autoplay
                                        />
                                    </div>
                                    <div className="my-5">
                                        <h4>Thank You</h4>
                                        <p className='my-3'>
                                            The candidate's information has been received. You will be automatically redirected
                                            to the Request page in few seconds.
                                            If you are not redirected automatically, please click  

                                            <a href={`${global.appBaseUrl}BackgroundCheck/Requests`} className="link link-underline mx-1">here</a>
 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {formPage === 6 &&
                <div>
                    <div className="col-md-6 col-lg-4 mx-auto">
                        <div className="card">
                            <div className="card-body">
                                <div className="main-modal-body text-center">
                                    <div className="col-md-6 mx-auto mt-4">
                                        <Player
                                            src={cancelGif}
                                            className="player"
                                            loop
                                            autoplay
                                        />
                                    </div>
                                    <div className="my-5">
                                        <h4>Check Declined</h4>
                                        <p className='my-3'>
                                            You rejected this check. 
                                            Please close this tab or proceed to our website
                                            {window.location.host.includes("peleza") ?
                                                <a href={"https://peleza.com"} className='link link-underline mx-1'>
                                                    here
                                                </a>
                                                :
                                                <a href={"https://prembly.com/backgroundCheck"} className='link link-underline mx-1'>
                                                    here
                                                </a>
                                            }
                                            to know more about our solutions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}
