import React, { useEffect, useState } from 'react'
import NotificationToast from '../../../../../utils/notifToast'
import Mainloader, { removeLetters } from '../../../../../utils'
import { Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { backgroundCheckRequestCandidatePaymentDetailsRequest, backgroundCheckRequestCandidatePaymentMpessaInitRequest, backgroundCheckRequestCandidatePaymentMpessaVerifyRequest } from '../../../../../../redux/actions/products/backgroundCheck/request/checks'
import global from '../../../../../../redux/constants/global'
import { RootState } from '../../../../../../redux/reducers'
import { Player } from '@lottiefiles/react-lottie-player'
import infoGif from "../../../../../../assets/infoIcon.json"

export default function CandidatePaymentComp(props: any) {
    const [notifVal, setNotifVal] = useState(false)
    const [notif, setNotif] = useState("")
    const [notifTitle, setNotifTitle] = useState("")
    const [paymentPage, setPaymentPage] = useState("1")
    const [amount, setAmount] = useState(20000)
    const [paymentMethod, setPaymentMethod] = useState("card")
    // const [paymentOrg, setPaymentOrg] = useState("")
    // const [paymentPlatform, setPaymentPlatform] = useState("")
    // const [paymentPlatform, setPaymentPlatform] = useState("stripe")
    const [paymentPlatform, setPaymentPlatform] = useState("MPESSA")
    const [paymentCurrency, setPaymentCurrency] = useState("KES")
    const [paymentPhoneNumber, setPaymentPhoneNumber] = useState("")
    const [transactionRef, setTransactionRef] = useState("")


    const backgroundCheckCandidatePaymentDetailsState = useSelector((state: RootState) => state.backgroundCheckRequestCandidatePaymentDetailsReducer);
    const backgroundCheckCandidatePaymentMpessaInitState = useSelector((state: RootState) => state.backgroundCheckRequestCandidatePaymentMpessaInitReducer);
    const backgroundCheckCandidatePaymentMpessaVerifyState = useSelector((state: RootState) => state.backgroundCheckRequestCandidatePaymentMpessaVerifyReducer);
    const backgroundCheckRequestAnswerUploadState = useSelector((state: RootState) => state.backgroundCheckRequestAnswerUploadReducer);


    const queryParams = new URLSearchParams(window.location.search)
    let requestId = queryParams.get("ref") || ""

    const dispatch = useDispatch()

    useEffect(() => {
        getCandidatePrice()
    }, [])



    let getCandidatePrice = () => {
        const callback = (data: any) => {
            if (data.status) {
                setAmount(data?.detail?.amount)
                // setAmount(1)
                setPaymentCurrency(data?.detail?.currency?.toUpperCase())
            }
            else {
                setNotifTitle("Error")
                setNotif(data.detail)
                setNotifVal(true)
            }
        };
        let data: any = {
            values: {
                request_id: requestId,
            },
            callback,
        };
        dispatch(backgroundCheckRequestCandidatePaymentDetailsRequest(data))
    }



    let proceedToNext = () => {
        if (!amount) {
            setNotifTitle("Error")
            setNotif("Please enter an Amount")
            setNotifVal(true)
            return
        }
        else if (paymentCurrency === "NGN" && (amount < 20000)) {
            setNotifTitle("Error")
            setNotif("Amount cannot be less than NGN20000")
            setNotifVal(true)
            return
        }
        else if (paymentCurrency === "KES" && (amount < 1)) {
            setNotifTitle("Error")
            setNotif("Amount cannot be less than KES1")
            setNotifVal(true)
            return
        }
        else if (paymentCurrency === "USD" && (amount < 50)) {
            setNotifTitle("Error")
            setNotif("Amount cannot be less than USD50")
            setNotifVal(true)
            return
        }
        // else if((walletBalanceState?.resp?.detail?.currency === "NGN") && (amount < 10000)){
        //     setNotifTitle("Error")
        //     setNotif("Amount cannot be less than NGN10000")
        //     setNotifVal(true)
        //     return
        // }
        // if((paymentCurrency === "USD") && (walletBalanceState?.resp?.detail?.currency === "NGN") && (amount < 10)){
        //     setNotifTitle("Error")
        //     setNotif("Amount cannot be less than USD10")
        //     setNotifVal(true)
        //     return
        // }
        // if((paymentCurrency === "USD") && (walletBalanceState?.resp?.detail?.currency === "USD") && (amount < 200)){
        //     setNotifTitle("Error")
        //     setNotif("Amount cannot be less than USD200")
        //     setNotifVal(true)
        //     return
        // }
        else if (!paymentMethod) {
            setNotifTitle("Error")
            setNotif("Please select payment method")
            setNotifVal(true)
            return
        }
        else if (paymentMethod === "card" && !paymentPlatform) {
            setNotifTitle("Error")
            setNotif("Please select payment platform")
            setNotifVal(true)
            return
        }
        // else if (paymentMethod === "card" && paymentPlatform === "stripe") {
        //     fundWallet()
        //     return
        // }
        // else if (paymentMethod === "card" && paymentPlatform === "paystack") {
        //     paystackFundWallet()
        //     return
        // }
        // else if (paymentMethod === "card" && paymentPlatform === "flutterwave") {
        //     flutterwaveFundWallet()
        //     return
        // }
        else if (paymentMethod === "card" && paymentPlatform === "MPESSA" && !paymentPhoneNumber) {
            setNotifTitle("Error")
            setNotif("Input a valid phone number")
            setNotifVal(true)
            return
        }
        else if (paymentMethod === "card" && paymentPlatform === "MPESSA" && paymentPhoneNumber) {
            payWithMpessa()
            return
        }

    }


    // let fundWallet = () => {
    //     const callback = (data: any) => {
    //         if (data.status) {
    //             window.location.href = data?.detail?.url
    //         }
    //         else {
    //             setNotifTitle("Error")
    //             setNotif(data.detail)
    //             setNotifVal(true)
    //         }
    //     };
    //     let data: any = {
    //         values: {
    //             amount: amount.toString(),
    //             currency: paymentCurrency,
    //             success_url: global.appBaseUrl + "?success=true",
    //             cancel_url: global.appBaseUrl + "?failed=true"
    //         },
    //         callback,
    //     };
    //     dispatch(topUpWalletRequest(data))
    // }

    let payWithMpessa = () => {
        const callback = (data: any) => {
            if (data?.status) {
                setNotifTitle("Success")
                setNotif(data.detail)
                setNotifVal(true)
                setTransactionRef(data?.transaction_ref)
                setPaymentPage("2")
            }
            else {
                setNotifTitle("Error")
                setNotif(data.detail)
                setNotifVal(true)
            }
        };
        let data: any = {
            values: {
                request_id: requestId,
                amount: amount.toString(),
                senders_phone_number: paymentPhoneNumber
            },
            callback,
        };
        dispatch(backgroundCheckRequestCandidatePaymentMpessaInitRequest(data))
    }

    let confirmPayment = () => {
        const callback = (data: any) => {
            if (data?.status) {
                if(data?.detail?.status === "PENDING"){
                    setNotifTitle("Error")
                    setNotif("Payment is still pending.")
                    setNotifVal(true)
                }
                if(data?.detail?.status === "FAILED"){
                    setNotifTitle("Error")
                    setNotif("Please try again on your phone, payment failed.")
                    setNotifVal(true)
                }
                if(data?.detail?.status === "SUCCESSFUL"){
                    props?.submitForm()
                }
                
                // setPaymentPage("2")
            }
            else {
                setNotifTitle("Error")
                setNotif(data.detail)
                setNotifVal(true)
            }
        };
        let data: any = {
            values: {
                transaction_ref: transactionRef,
            },
            callback,
        };
        dispatch(backgroundCheckRequestCandidatePaymentMpessaVerifyRequest(data))
    }

    return (
        <div>
            {(notif && notifVal) && <NotificationToast title={notifTitle} message={notif} closeNotif={() => setNotifVal(!notifVal)} />}

            {backgroundCheckCandidatePaymentDetailsState?.isLoading && <Mainloader />}


            {!backgroundCheckCandidatePaymentDetailsState?.isLoading &&
                <div className="container">
                    <button className='btn btn-green mb-5' onClick={() => props?.goToFormPage(1)}>
                        Back to form
                    </button>


                    <div className="col-md-6 mx-auto">
                        {paymentPage === "1" &&
                            <div>
                                <h4>Candidate Payment</h4>
                                <p> 
                                    Kindly fill in your phone number to pay for this check. 
                                    You will receive a notification on your phone to pay using Mpesa
                                </p>
                                {/* <p>Kindly Make Payment for this check before proceeding</p> */}

                                <div className="">
                                    <label htmlFor="amount">Amount</label>
                                    <div className="input-group">
                                        <div className="border px-3" style={{ borderRadius: "5px 0px 0px 5px", paddingTop: "12px" }}>
                                            <p className='py-0 my-0'>{paymentCurrency}</p>
                                        </div>
                                        <input type="text" className="form-control" value={amount}
                                            // onChange={e => setAmount(removeLetters(e.target.value))}
                                            disabled
                                        />
                                        {/* <span className="">
                                            <select className="form-select" value={paymentCurrency}
                                                onChange={(e) => {
                                                    setPaymentCurrency(e.target.value);
                                                    ((e?.target?.value === "USD") && (paymentMethod === 'card')) && setPaymentPlatform('stripe');
                                                }}
                                                style={{ borderRadius: "0px 5px 5px 0px" }}
                                            >
                                                <option value="NGN">NGN</option>
                                                <option value="KSH">KSH</option>
                                                <option value="USD">USD</option>
                                            </select>
                                        </span> */}
                                    </div>
                                </div>


                                <div className="">
                                    <label htmlFor="paymentPlatform">Select Payment Platform</label>
                                    <select className="form-select " value={paymentPlatform}
                                        // onChange={e => { setPaymentPlatform(e.target.value) }}
                                        disabled
                                    >
                                        <option value="">Select Platform</option>
                                        {/* {organisationInfoState?.resp?.data?.organisation.currency === "NGN" ? */}
                                        {paymentCurrency === "NGN" ?
                                            <>
                                                <option value="stripe">Pay with Stripe</option>
                                                {/* <option value="paystack">Pay with Paystack</option>
                                                    <option value="flutterwave">Pay with Flutterwave</option> */}
                                            </>
                                            :
                                            (paymentCurrency === "KES") ?
                                                <>
                                                    {/* <option value="stripe">Pay with Stripe(USD)</option> */}
                                                    <option value="MPESSA">Pay with M-PESA</option>
                                                    {/* <option value="paystack">Pay with Paystack</option>
                                                                        <option value="flutterwave">Pay with Flutterwave</option> */}
                                                </>
                                                :
                                                <>
                                                    <option value="stripe">Pay with Stripe(USD)</option>
                                                </>
                                        }
                                    </select>
                                </div>

                                {(paymentMethod === 'card' && paymentPlatform === "MPESSA") &&
                                    <div className="">
                                        <label htmlFor="paymentPhoneNumber">Enter Phone Number</label>
                                        <input type="tel" className='form-control'  
                                            // value={paymentPhoneNumber?.charAt(0)?.replaceAll("0", "") + paymentPhoneNumber?.substring(1)} 
                                            value={paymentPhoneNumber} maxLength={12}
                                            placeholder="254700000000" onChange={e => setPaymentPhoneNumber(removeLetters(e?.target?.value?.charAt(0)?.replaceAll("0", "") + e?.target?.value?.substring(1)) )}
                                        />
                                    </div>
                                }

                                <button className="btn btn-deep-green py-2 mt-4"
                                    onClick={proceedToNext}
                                >
                                    {(backgroundCheckCandidatePaymentMpessaInitState.isLoading)
                                        ?
                                        <div>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :
                                        "Proceed"
                                    }
                                </button>
                            </div>
                        }

                        {paymentPage === "2" &&
                            <div className="card">
                                <div className="card-body">
                                    <div className='text-center' >
                                        <div>
                                            <div className="col-md-6 mx-auto">
                                                <Player
                                                    src={infoGif}
                                                    className="player"
                                                    loop
                                                    autoplay
                                                />
                                            </div>
                                            <h5>Payment Initiated</h5>
                                            <small>
                                                You have successfully initiated your payment for this check,
                                                kindly finish the payment on your mobile phone. 
                                            </small>
                                            <small>
                                                Proceed from this page after you have completed your payment
                                            </small>
                                        </div>

                                        <button className="btn btn-deep-green py-2 mt-4 px-5"
                                            onClick={confirmPayment}
                                        >
                                            {(backgroundCheckCandidatePaymentMpessaVerifyState.isLoading ||
                                                backgroundCheckRequestAnswerUploadState?.isLoading
                                            )
                                                ?
                                                <div>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                :
                                                "Proceed"
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            }

        </div>
    )
}
