export const countryList = [
  {
    dialCode: "+93",
    code: "AF",
    name: "Afghanistan",
  },
  {
    dialCode: "+355",
    code: "AL",
    name: "Albania",
  },
  {
    dialCode: "+213",
    code: "DZ",
    name: "Algeria"
    
  },
  {
    dialCode: "+1 684",
    code: "AS",
    name: "American Samoa"
  },
  {
    dialCode: "+376",
    code: "AD",
    name: "Andorra",
  },
  {
    dialCode: "+244",
    code: "AO",
    "name": "Angola",
  },
  {
    dialCode: "+1 264",
    code: "AI",
    "name": "Anguilla",
  },
  {
    dialCode: "+672",
    code: "AQ",
    "name": "Antarctica",
  },
  {
    dialCode: "+1268",
    code: "AG",
    "name": "Antigua and Barbuda",
  },
  {
    dialCode: "+54",
    code: "AR",
    "name": "Argentina",
  },
  {
    dialCode: "+374",
    code: "AM",
    "name": "Armenia",
  },
  {
    dialCode: "+297",
    code: "AW",
    "name": "Aruba",
  },
  {
    dialCode: "+61",
    code: "AU",
    "name": "Australia",
  },
  {
    dialCode: "+43",
    code: "AT",
    "name": "Austria",
  },
  {
    dialCode: "+994",
    code: "AZ",
    "name": "Azerbaijan",
  },
  {
    dialCode: "+1 242",
    code: "BS",
    "name": "Bahamas",
  },
  {
    dialCode: "+973",
    code: "BH",
    "name": "Bahrain",
  },
  {
    dialCode: "+880",
    code: "BD",
    "name": "Bangladesh",
  },
  {
    dialCode: "+1 246",
    code: "BB",
    "name": "Barbados",
  },
  {
    dialCode: "+375",
    code: "BY",
    "name": "Belarus",
  },
  {
    dialCode: "+32",
    code: "BE",
    "name": "Belgium",
  },
  {
    dialCode: "+501",
    code: "BZ",
    "name": "Belize",
  },
  {
    dialCode: "+229",
    code: "BJ",
    "name": "Benin",
  },
  {
    dialCode: "+1 441",
    code: "BM", 
    "name": "Bermuda",
  },
  {
    dialCode: "+975",
    code: "BT",
    "name": "Bhutan",
  },
  {
    dialCode: "+591",
    code: "BO",
    "name": "Bolivia (Plurinational State of)",
  },
  {
    dialCode: "+387",
    code: "BA",
    "name": "Bosnia and Herzegovina",
  },
  {
    dialCode: "+267",
    code: "BW",
    "name": "Botswana",
  },
  {
    dialCode: "+55",
    code: "BR",
    "name": "Brazil",
  },
  {
    dialCode: "+246",
    code: "IO",
    "name": "British Indian Ocean Territory",
  },
  {
    dialCode: "+673",
    code: "BN",
    "name": "Brunei Darussalam",
  },
  {
    dialCode: "+359",
    code: "BG",
    "name": "Bulgaria",
  },
  {
    dialCode: "+226",
    code: "BF",
    "name": "Burkina Faso",
  },
  {
    dialCode: "+257",
    code: "BI",
    "name": "Burundi",
  },
  {
    dialCode: "+855",
    code: "KH",
    "name": "Cambodia",
  },
  {
    dialCode: "+237",
    code: "CM",
    "name": "Cameroon",
  },
  {
    dialCode: "+1",
    code: "CA",
    "name": "Canada",
  },
  {
    dialCode: "+238",
    code: "CV",
    "name": "Cabo Verde",
  },
  {
    dialCode: "+ 345",
    code: "KY", 
    "name": "Cayman Islands",
  },
  {
    dialCode: "+236",
    code: "CF",
    "name": "Central African Republic",
  },
  {
    dialCode: "+235",
    code: "TD",
    "name": "Chad",
  },
  {
    dialCode: "+56",
    code: "CL",
    "name": "Chile",
  },
  {
    dialCode: "+86",
    code: "CN",
    "name": "China",
  },
  {
    dialCode: "+61",
    code: "CX",
    "name": "Christmas Island",
  },
  {
    dialCode: "+61",
    code: "CC",
    "name": "Cocos (Keeling) Islands",
  },
  {
    dialCode: "+57",
    code: "CO",
    "name": "Colombia",
  },
  {
    dialCode: "+269",
    code: "KM",
    "name": "Comoros",
  },
  {
    dialCode: "+242",
    code: "CG",
    "name": "Congo",
  },
  {
    dialCode: "+243",
    code: "CD",
    "name": "Congo, Democratic Republic of the",
  },
  {
    dialCode: "+682",
    code: "CK",
    "name": "Cook Islands",
  },
  {
    dialCode: "+506",
    code: "CR",
    "name": "Costa Rica",
  },
  {
    dialCode: "+225",
    code: "CI",
    "name": "Côte d'Ivoire",
  },
  {
    dialCode: "+385",
    code: "HR",
    "name": "Croatia",
  },
  {
    dialCode: "+53",
    code: "CU",
    "name": "Cuba",
  },
  {
    dialCode: "+599",
    code: "CW",
    "name": "Curaçao"
  },
  {
    dialCode: "+357",
    code: "CY",
    "name": "Cyprus",
  },
  {
    dialCode: "+420",
    code: "CZ",
    "name": "Czechia",
  },
  {
    dialCode: "+45",
    code: "DK",
    "name": "Denmark",
  },
  {
    dialCode: "+253",
    code: "DJ",
    "name": "Djibouti",
  },
  {
    dialCode: "+1 767",
    code: "DM",
    "name": "Dominica",
  },
  {
    dialCode: "+1 849",
    code: "DO",
    "name": "Dominican Republic",
  },
  {
    dialCode: "+593",
    code: "EC",
    "name": "Ecuador"
  },
  {
    dialCode: "+20",
    code: "EG",
    "name": "Egypt",
  },
  {
    dialCode: "+503",
    code: "SV",
    "name": "El Salvador",
  },
  {
    dialCode: "+240",
    code: "GQ",
    "name": "Equatorial Guinea",
  },
  {
    dialCode: "+291",
    code: "ER",
    "name": "Eritrea",
  },
  {
    dialCode: "+372",
    code: "EE",
    "name": "Estonia",
  },
  {
    dialCode: "+268",
    code: "SZ",
    "name": "Eswatini",
  },
  {
    dialCode: "+251",
    code: "ET",
    "name": "Ethiopia",
  },
  {
    dialCode: "+500",
    code: "FK",
    "name": "Falkland Islands (Malvinas)",
  },
  {
    dialCode: "+298",
    code: "FO",
    "name": "Faroe Islands",
  },
  {
    dialCode: "+679",
    code: "FJ",
    "name": "Fiji",
  },
  {
    dialCode: "+358",
    code: "FI",
    "name": "Finland",
  },
  {
    dialCode: "+33",
    code: "FR",
    "name": "France",
  },
  {
    dialCode: "+594",
    code: "GF",
    "name": "French Guiana",
  },
  {
    dialCode: "+689",
    code: "PF",
    "name": "French Polynesia",
  },
  {
    dialCode: "+241",
    code: "GA",
    "name": "Gabon",
  },
  {
    dialCode: "+220",
    code: "GM",
    "name": "Gambia",
  },
  {
    dialCode: "+995",
    code: "GE",
    "name": "Georgia",
  },
  {
    dialCode: "+49",
    code: "DE",
    "name": "Germany",
  },
  {
    dialCode: "+233",
    code: "GH",
    "name": "Ghana",
  },
  {
    dialCode: "+350",
    code: "GI",
    "name": "Gibraltar",
  },
  {
    dialCode: "+30",
    code: "GR",
    "name": "Greece",
  },
  {
    dialCode: "+299",
    code: "GL",
    "name": "Greenland",
  },
  {
    dialCode: "+1 473",
    code: "GD",
    "name": "Grenada",
  },
  {
    dialCode: "+590",
    code: "GP",
    "name": "Guadeloupe",
  },
  {
    dialCode: "+1 671",
    code: "GU",
    "name": "Guam",
  },
  {
    dialCode: "+502",
    code: "GT",
    "name": "Guatemala"
  },
  {
    dialCode: "+44",
    code: "GG",
    "name": "Guernsey",
  },
  {
    dialCode: "+224",
    code: "GN",
    "name": "Guinea",
  },
  {
    dialCode: "+245",
    code: "GW",
    "name": "Guinea-Bissau",
  },
  {
    dialCode: "+595",
    code: "GY",
    "name": "Guyana",
  },
  {
    dialCode: "+509",
    code: "HT",
    "name": "Haiti",
  },
  {
    dialCode: "+379",
    code: "VA",
    "name": "Holy See",
  },
  {
    dialCode: "+504",
    code: "HN",
    "name": "Honduras"
  },
  {
    dialCode: "+852",
    code: "HK",
    "name": "Hong Kong",
  },
  {
    dialCode: "+36",
    code: "HU",
    "name": "Hungary",
  },
  {
    dialCode: "+354",
    code: "IS",
    "name": "Iceland",
  },
  {
    dialCode: "+91",
    code: "IN",
    "name": "India",
  },
  {
    dialCode: "+62",
    code: "ID",
    "name": "Indonesia",
  },
  {
    dialCode: "+98",
    code: "IR",
    "name": "Iran (Islamic Republic of)",
  },
  {
    dialCode: "+964",
    code: "IQ",
    "name": "Iraq",
  },
  {
    dialCode: "+353",
    code: "IE",
    "name": "Ireland",
  },
  {
    dialCode: "+44",
    code: "IM",
    "name": "Isle of Man",
  },
  {
    dialCode: "+972",
    code: "IL",
    "name": "Israel", 
  },
  {
    dialCode: "+39",
    code: "IT",
    "name": "Italy",
  },
  {
    dialCode: "+1 876",
    code: "JM",
    "name": "Jamaica",
  },
  {
    dialCode: "+81",
    code: "JP",
    "name": "Japan",
  },
  {
    dialCode: "+44",
    code: "JE",
    "name": "Jersey",
  },
  {
    dialCode: "+962",
    code: "JO",
    "name": "Jordan",
  },
  {
    dialCode: "+7 7",
    code: "KZ",
    "name": "Kazakhstan",
  },
  {
    dialCode: "+254",
    code: "KE",
    "name": "Kenya",
  },
  {
    dialCode: "+686",
    code: "KI",
    "name": "Kiribati",
  },
  {
    dialCode: "+850",
    code: "KP",
    "name":"Korea (Democratic People's Republic of)",
  },
  {
    dialCode: "+82",
    code: "KR",
    name:"Korea, Republic of",
  },
  {
    dialCode: "+965",
    code: "KW",
    "name": "Kuwait"
  },
  {
    dialCode: "+996",
    code: "KG",
    "name": "Kyrgyzstan",
  },
  {
    dialCode: "+856",
    code: "LA",
    "name": "Lao People's Democratic Republic",
  },
  {
    dialCode: "+371",
    code: "LV",
    "name": "Latvia",
  },
  {
    dialCode: "+961",
    code: "LB",
    "name": "Lebanon",
  },
  {
    dialCode: "+266",
    code: "LS",
    "name": "Lesotho",
  },
  {
    dialCode: "+231",
    code: "LR",
    "name": "Liberia",
  },
  {
    dialCode: "+218",
    code: "LY",
    "name": "Libya",
  },
  {
    dialCode: "+423",
    code: "LI",
    "name": "Liechtenstein",
  },
  {
    dialCode: "+370",
    code: "LT",
    "name": "Lithuania",
  },
  {
    dialCode: "+352",
    code: "LU",
    "name": "Luxembourg",
  },
  {
    dialCode: "+853",
    code: "MO",
    "name": "Macao",
  },
  {
    dialCode: "+261",
    code: "MG",
    "name": "Madagascar",
  },
  {
    dialCode: "+265",
    code: "MW",
    "name": "Malawi",
  },
  {
    dialCode: "+60",
    code: "MY",
    "name": "Malaysia",
  },
  {
    dialCode: "+960",
    code: "MV",
    "name": "Maldives",
  },
  {
    dialCode: "+223",
    code: "ML",
    "name": "Mali",
  },
  {
    dialCode: "+356",
    code: "MT",
    "name": "Malta",
  },
  {
    dialCode: "+692",
    code: "MH",
    "name": "Marshall Islands",
  },
  {
    dialCode: "+596",
    code: "MQ",
    "name": "Martinique",
  },
  {
    dialCode: "+222",
    code: "MR",
    "name": "Mauritania",
  },
  {
    dialCode: "+230",
    code: "MU",
    "name": "Mauritius",
  },
  {
    dialCode: "+262",
    code: "YT",
    "name": "Mayotte",
  },
  {
    dialCode: "+52",
    code: "MX",
    "name": "Mexico",
  },
  {
    dialCode: "+691",
    code: "FM",
    "name": "Micronesia (Federated States of)",
  },
  {
    dialCode: "+373",
    code: "MD",
    "name": "Moldova, Republic of",
  },
  {
    dialCode: "+377",
    code: "MC",
    "name": "Monaco",
  },
  {
    dialCode: "+976",
    code: "MN",
    "name": "Mongolia",
  },
  {
    dialCode: "+382",
    code: "ME",
    "name": "Montenegro",
  },
  {
    dialCode: "+1664",
    code: "MS",
    "name": "Montserrat",
  },
  {
    dialCode: "+212",
    code: "MA",
    "name": "Morocco",
  },
  {
    dialCode: "+258",
    code: "MZ",
    "name": "Mozambique",
  },
  {
    dialCode: "+95",
    code: "MM",
    "name": "Myanmar",
  },
  {
    dialCode: "+264",
    code: "NA",
    "name": "Namibia",
  },
  {
    dialCode: "+674",
    code: "NR",
    "name": "Nauru",
  },
  {
    dialCode: "+977",
    code: "NP",
    "name": "Nepal",
  },
  {
    dialCode: "+31",
    code: "NL",
    "name": "Netherlands",
  },
  {
    dialCode: "+687",
    code: "NC",
    "name": "New Caledonia",
  },
  {
    dialCode: "+64",
    code: "NZ",
    "name": "New Zealand",
  },
  {
    dialCode: "+505",
    code: "NI",
    "name": "Nicaragua",
  },
  {
    dialCode: "+227",
    code: "NE",
    "name": "Niger",
  },
  {
    dialCode: "+234",
    code: "NG",
    "name": "Nigeria",
  },
  {
    dialCode: "+683",
    code: "NU",
    "name": "Niue",
  },
  {
    dialCode: "+672",
    code: "NF",
    "name": "Norfolk Island",
  },
  {
    dialCode: "+389",
    code: "MK",
    "name": "North Macedonia",
  },
  {
    dialCode: "+1 670",
    code: "MP",
    "name": "Northern Mariana Islands",
  },
  {
    dialCode: "+47",
    code: "NO",
    "name": "Norway",
  },
  {
    dialCode: "+968",
    code: "OM",
    "name": "Oman",
  },
  {
    dialCode: "+92",
    code: "PK",
    "name": "Pakistan",
  },
  {
    dialCode: "+680",
    code: "PW",
    "name": "Palau",
  },
  {
    dialCode: "+970",
    code: "PS",
    "name": "Palestine, State of",
  },
  {
    dialCode: "+507",
    code: "PA",
    "name": "Panama",
  },
  {
    dialCode: "+675",
    code: "PG", 
    "name": "Papua New Guinea",
  },
  {
    dialCode: "+595",
    code: "PY",
    "name": "Paraguay",
  },
  {
    dialCode: "+51",
    code: "PE",
    "name": "Peru",
  },
  {
    dialCode: "+63",
    code: "PH",
    "name": "Philippines",
  },
  {
    dialCode: "+872",
    code: "PN",
    "name": "Pitcairn",
  },
  {
    dialCode: "+48",
    code: "PL",
    "name": "Poland",
  },
  {
    dialCode: "+351",
    code: "PT",
    "name": "Portugal",
  },
  {
    dialCode: "+1 939",
    code: "PR",
    "name": "Puerto Rico",
  },
  {
    dialCode: "+974",
    code: "QA",
    "name": "Qatar",
  },
  {
    dialCode: "+262",
    code: "RE",
    "name": "Réunion",
  },
  {
    dialCode: "+40",
    code: "RO",
    "name": "Romania",
  },
  {
    dialCode: "+7",
    code: "RU",
    "name": "Russian Federation"
  },
  {
    dialCode: "+250",
    code: "RW",
    "name": "Rwanda",
  },
  {
    dialCode: "+590",
    code: "BL",
    "name": "Saint Barthélemy",
  },
  {
    dialCode: "+290",
    code: "SH",
    "name": "Saint Helena, Ascension and Tristan da Cunha",
  },
  {
    dialCode: "+1 869",
    code: "KN",
    "name": "Saint Kitts and Nevis",
  },
  {
    dialCode: "+1 758",
    code: "LC",
    "name": "Saint Lucia",
  },
  {
    dialCode: "+590",
    code: "MF",
    "name": "Saint Martin (French part)",
  },
  {
    dialCode: "+508",
    code: "PM",
    "name": "Saint Pierre and Miquelon",
  },
  {
    dialCode: "+1 784",
    code: "VC",
    "name": "Saint Vincent and the Grenadines"
  },
  {
    dialCode: "+685",
    code: "WS",
    "name": "Samoa",
  },
  {
    dialCode: "+378",
    code: "SM",
    "name": "San Marino"
  },
  {
    dialCode: "+239",
    code: "ST",
    "name": "Sao Tome and Principe",
  },
  {
    dialCode: "+966",
    code: "SA",
    "name": "Saudi Arabia",
  },
  {
    dialCode: "+221",
    code: "SN",
    "name": "Senegal",
  },
  {
    dialCode: "+381",
    code: "RS",
    "name": "Serbia",
  },
  {
    dialCode: "+248",
    code: "SC",
    "name": "Seychelles",
  },
  {
    dialCode: "+232",
    code: "SL",
    "name": "Sierra Leone",
  },
  {
    dialCode: "+65",
    code: "SG",
    "name": "Singapore",
  },
  {
    dialCode: "+421",
    code: "SK",
    "name": "Slovakia",
  },
  {
    dialCode: "+386",
    code: "SI",
    "name": "Slovenia",
  },
  {
    dialCode: "+677",
    code: "SB",
    "name": "Solomon Islands",
  },
  {
    dialCode: "+252",
    code: "SO",
    "name": "Somalia",
  },
  {
    dialCode: "+27",
    code: "ZA",
    "name": "South Africa",
  },
  {
    dialCode: "+500",
    code: "GS",
    "name": "South Georgia and the South Sandwich Islands",
  },
  {
    dialCode: "+34",
    code: "ES",
    "name": "Spain",
  },
  {
    dialCode: "+94",
    code: "LK",
    "name": "Sri Lanka",
  },
  {
    dialCode: "+249",
    code: "SD",
    "name": "Sudan",
  },
  {
    dialCode: "+597",
    code: "SR",
    "name": "Suriname",
  },
  {
    dialCode: "+47",
    code: "SJ",
    "name": "Svalbard and Jan Mayen",
  },
  {
    dialCode: "+46",
    code: "SE",
    "name": "Sweden",
  },
  {
    dialCode: "+41",
    code: "CH",
    "name": "Switzerland",
  },
  {
    dialCode: "+963",
    code: "SY",
    "name": "Syrian Arab Republic"
  },
  {
    dialCode: "+886",
    code: "TW",
    "name": "Taiwan, Province of China",
  },
  {
    dialCode: "+992",
    code: "TJ",
    "name": "Tajikistan",
  },
  {
    dialCode: "+255",
    code: "TZ",
    "name": "Tanzania, United Republic",
  },
  {
    dialCode: "+66",
    code: "TH",
    "name": "Thailand",
  },
  {
    dialCode: "+670",
    code: "TL",
    "name": "Timor-Leste",
  },
  {
    dialCode: "+228",
    code: "TG",
    "name": "Togo",
  },
  {
    dialCode: "+690",
    code: "TK",
    "name": "Tokelau",
  },
  {
    dialCode: "+676",
    code: "TO",
    "name": "Tonga",
  },
  {
    dialCode: "+1 868",
    code: "TT",
    "name": "Trinidad and Tobago",
  },
  {
    dialCode: "+216",
    code: "TN",
    "name": "Tunisia",
  },
  {
    dialCode: "+90",
    code: "TR",
    "name": "Turkey",
  },
  {
    dialCode: "+993",
    code: "TM",
    "name": "Turkmenistan",
  },
  {
    dialCode: "+1 649",
    code: "TC",
    "name": "Turks and Caicos Islands",
  },
  {
    dialCode: "+688",
    code: "TV",
    "name": "Tuvalu",
  },
  {
    dialCode: "+256",
    code: "UG",
    "name": "Uganda",
  },
  {
    dialCode: "+380",
    code: "UA",
    "name": "Ukraine",
  },
  {
    dialCode: "+971",
    code: "AE",
    "name": "United Arab Emirates",
  },
  {
    dialCode: "+44",
    code: "GB",
    name: "United Kingdom of Great Britain and Northern Ireland",
  },
  {
    dialCode: "+1",
    code: "US",
    "name": "United States of America",
  },
  {
    dialCode: "+598",
    code: "UY",
    "name": "Uruguay",
  },
  {
    dialCode: "+998",
    code: "UZ",
    "name": "Uzbekistan",
  },
  {
    dialCode: "+678",
    code: "VU",
    "name": "Vanuatu",
  },
  {
    dialCode: "+58",
    code: "VE",
    "name": "Venezuela (Bolivarian Republic of)"
  },
  {
    dialCode: "+84",
    code: "VN",
    "name": "Viet Nam"
  },
  {
    dialCode: "+1 284",
    code: "VG",
    name: "Virgin Islands (British)",
  },
  {
    dialCode: "+1 340",
    code: "VI",
    name: "Virgin Islands (U.S.)",
  },
  {
    dialCode: "+681",
    code: "WF",
    name: "Wallis and Futuna"
  },
  {
    dialCode: "+967",
    code: "YE",
    name: "Yemen",
  },
  {
    dialCode: "+260",
    code: "ZM",
    name: "Zambia",
  },
  {
    dialCode: "+263",
    code: "ZW",
    name: "Zimbabwe",
  },
  {
    dialCode: "+358",
    code: "AX",
    name: "Åland Islands",
  }
];




export let bgcCountries = [
  { "name": "Afghanistan", "value": "AF" },
  { "name": "Åland Islands", "value": "AX" },
  { "name": "Albania", "value": "AL" },
  { "name": "Algeria", "value": "DZ" },
  { "name": "American Samoa", "value": "AS" },
  { "name": "Andorra", "value": "AD" },
  { "name": "Angola", "value": "AO" },
  { "name": "Anguilla", "value": "AI" },
  { "name": "Antarctica", "value": "AQ" },
  { "name": "Antigua and Barbuda", "value": "AG" },
  { "name": "Argentina", "value": "AR" },
  { "name": "Armenia", "value": "AM" },
  { "name": "Aruba", "value": "AW" },
  { "name": "Australia", "value": "AU" },
  { "name": "Austria", "value": "AT" },
  { "name": "Azerbaijan", "value": "AZ" },
  { "name": "Bahamas", "value": "BS" },
  { "name": "Bahrain", "value": "BH" },
  { "name": "Bangladesh", "value": "BD" },
  { "name": "Barbados", "value": "BB" },
  { "name": "Belarus", "value": "BY" },
  { "name": "Belgium", "value": "BE" },
  { "name": "Belize", "value": "BZ" },
  { "name": "Benin", "value": "BJ" },
  { "name": "Bermuda", "value": "BM" },
  { "name": "Bhutan", "value": "BT" },
  { "name": "Bolivia (Plurinational State of)", "value": "BO" },
  { "name": "Bonaire, Sint Eustatius and Saba", "value": "BQ" },
  { "name": "Bosnia and Herzegovina", "value": "BA" },
  { "name": "Botswana", "value": "BW" },
  { "name": "Bouvet Island", "value": "BV" },
  { "name": "Brazil", "value": "BR" },
  { "name": "British Indian Ocean Territory", "value": "IO" },
  { "name": "Brunei Darussalam", "value": "BN" },
  { "name": "Bulgaria", "value": "BG" },
  { "name": "Burkina Faso", "value": "BF" },
  { "name": "Burundi", "value": "BI" },
  { "name": "Cabo Verde", "value": "CV" },
  { "name": "Cambodia", "value": "KH" },
  { "name": "Cameroon", "value": "CM" },
  { "name": "Canada", "value": "CA" },
  { "name": "Cayman Islands", "value": "KY" },
  { "name": "Central African Republic", "value": "CF" },
  { "name": "Chad", "value": "TD" },
  { "name": "Chile", "value": "CL" },
  { "name": "China", "value": "CN" },
  { "name": "Christmas Island", "value": "CX" },
  { "name": "Cocos (Keeling) Islands", "value": "CC" },
  { "name": "Colombia", "value": "CO" },
  { "name": "Comoros", "value": "KM" },
  { "name": "Congo", "value": "CG" },
  { "name": "Congo, Democratic Republic of the", "value": "CD" },
  {
    "name": "Cook Islands",
    "value": "CK",
  },
  { "name": "Costa Rica", "value": "CR" },
  {
    "name": "Côte d'Ivoire",
    "value": "CI",
  },
  { "name": "Croatia", "value": "HR" },
  { "name": "Cuba", "value": "CU" },
  { "name": "Curaçao", "value": "CW" },
  { "name": "Cyprus", "value": "CY" },
  { "name": "Czechia", "value": "CZ" },
  { "name": "Denmark", "value": "DK" },
  { "name": "Djibouti", "value": "DJ" },
  { "name": "Dominica", "value": "DM" },
  { "name": "Dominican Republic", "value": "DO" },
  { "name": "Ecuador", "value": "EC" },
  { "name": "Egypt", "value": "EG" },
  { "name": "El Salvador", "value": "SV" },
  { "name": "Equatorial Guinea", "value": "GQ" },
  { "name": "Eritrea", "value": "ER" },
  { "name": "Estonia", "value": "EE" },
  { "name": "Eswatini", "value": "SZ" },
  { "name": "Ethiopia", "value": "ET" },
  { "name": "Falkland Islands (Malvinas)", "value": "FK" },
  { "name": "Faroe Islands", "value": "FO" },
  { "name": "Fiji", "value": "FJ" },
  { "name": "Finland", "value": "FI" },
  { "name": "France", "value": "FR" },
  { "name": "French Guiana", "value": "GF" },
  { "name": "French Polynesia", "value": "PF" },
  { "name": "French Southern Territories", "value": "TF" },
  { "name": "Gabon", "value": "GA" },
  { "name": "Gambia", "value": "GM" },
  { "name": "Georgia", "value": "GE" },
  { "name": "Germany", "value": "DE" },
  { "name": "Ghana", "value": "GH" },
  { "name": "Gibraltar", "value": "GI" },
  { "name": "Greece", "value": "GR" },
  { "name": "Greenland", "value": "GL" },
  { "name": "Grenada", "value": "GD" },
  { "name": "Guadeloupe", "value": "GP" },
  { "name": "Guam", "value": "GU" },
  { "name": "Guatemala", "value": "GT" },
  { "name": "Guernsey", "value": "GG" },
  { "name": "Guinea", "value": "GN" },
  { "name": "Guinea-Bissau", "value": "GW" },
  { "name": "Guyana", "value": "GY" },
  { "name": "Haiti", "value": "HT" },
  { "name": "Heard Island and McDonald Islands", "value": "HM" },
  { "name": "Holy See", "value": "VA" },
  { "name": "Honduras", "value": "HN" },
  { "name": "Hong Kong", "value": "HK" },
  { "name": "Hungary", "value": "HU" },
  { "name": "Iceland", "value": "IS" },
  { "name": "India", "value": "IN" },
  { "name": "Indonesia", "value": "ID" },
  { "name": "Iran (Islamic Republic of)", "value": "IR" },
  { "name": "Iraq", "value": "IQ" },
  { "name": "Ireland", "value": "IE" },
  { "name": "Isle of Man", "value": "IM" },
  { "name": "Israel", "value": "IL" },
  { "name": "Italy", "value": "IT" },
  { "name": "Jamaica", "value": "JM" },
  { "name": "Japan", "value": "JP" },
  { "name": "Jersey", "value": "JE" },
  { "name": "Jordan", "value": "JO" },
  { "name": "Kazakhstan", "value": "KZ" },
  { "name": "Kenya", "value": "KE" },
  { "name": "Kiribati", "value": "KI" },
  { "name": "Korea (Democratic People's Republic of)", "value": "KP" },
  { "name": "Korea, Republic of", "value": "KR" },
  { "name": "Kuwait", "value": "KW" },
  { "name": "Kyrgyzstan", "value": "KG" },
  { "name": "Lao People's Democratic Republic", "value": "LA" },
  { "name": "Latvia", "value": "LV" },
  { "name": "Lebanon", "value": "LB" },
  { "name": "Lesotho", "value": "LS" },
  { "name": "Liberia", "value": "LR" },
  { "name": "Libya", "value": "LY" },
  { "name": "Liechtenstein", "value": "LI" },
  { "name": "Lithuania", "value": "LT" },
  { "name": "Luxembourg", "value": "LU" },
  { "name": "Macao", "value": "MO" },
  { "name": "Madagascar", "value": "MG" },
  { "name": "Malawi", "value": "MW" },
  { "name": "Malaysia", "value": "MY" },
  { "name": "Maldives", "value": "MV" },
  { "name": "Mali", "value": "ML" },
  { "name": "Malta", "value": "MT" },
  { "name": "Marshall Islands", "value": "MH" },
  { "name": "Martinique", "value": "MQ" },
  { "name": "Mauritania", "value": "MR" },
  { "name": "Mauritius", "value": "MU" },
  { "name": "Mayotte", "value": "YT" },
  { "name": "Mexico", "value": "MX" },
  { "name": "Micronesia (Federated States of)", "value": "FM" },
  { "name": "Moldova, Republic of", "value": "MD" },
  { "name": "Monaco", "value": "MC" },
  { "name": "Mongolia", "value": "MN" },
  { "name": "Montenegro", "value": "ME" },
  { "name": "Montserrat", "value": "MS" },
  { "name": "Morocco", "value": "MA" },
  { "name": "Mozambique", "value": "MZ" },
  { "name": "Myanmar", "value": "MM" },
  { "name": "Namibia", "value": "NA" },
  { "name": "Nauru", "value": "NR" },
  { "name": "Nepal", "value": "NP" },
  { "name": "Netherlands", "value": "NL" },
  { "name": "New Caledonia", "value": "NC" },
  { "name": "New Zealand", "value": "NZ" },
  {
    "name": "Nicaragua",
    "value": "NI",
  },
  { "name": "Niger", "value": "NE" },
  { "name": "Nigeria", "value": "NG" },
  { "name": "Niue", "value": "NU" },
  { "name": "Norfolk Island", "value": "NF" },
  { "name": "North Macedonia", "value": "MK" },
  { "name": "Northern Mariana Islands", "value": "MP" },
  { "name": "Norway", "value": "NO" },
  { "name": "Oman", "value": "OM" },
  { "name": "Pakistan", "value": "PK" },
  { "name": "Palau", "value": "PW" },
  { "name": "Palestine, State of", "value": "PS" },
  { "name": "Panama", "value": "PA" },
  { "name": "Papua New Guinea", "value": "PG" },
  { "name": "Paraguay", "value": "PY" },
  { "name": "Peru", "value": "PE" },
  { "name": "Philippines", "value": "PH" },
  { "name": "Pitcairn", "value": "PN" },
  { "name": "Poland", "value": "PL" },
  { "name": "Portugal", "value": "PT" },
  { "name": "Puerto Rico", "value": "PR" },
  { "name": "Qatar", "value": "QA" },
  { "name": "Réunion", "value": "RE" },
  { "name": "Romania", "value": "RO" },
  { "name": "Russian Federation", "value": "RU" },
  { "name": "Rwanda", "value": "RW" },
  { "name": "Saint Barthélemy", "value": "BL" },
  { "name": "Saint Helena, Ascension and Tristan da Cunha", "value": "SH" },
  { "name": "Saint Kitts and Nevis", "value": "KN" },
  { "name": "Saint Lucia", "value": "LC" },
  { "name": "Saint Martin (French part)", "value": "MF" },
  { "name": "Saint Pierre and Miquelon", "value": "PM" },
  { "name": "Saint Vincent and the Grenadines", "value": "VC" },
  { "name": "Samoa", "value": "WS" },
  { "name": "San Marino", "value": "SM" },
  { "name": "Sao Tome and Principe", "value": "ST" },
  { "name": "Saudi Arabia", "value": "SA" },
  { "name": "Senegal", "value": "SN" },
  { "name": "Serbia", "value": "RS" },
  { "name": "Seychelles", "value": "SC" },
  { "name": "Sierra Leone", "value": "SL" },
  { "name": "Singapore", "value": "SG" },
  { "name": "Sint Maarten (Dutch part)", "value": "SX" },
  { "name": "Slovakia", "value": "SK" },
  { "name": "Slovenia", "value": "SI" },
  { "name": "Solomon Islands", "value": "SB" },
  { "name": "Somalia", "value": "SO" },
  { "name": "South Africa", "value": "ZA" },
  { "name": "South Georgia and the South Sandwich Islands", "value": "GS" },
  { "name": "South Sudan", "value": "SS" },
  { "name": "Spain", "value": "ES" },
  { "name": "Sri Lanka", "value": "LK" },
  { "name": "Sudan", "value": "SD" },
  { "name": "Suriname", "value": "SR" },
  { "name": "Svalbard and Jan Mayen", "value": "SJ" },
  { "name": "Sweden", "value": "SE" },
  { "name": "Switzerland", "value": "CH" },
  { "name": "Syrian Arab Republic", "value": "SY" },
  { "name": "Taiwan, Province of China", "value": "TW" },
  { "name": "Tajikistan", "value": "TJ" },
  { "name": "Tanzania, United Republic", "value": "TZ" },
  { "name": "Thailand", "value": "TH" },
  { "name": "Timor-Leste", "value": "TL" },
  { "name": "Togo", "value": "TG" },
  { "name": "Tokelau", "value": "TK" },
  { "name": "Tonga", "value": "TO" },
  { "name": "Trinidad and Tobago", "value": "TT" },
  { "name": "Tunisia", "value": "TN" },
  { "name": "Turkey", "value": "TR" },
  { "name": "Turkmenistan", "value": "TM" },
  { "name": "Turks and Caicos Islands", "value": "TC" },
  { "name": "Tuvalu", "value": "TV" },
  { "name": "Uganda", "value": "UG" },
  { "name": "Ukraine", "value": "UA" },
  { "name": "United Arab Emirates", "value": "AE" },
  { "name": "United Kingdom of Great Britain and Northern Ireland", "value": "GB" },
  { "name": "United States of America", "value": "US" },
  { "name": "United States Minor Outlying Islands", "value": "UM" },
  { "name": "Uruguay", "value": "UY" },
  { "name": "Uzbekistan", "value": "UZ" },
  { "name": "Vanuatu", "value": "VU" },
  { "name": "Venezuela (Bolivarian Republic of)", "value": "VE" },
  { "name": "Viet Nam", "value": "VN" },
  { "name": "Virgin Islands (British)", "value": "VG" },
  { "name": "Virgin Islands (U.S.)", "value": "VI" },
  { "name": "Wallis and Futuna", "value": "WF" },


  { "name": "Western Sahara", "value": "EH" },

  
  { "name": "Yemen", "value": "YE" },
  { "name": "Zambia", "value": "ZM" },
  { "name": "Zimbabwe", "value": "ZW" },
]