import React, { useEffect, useState } from 'react'
import { RootState } from '../../../../redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { backgroundCheckPackageRequestsCostsRequest } from '../../../../redux/actions/products/backgroundCheck/package';
import Mainloader, { EmptyStateComp } from '../../../../components/utils';
import NotificationToast from '../../../../components/utils/notifToast';

export default function BackgroundCheckTransactionsPage() {
    const [notifVal, setNotifVal] = useState(false)
    const [notif, setNotif] = useState("")
    const [notifTitle, setNotifTitle] = useState("")

    const requestTransactionsState = useSelector((state: RootState) => state.backgroundCheckPackageRequestsCostsReducer);

    const dispatch = useDispatch()

    useEffect(() => {
        getAllRequestTransactions()
    }, [])

    let getAllRequestTransactions = () => {
        const callback = (data: any) => {
            if (!data.status) {
                setNotifTitle("Error")
                setNotif(data.detail)
                setNotifVal(true)
            }
        };
        let data: any = {
            values: {},
            callback,
        };
        dispatch(backgroundCheckPackageRequestsCostsRequest(data))
    }

    return (
        <div>
            {(notif && notifVal) && <NotificationToast title={notifTitle} message={notif} closeNotif={() => setNotifVal(!notifVal)} />}


            {requestTransactionsState?.isLoading && <Mainloader />}

            {!requestTransactionsState?.isLoading &&
                <div className="container-fluid px-md-4 mt-4">
                    <div className="table-header">
                        <div className="row">
                            <div className="col-md-3 col-lg-4">
                                <div className="pb-2">
                                    <h5>Payment History</h5>
                                    <p>View all Background Checks payment history here</p>
                                </div>
                            </div>
                            {/* <div className="col-md-9 col-lg-8">
                                <div className="row justify-content-end">
                                    <div className="col-md-5">
                                        <div className="input-group">
                                            <input type="search" className="form-control search-input border-end-0" placeholder="Search" value={searchValue}
                                                onChange={e => { setSearchValue(e.target.value); filterData(undefined, undefined, undefined, undefined, e.target.value) }}
                                            />
                                            <span className="input-group-text border-start-0"
                                                style={{ borderRadius: "0px 5px 5px 0px", backgroundColor: "#fff" }}
                                            >
                                                <i className="ri-search-line" />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <button className="btn-filter btn py-3" onClick={() => setFilterModal(true)}>
                                            Filter
                                            <i className="ms-2 ri-xl ri-filter-3-fill" />
                                        </button>
                                    </div>
                                    <div className="col-md-3 pb-2 text-end">
                                        <button className='btn btn-green py-3 ' onClick={() => setInitiateRequestModal(true)}>Initiate Request</button>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>


                    <div className="table-responsive mt-4">

                        {((requestTransactionsState?.resp?.details?.length < 1)) ?
                            <div className="my-5 py">
                                <EmptyStateComp title={"No transaction found"}
                                    ctaAction={() => { }}
                                    desc={"You will see all your request payments here"}
                                    ctaValue={""}
                                />
                            </div>
                            :
                            <table className="table">
                                <thead className="">
                                    <tr>
                                        <th scope="col">S/N</th>
                                        <th scope="col">Package Name</th>
                                        <th scope="col">Candidate Name</th>
                                        <th scope="col">Amount</th>
                                        {/* <th scope="col">Candidate Email</th> */}
                                        {/* <th scope="col">Date Created</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {requestTransactionsState?.resp?.detail?.map((val: any, i: number) => (
                                        <tr>
                                            <th scope="row">{i + 1}</th>
                                            <td>{val?.package_name}</td>
                                            <td>{val?.candidate_name || val?.customer_name || "Not Available"}</td>
                                            <td>{`${val?.currency} ${val?.amount}`}</td>
                                            {/* <td><span className='add-ellipsis'>{moment(val?.created_at).format("MMMM Do YYYY, h:mm")}</span></td> */}

                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        }
                    </div>
                    {/* <div className='d-flex align-items-center my-3'>
                        <p className='me-3 my-0'>{pageValue}</p>
                        {allRequestState?.resp?.last_evaluated_key &&
                            <button className='btn btn-green' onClick={() => nextPage(allRequestState?.resp?.last_evaluated_key)}>Next</button>
                        }
                    </div> */}
                </div>
            }
        </div>
    )
}
