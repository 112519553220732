import { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Sidebar from './components/wrapper/sidebar'
import Topbar from './components/wrapper/topbar'
import Dashboard from './pages/dashboard'
import LoginPage from './pages/Auth/login'
import SettingsPage from './pages/settings'
import SignUpPage from './pages/Auth/signUp'
// import WalletPage from './pages/wallet'

// import IdPassVerificationPage from './pages/products/identitypass/verification'
// import IdpassCheckerWidgetPage from './pages/products/identitypass/checkerWidget'
// import IdentityPassUpdateWidgetPage from './pages/products/identitypass/checkerWidget/UpdateWidget'

// import APILibraries from './pages/apiLibraries';
// import useTourGuide from './hooks/useTourGuide'
import SubscriptionPage from './pages/subscription'
import ResetPasswordPage from './pages/Auth/resetPassword'
import NotificationsPage from './pages/notifications'
import APIKeys from './components/ApiLibraries/apiKeys'
import Application from './components/ApiLibraries/applications'
// import ApiStatusComp from './components/ApiLibraries/apiStatus'
import AcceptInvitationPage from './pages/Auth/acceptInvite'
import SDKLibraryPage from './pages/sdkLibraries'
import ReferralPage from './pages/referral'
import TourGuideProvider from './contexts/tour-guide'
import Layout from './Layout'
import AuthWrapper from './components/wrapper/authWrapper'
import Mainloader from './components/utils'
import BackgroundCheckConsentPage from './components/products/backgroundCheck/request/checks/consent'
import BackgroundCheckRequestCandidateFormPage from './pages/products/backgroundCheck/request/checks'
import BackgroundCheckRequestPage from './pages/products/backgroundCheck/request'
import CandidateReportPage from './components/products/backgroundCheck/request/candidateReport'
import BackgroundCheckRequestInitiatePage from './components/products/backgroundCheck/request/requestCheck'
import BackgroundCheckPackagePage from './pages/products/backgroundCheck/package'
import BackgroundCheckCreatePackagePage from './components/products/backgroundCheck/package/createPackage'
import BackgroundCheckUpdatePackagePage from './components/products/backgroundCheck/package/updatePackage'
// import BackgroundCheckNewReports from './components/products/backgroundCheck/report/BackgroundCheckNewReports'
import BackgroundCheckConsentPageBolt from './components/products/backgroundCheck/request/checks/externals/bolt/consent'
import BackgroundCheckRequestCandidateFormPageBolt from './pages/products/backgroundCheck/request/checks/externals/bolt'
import BackgroundCheckRequestUpdateSubmissionPageBolt from './pages/products/backgroundCheck/request/checks/externals/bolt/updateSubmission'
import BackgroundCheckRequestMerchantCandidateFormPage from './pages/products/backgroundCheck/request/checks/merchant'
import BackgroundCheckTransactionsPage from './pages/products/backgroundCheck/transactions'
import BackgroundCheckReportCheckerPage from './pages/products/backgroundCheck/reportChecker'
import BackgroundCheckRequestReferencesFormPage from './pages/products/backgroundCheck/request/checks/references'

interface IUserRights {
    userRights: any
    includes: any
}

function App() {
    const [userRights, setUserRights] = useState<IUserRights | []>([])
    const [permissionKey, setPermissionKey] = useState('')
    const [loadingState, setLoadingState] = useState(false)

    const tenantInfo: any = localStorage.getItem('tenant')
	const queryParams = new URLSearchParams(window.location.search)
	let packageId = queryParams.get('packageRef') || ""

    let setFilteredRights = (val: any) => {
        setUserRights(val)
    }

    let pushPermissionKey = (val: string) => {
        setPermissionKey(val)
    }

    let changeLoadingState = (val: boolean) => {
        setLoadingState(val)
    }

    return (
        <div>
            {window.location.pathname === '/login' ||
            window.location.pathname === '/signUp' ||
            window.location.pathname === '/ResetPassword' ||
            window.location.pathname === '/Accept-Invitation' ||
            window.location.pathname === '/BackgroundCheck/Requests/Accept' ||
            window.location.pathname === '/BackgroundCheck/Requests/Candidate-Form' ||
            window.location.pathname === '/BackgroundCheck/Requests/Merchant-Candidate-Form' ||
            window.location.pathname === '/BackgroundCheck/Requests/Update-Form'  ||
            window.location.pathname === '/BackgroundCheck/Report-Checker' ||
            window.location.pathname === '/BackgroundCheck/Reference-Form' ? (
                <div>
                    <Routes>
                        <Route path="/login" element={<LoginPage />} />

                        <Route path="/signUp" element={<SignUpPage />} />
                        <Route path="/ResetPassword" element={<ResetPasswordPage />} />
                        <Route path="/Accept-Invitation" element={<AcceptInvitationPage />} />
                        <Route
                            path="/BackgroundCheck/Requests/Accept"
                            element={(
                                // live
                                (packageId === "6b47ea42-f463-47c8-879f-e57fff7d99fa") || 
                                (packageId === "f0206f1d-edd7-441d-b77a-b8a34daffc37") ||
                                (packageId === "7fd596bf-be48-4a36-b5cd-11e55f0e8fc9") ||


                                (packageId === "b8b7428b-5d88-461e-a681-388290dd2b9b") || 
                                (packageId === "8f20da67-2f24-45fa-b233-80e1cabc6600") ||
                                (packageId === "d6741437-df4f-4e39-be34-2310a7a56322") 

                                ||
                                // dev
                                (packageId === "fc9dcbd1-9845-45b6-bfa5-2da03992d729") ||
                                (packageId === "29297ffd-235b-48e3-a5d8-000230ac7ba5") ||
                                (packageId === "df91b03a-e71e-4045-8355-d5e0202ebab7") ||


                                (packageId === "358fab83-09af-4abb-9b04-72babb8df94d") ||
                                (packageId === "e77f7553-f3b8-4c74-ba59-28ab328b5e3d")
                                
                            )
                                ?
                                <BackgroundCheckRequestCandidateFormPageBolt/>
                                // <BackgroundCheckConsentPageBolt tenantInfo={JSON.parse(tenantInfo)}/>
                                :
                                <BackgroundCheckConsentPage tenantInfo={JSON.parse(tenantInfo)} />
                            }
                        />
                        <Route path="/BackgroundCheck/Requests/Candidate-Form"
                            element={ <BackgroundCheckRequestCandidateFormPage /> }
                        />
                        <Route path="/BackgroundCheck/Requests/Merchant-Candidate-Form"
                            element={ <BackgroundCheckRequestMerchantCandidateFormPage /> }
                        />
                        <Route path="/BackgroundCheck/Requests/Update-Form"
                            element={ <BackgroundCheckRequestUpdateSubmissionPageBolt/>}
                        />
                        <Route path="/BackgroundCheck/Report-Checker"
                            element={ <BackgroundCheckReportCheckerPage/>}
                        />
                        <Route path="/BackgroundCheck/Reference-Form"
                            element={ <BackgroundCheckRequestReferencesFormPage/>}
                        />
                    </Routes>
                </div>
            ) : (
                <AuthWrapper>
                    <TourGuideProvider>
                        <>
                            {loadingState && <Mainloader />}

                            <div
                                style={{
                                    visibility: `${loadingState ? 'hidden' : 'visible'}`,
                                    // not scrollable when loading fix
                                    height: `${loadingState ? '100vh' : 'auto'}`,
                                    overflow: `${loadingState ? 'hidden' : 'scroll'}`,
                                }}
                                className="d-lg-flex">
                                <div className="col-lg-2">
                                    <Sidebar
                                        pushFilteredRights={setFilteredRights}
                                        pushPermissionKey={pushPermissionKey}
                                        userRights={userRights}
                                        tenantInfo={JSON.parse(tenantInfo)}
                                    />
                                </div>
                                <Layout className="col-lg-10">
                                    <Topbar
                                        userRights={userRights}
                                        tenantInfo={JSON.parse(tenantInfo)}
                                    />
                                    <div className="pages mt-4">
                                        <Routes>
                                            <Route
                                                path="/"
                                                element={
                                                    <Dashboard
                                                        userRights={userRights}
                                                        permissionKey={permissionKey}
                                                        changeLoadingState={changeLoadingState}
                                                    />
                                                }
                                            />

                                            {/* <Route
                                                path="/Identitypass/Verification/Data"
                                                element={
                                                    <IdPassVerificationPage
                                                        userRights={userRights}
                                                    />
                                                }
                                            />
                                            <Route
                                                path="/Identitypass/Verification/Document"
                                                element={
                                                    <IdPassVerificationPage
                                                        userRights={userRights}
                                                    />
                                                }
                                            />
                                            <Route path="/Identitypass/Checker-Widget">
                                                <Route
                                                    index
                                                    element={
                                                        <IdpassCheckerWidgetPage
                                                            userRights={userRights}
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path=":id"
                                                    element={
                                                        <IdentityPassUpdateWidgetPage
                                                            userRights={userRights}
                                                        />
                                                    }
                                                />
                                            </Route> */}

                                            <Route
                                                path="/BackgroundCheck/Requests"
                                                element={<BackgroundCheckRequestPage userRights={userRights} />}
                                            />
                                            <Route
                                                path="/BackgroundCheck/Requests/report/:ref"
                                                element={
                                                    <CandidateReportPage 
                                                        userRights={userRights} 
                                                        tenantInfo={JSON?.parse(tenantInfo)}
                                                    />
                                                }
                                            />
                                            <Route
                                                path="/BackgroundCheck/Requests/Initiate"
                                                element={<BackgroundCheckRequestInitiatePage userRights={userRights}  />}
                                            />
                                            <Route
                                                path="/BackgroundCheck/Packages"
                                                element={<BackgroundCheckPackagePage userRights={userRights} />}
                                            />
                                            <Route
                                                path="/BackgroundCheck/Packages/Create-Package"
                                                element={<BackgroundCheckCreatePackagePage userRights={userRights} />}
                                            />
                                            <Route
                                                path="/BackgroundCheck/Packages/Update-Package/:ref"
                                                element={<BackgroundCheckUpdatePackagePage userRights={userRights}  />}
                                            />
                                            <Route
                                                path="/BackgroundCheck/Transactions"
                                                element={<BackgroundCheckTransactionsPage />}
                                            />

                                            {/* <Route path="/Api-Library" element={<APILibraries/>} /> */}
                                            <Route
                                                path="/API-Library/API-Keys"
                                                element={<APIKeys userRights={userRights} />}
                                            />
                                            <Route
                                                path="/API-Library/Applications"
                                                element={<Application userRights={userRights} />}
                                            />
                                            
                                            {/* <Route
                                                path="/pdfpage"
                                                element={<BackgroundCheckNewReports />}
                                            /> */}


                                            {/* <Route
                                                path="/API-Library/API-Status"
                                                element={<ApiStatusComp userRights={userRights} />}
                                            /> */}
                                            <Route
                                                path="/SDK-Library/Webhook"
                                                element={<SDKLibraryPage />}
                                            />
                                            <Route
                                                path="/Subscription"
                                                element={
                                                    <SubscriptionPage
                                                        userRights={userRights}
                                                        permissionKey={permissionKey}
                                                    />
                                                }
                                            />
                                            <Route
                                                path="/Referral"
                                                element={
                                                    <ReferralPage
                                                        tenantInfo={JSON.parse(tenantInfo)}
                                                    />
                                                }
                                            />
                                            <Route
                                                path="/Settings"
                                                element={
                                                    <SettingsPage
                                                        userRights={userRights}
                                                        changeLoadingState={changeLoadingState}
                                                        tenantInfo={JSON.parse(tenantInfo)}
                                                    />
                                                }
                                            />
                                            <Route
                                                path="/Notifications"
                                                element={<NotificationsPage />}
                                            />
                                        </Routes>
                                    </div>
                                </Layout>
                            </div>
                        </>
                    </TourGuideProvider>
                </AuthWrapper>
            )}
        </div>
    )
}

export default App
