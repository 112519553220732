import axios from 'axios'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '../../../../constants/actionTypes'
import global from '../../../../constants/global'
import Cookies from 'js-cookie'
import { authorizationRedirect, serverCodes } from '../../../../constants/api'
import { IBackgroundCheckReportCheckerGetReport } from '../../../../actions/products/backgroundCheck/reportChecker/types'
import { backgroundCheckReportCheckerGetReportFailure, backgroundCheckReportCheckerGetReportSuccess } from '../../../../actions/products/backgroundCheck/reportChecker'

let accessT = Cookies.get('babtbu') || ''
let orgId = Cookies.get('org') || ''

// axios declarations
const backgroundCheckReportCheckerGetReport = async (payload: any) => {
    const { data } = await axios.post<IBackgroundCheckReportCheckerGetReport[]>(
        global.apiBaseUrl + global.backgroundCheckApiUrl + 'api/v1/bolt-check-validation',
        payload,
        {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: accessT,
                Organisation: orgId,
            },
        }
    )
    return data
}

// sagas -- request values
function* backgroundCheckReportCheckerGetReportSaga(action: any) {
    try {
        const response: { data: any } = yield call(backgroundCheckReportCheckerGetReport, {
            reference: action.payload.values.reference,
        })
        yield put(
            backgroundCheckReportCheckerGetReportSuccess({
                resp: response,
            })
        )
        action.payload.callback(response)
    } catch (e: any) {
        if (serverCodes.includes(e?.response?.request?.status) || !e?.response?.request?.status) {
            yield put(
                backgroundCheckReportCheckerGetReportFailure({
                    error: "An error occurred, hang on a minute as we work towards fixing this error.",
                })
            )
            action.payload.callback({
                detail: "An error occurred, hang on a minute as we work towards fixing this error.",
            })
            return
        }
        if (e.response.request.status === 401) {
            authorizationRedirect()
        } else {
            yield put(
                backgroundCheckReportCheckerGetReportFailure({
                    error: e.response.data.detail,
                })
            )
            action.payload.callback(e.response.data)
        }
    }
}

// triggers
export function* backgroundCheckReportCheckerGetReportSagaTrigger() {
    yield all([
        takeLatest(actionTypes.BACKGROUND_CHECK_REPORT_CHECKER_GET_REPORT_REQUEST, backgroundCheckReportCheckerGetReportSaga),
    ])
}