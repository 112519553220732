export let refData = [
    {
        name: "Test Ref",
        endpointId: "professional_reference",
        structure: [
            {
                label: "Candidate Name",
                type: "text",
                placeholder: "Input response",
                fieldName: "candidate_name",
                fieldId: "1",
                required: true,
            },
            // {
            //     label: "Referee Name",
            //     type: "text",
            //     placeholder: "Input response",
            //     fieldName: "referee_name",
            //     fieldId: "2",
            //     required: true,
            // },
            {
                label: "What is your relationship to the candidate? / How do you know the candidate?",
                type: "textarea",
                placeholder: "Input response",
                fieldName: "relationship_to_candidate",
                fieldId: "2",
                required: true,
            },
            {
                label: "Have you ever worked on a project with this individual? If yes, did the applicant follow through with his/her portion of the duties or responsibilities?",
                type: "textarea",
                placeholder: "Input response",
                fieldName: "collaboration_with_candidate",
                fieldId: "3",
                required: true,
            },
            {
                label: "Based on your experience with this individual, kindly rate the  below traits as observed in him/her",
                type: "groupedRadio",
                fieldName: "candidate_rating",
                fieldId: "4",
                required: true,
                tagOptions: [
                    "Below Average (BA)",
                    "Average (A)",
                    "Above Average (AA)",
                ],
                options: [
                    {
                        tag: "CREATIVITY",
                        // tagOptions: [
                        //     "Below Average (BA)",
                        //     "Average (A)",
                        //     "Above Average (AA)",
                        // ],
                        // tagType: "radio"
                    },
                    {
                        tag: "PROFESSIONALISM",
                    },
                    {
                        tag: "ACCOUNTABILITY",
                    },
                    {
                        tag: "RELIABILITY",
                    },
                ],
                placeholder: "Input response"
            },
            {
                label: "This person could be more effective if he/she worked to improve on:",
                type: "textarea",
                placeholder: "Input response",
                fieldName: "candidate_improvement_point",
                fieldId: "5",
                required: true,
            },
            {
                label: "Candidate's strengths include:",
                type: "textarea",
                placeholder: "Input response",
                fieldName: "candidate_strength",
                fieldId: "6",
                required: true,
            },
            {
                label: "Kindly give us a brief description of your opinion of the candidate's character and interpersonal skills",
                type: "textarea",
                placeholder: "Input response",
                fieldName: "candidate_character",
                fieldId: "7",
                required: true,
            },
            {
                label: "If you were an employer, would you consider the candidate for hire within your company?",
                type: "select",
                fieldName: "recommendation_for_hiring",
                fieldId: "8",
                required: true,
                options: [
                    {name:"Yes", value:"Yes"},
                    {name:"No", value:"No"},
                ]
            },
            {
                label: "Is there any further information you would like to add regarding the candidate besides the information requested above?",
                type: "textarea",
                fieldName: "other_relevant_info",
                fieldId: "9",
                required: false,
            },
            {
                label: "Referee Name",
                type: "text",
                placeholder: "Input response",
                fieldName: "referee_name",
                fieldId: "10",
                required: true,
            },
            {
                label: "Job Title",
                type: "text",
                placeholder: "Input response",
                fieldName: "referee_job_title",
                fieldId: "11",
                required: true,
            },
            {
                label: "Company/Organisation Name",
                type: "text",
                placeholder: "Input response",
                fieldName: "referee_company",
                fieldId: "12",
                required: true,
            },
        ]
    },
    {
        name: "Test Education Ref",
        endpointId: "education",
        structure: [
            {
                label: "School/Institution",
                type: "text",
                placeholder: "Input response"
            },
            {
                label: "Candidate Name",
                type: "text",
                placeholder: "Input response"
            },
            {
                label: "Candidate's Gender",
                type: "text",
                placeholder: "Input response"
            },
            {
                label: "Course of study",
                type: "text",
                placeholder: "Input response"
            },
            {
                label: "Year of Graduation",
                type: "text",
                placeholder: "Input response"
            },
            {
                label: "Is the certificate authentic?",
                type: "select",
                options: ["Yes", "No"],
            },
            {
                label: "Upload Document",
                type: "file",
                placeholder: "Input response"
            },
            {
                label: "This responses were confirmed by:",
                type: "text",
                placeholder: "Input response"
            },
            {
                label: "Position",
                type: "text",
                placeholder: "Input response"
            },
        ]
    },
    {
        name: "Test Ref",
        endpointId: "employment",
        structure: [
            {
                label: "Company/Organisation Name",
                type: "text",
                placeholder: "Input response",
                fieldId:"1"
            },
            {
                label: "Candidate Name",
                type: "text",
                placeholder: "Input response",
                fieldId:"2"
            },
            {
                label: "Date of employment (Start Date)",
                type: "date",
                placeholder: "Input response",
                fieldId:"3"
            },
            {
                label: "Date of employment (End Date)",
                type: "date",
                placeholder: "Input response",
                fieldId:"4"
            },
            {
                label: "Position Held/Title",
                type: "text",
                placeholder: "Input response",
                fieldId:"5"
            },
            {
                label: "Employment Contract",
                type: "select",
                options: ["Fulltime", "Part time", "Contract", "Internship", "Volunteer"],
                fieldId:"6"
            },
            {
                label: "Duties/ Responsibilities",
                type: "textarea",
                placeholder: "Input response",
                fieldId:"7"
            },
            {
                label: "Level of Supervision",
                type: "select",
                options: ["Direct", "Close", "Intermittent"],
                fieldId:"8"
            },
            {
                label: "Overall Work Performance",
                type: "textarea",
                placeholder: "Input response",
                fieldId:"9"
            },
            {
                label: "Any Outstanding Achievement(s)?",
                type: "textarea",
                placeholder: "Input response",
                fieldId:"10"
            },
            {
                label: "How did the candidate cope under pressure?",
                type: "textarea",
                placeholder: "Input response",
                fieldId:"11"
            },
            {
                label: "How did the candidate relate with others?",
                type: "textarea",
                placeholder: "Input response",
                fieldId:"12"
            },
            {
                label: "What are the candidate's strengths?",
                type: "textarea",
                placeholder: "Input response",
                fieldId:"13"
            },
            {
                label: "Areas of improvement",
                type: "textarea",
                placeholder: "Input response",
                fieldId:"14"
            },
            {
                label: "Would you consider the candidate for rehire?",
                type: "radio",
                options: ["Yes", "No",],
                fieldId:"15"
            },
            {
                label: "Description of the candidate",
                type: "groupedRadio",
                tagOptions: [
                    "Excellent",
                    "Very Good",
                    "Good",
                    "Average",
                    "Below Average",
                ],
                options: [
                    {
                        tag: "TRUSTWORTHINESS",
                        // tagOptions: [
                        //     "Excellent",
                        //     "Very Good",
                        //     "Good",
                        //     "Average",
                        //     "Below Average",
                        // ],
                        tagType: "radio"
                    },
                    {
                        tag: "DEPENDABILITY/RELIABILITY",
                    },
                    {
                        tag: "INITIATIVE",
                    },
                    {
                        tag: "COMMUNICATION",
                    },
                    {
                        tag: "WRITTEN COMMUNICATION",
                    },
                    {
                        tag: "TIME MANAGEMENT",
                    },
                    {
                        tag: "SOUND/TIMELY DECISIONS",
                    },
                    {
                        tag: "ADAPTABILITY",
                    },
                    {
                        tag: "PROFESSIONALISM",
                    },
                ],
                placeholder: "Input response",
                fieldId:"16"
            },
            {
                label: "Any incidence of fraud or misconduct involving financial losses?",
                type: "textarea",
                placeholder: "Input response",
                fieldId:"17"
            },
            {
                label: "Any disciplinary cases?",
                type: "textarea",
                placeholder: "Input response",
                fieldId:"18"
            },
            {
                label: "General opinon about the candidate",
                type: "textarea",
                placeholder: "Input response",
                fieldId:"19"
            },
            {
                label: "Reason for leaving",
                type: "textarea",
                placeholder: "Input response",
                fieldId:"20"
            },
            {
                label: "How did the candidate leave?",
                type: "groupedRadio",
                tagOptions: [
                    "Yes",
                    "No",
                ],
                options: [
                    {
                        tag: "SERVED NOTICE?",
                        // tagOptions: [
                        //     "Yes",
                        //     "No",
                        // ],
                        // tagType: "radio"
                    },
                    {
                        tag: "RETURNED ASSIGNED WORK TOOLS?",
                    },
                ],
                placeholder: "Input response",
                fieldId:"21"
            },
            {
                label: "These responses were confirmed by:",
                type: "text",
                placeholder: "Input response",
                fieldId:"22"
            },
            {
                label: "Position",
                type: "text",
                placeholder: "Input response",
                fieldId:"23"
            },
        ]
    }

] 