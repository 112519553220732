import { Player } from '@lottiefiles/react-lottie-player'
import React from 'react'
// import LoadingPageLogo from '../../assets/loadingPageLogo.svg'
// import loadingPageGif from '../../assets/loadingPageGif.gif'
import loaderGif from "../../assets/loader.json"
import { Spinner } from 'react-bootstrap'


// let tenantLogo = Cookies.get("orgLogo") || ""
const tenantInfo: any = localStorage.getItem("tenant");

export default function LoadingPage() {
	return (
		<div className='loading-page-area'>
			<div>
				<div style={{ width: "200px", position: "relative", bottom: "-50px" }}>
					<div>
                <div>
                    <div style={{ width: '200px', position: 'relative', bottom: '-50px' }}>
                        {JSON.parse(tenantInfo)?.products?.map((log: any, i: any) => {
                            if (log?.code === "BACKGROUND-CHECK") {
                                return (
                                    <img src={log?.logo} alt="" className="w-100" />
                                )
                            }
                        })}
                    </div>
                    <div style={{ paddingTop: "70px" }}>
                        {/* <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="spinner-border" style={{width: "3rem", height: "3rem"}}
                        /> */}

                        <div style={{ width: '200px' }}>
                            <Player
                                src={loaderGif}
                                className="player"
                                loop
                                autoplay
                            />
                            <div className="sr-only">
                                Loading...
                            </div>
                        </div>
                    </div>
                </div>
						{/* <Spinner
							as="span"
							animation="border"
							size="sm"
							role="status"
							aria-hidden="true"
							className="spinner-border" style={{ width: "3rem", height: "3rem" }}
						/>
						<div className="sr-only mt-3">
							Loading...
						</div> */}
					</div>
					{/* <img src={LoadingPageLogo} alt="" className='w-100' /> */}
				</div>
				{/* <div style={{width:"200px"}}>
            <img src={loadingPageGif} alt="" className='w-100' />
          </div> */}
			</div>
		</div>
	)
}
