import React, { useCallback, useRef, useState } from 'react'
import Webcam from 'react-webcam'
import { EmptyStateComp } from '../../../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { backgroundCheckRequestFileUploadRequest } from '../../../../../../redux/actions/products/backgroundCheck/request/checks';
import NotificationToast from '../../../../../utils/notifToast';
import { RootState } from '../../../../../../redux/reducers';
import { Spinner } from 'react-bootstrap';

export default function BackgroundCheckFaceVerificationComp(props: any) {


    const webcamRef = useRef<any>()
    const [faceData, setFaceData] = useState({})
    const [faceImg, setFaceImg] = useState("")
    const [cameraError, setCameraError] = useState(false)
    const [notifVal, setNotifVal] = useState(false)
    const [notif, setNotif] = useState("")
    const [notifTitle, setNotifTitle] = useState("")


    const uploadFileState = useSelector((state: RootState) => state.backgroundCheckRequestFileUploadReducer)


    const dispatch = useDispatch()

    const capture = useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();

            const callback = (data: any) => {
                if (data?.status) {
                    let faceDataValues = {
                        face: [
                            {
                                title: "face",
                                form_value: "facial_verification",
                                endpoint: "facial_verification",
                                form_data: data?.detail,
                                form_id: "faceID-ad91b55a-2b1a-4d24-adea-886cf7280f471"
                            }
                        ]
                    }

                    setFaceData(faceDataValues)
                    setFaceImg(data?.detail)
                }
                else {
                    setNotifTitle("Error")
                    setNotif(data.detail)
                    setNotifVal(true)
                }
            };
            let data: any = {
                values: {
                    file: imageSrc,
                },
                callback,
            };
            dispatch(backgroundCheckRequestFileUploadRequest(data))

        },
        [webcamRef]
    );

    let retakeImage = () => {
        setFaceData({})
        setFaceImg("")

    }

    let proceedToNext = () => {
        props?.proceedFromFaceVerification(faceData)

    }

    const videoConstraints = {
        width: 320,
        height: 300,
        facingMode: "user"
    };

//     navigator.mediaDevices.getUserMedia(constraints)
//   .then((stream) => {
//     /* use the stream */
//   })
//   .catch((err) => {
//     /* handle the error */
//   });
    
//     navigator.getUserMedia({audio:true,video:true}, function(stream:any) {
//         stream.getTracks().forEach(x=>x.stop());
//       }, err=>console.log(err));

    return (
        <div>
            {(notif && notifVal) && <NotificationToast title={notifTitle} message={notif} closeNotif={() => setNotifVal(!notifVal)} />}

            <div className="container">
                <button className='btn btn-green mb-5' onClick={() => props?.goToFormPage(1)}>
                    Back to form
                </button>
                <h3>Facial Verification</h3>
                <p>This form requires your face to proceed; Please capture your face here.</p>
            </div>

            <div className='col-md-6 mx-auto text-center'>
                {cameraError &&
                    <div className="">
                        <div className="my-5 py">
                            <EmptyStateComp
                                title={'Camera Error'}
                                ctaAction={() => { }}
                                desc={"This error is mostly caused by disabled camera, kindly enable your camera"}
                                // desc={"To enable your camera, click the canceled video icon in your address bar, allow this url to access your camera, then reload the page."}
                                ctaValue={''}
                            />
                        </div>
                    </div>
                }

                {!faceImg &&
                    <>
                        <Webcam
                            audio={false}
                            height={300}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            width={320}
                            videoConstraints={videoConstraints}
                            onUserMediaError={() => setCameraError(true)}
                            onUserMedia={() => setCameraError(false)}

                        />

                        {!cameraError &&
                            <div>
                                <button className='btn btn-deep-green mt-3' disabled={uploadFileState?.isLoading} onClick={capture}>
                                    {uploadFileState?.isLoading ?
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span className="sr-only">Capturing...</span>
                                        </>
                                        :
                                        "Capture photo"
                                    }
                                </button>
                            </div>
                        }
                    </>
                }

                {faceImg &&
                    <>
                        <img src={faceImg} style={{ borderRadius: "50%", width: "300px", height: "300px" }} alt="" />

                        <div className='mt-3'>
                            <button className='btn btn-deep-green-outline me-3' onClick={retakeImage}>Retake</button>
                            <button className='btn btn-deep-green' onClick={proceedToNext}>Proceed</button>
                        </div>
                    </>
                }


            </div>


        </div>
    )
}