import { actionTypes } from '../../../../constants/actionTypes'
import { 
    BackgroundCheckReportCheckerGetReportFailure, BackgroundCheckReportCheckerGetReportFailurePayload, BackgroundCheckReportCheckerGetReportPayload, 
    BackgroundCheckReportCheckerGetReportRequest, BackgroundCheckReportCheckerGetReportSuccess, BackgroundCheckReportCheckerGetReportSuccessPayload, 

} from './types';



export const backgroundCheckReportCheckerGetReportRequest = (payload:BackgroundCheckReportCheckerGetReportPayload):BackgroundCheckReportCheckerGetReportRequest => ({
    type: actionTypes.BACKGROUND_CHECK_REPORT_CHECKER_GET_REPORT_REQUEST,
    payload,
});
export const backgroundCheckReportCheckerGetReportSuccess = (payload:BackgroundCheckReportCheckerGetReportSuccessPayload):BackgroundCheckReportCheckerGetReportSuccess => ({
    type: actionTypes.BACKGROUND_CHECK_REPORT_CHECKER_GET_REPORT_SUCCESS,
    payload,
});
export const backgroundCheckReportCheckerGetReportFailure = (payload:BackgroundCheckReportCheckerGetReportFailurePayload):BackgroundCheckReportCheckerGetReportFailure => ({
    type: actionTypes.BACKGROUND_CHECK_REPORT_CHECKER_GET_REPORT_FAILURE,
    payload,
});


