
import { BackgroundCheckReportCheckerGetReportState } from '../../../../actions/products/backgroundCheck/reportChecker/types';
import { actionTypes } from './../../../../constants/actionTypes';


const initialState : BackgroundCheckReportCheckerGetReportState = {
    isLoading: false,
    error: null,
    resp:null,
};


export const backgroundCheckReportCheckerGetReportReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case actionTypes.BACKGROUND_CHECK_REPORT_CHECKER_GET_REPORT_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.BACKGROUND_CHECK_REPORT_CHECKER_GET_REPORT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                resp:action.payload.resp
            };
        case actionTypes.BACKGROUND_CHECK_REPORT_CHECKER_GET_REPORT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload.error
            };
        default:
            return state;
    }
};


