import React, { useEffect, useState } from 'react'
import NotificationToast from '../../../../../../components/utils/notifToast'
import { refData } from './formStructure'
import { backgroundCheckRequestFileUploadRequest, backgroundCheckRequestReferenceAnswerUploadRequest, backgroundCheckRequestReferenceInfoRequest } from '../../../../../../redux/actions/products/backgroundCheck/request/checks'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import { RootState } from '../../../../../../redux/reducers'
import { Player } from '@lottiefiles/react-lottie-player'
import successVerifGif from '../../../../../../assets/successVerif.json'
import cancelGif from '../../../../../../assets/cancel.json'
import Mainloader, { EmptyStateComp } from '../../../../../../components/utils'

export default function BackgroundCheckRequestReferencesFormPage() {
    const [notifVal, setNotifVal] = useState(false)
    const [notif, setNotif] = useState("")
    const [notifTitle, setNotifTitle] = useState("")
    const [formPage, setFormPage] = useState(1)
    const [payloadData, setPayloadData] = useState<any>({})
    const [formStructure, setFormStructure] = useState<any>([])
    const [payloadDataArr, setPayloadDataArr] = useState<any[]>([])


    const backgroundCheckRequestReferenceAnswerUploadState = useSelector((state: RootState) => state.backgroundCheckRequestReferenceAnswerUploadReducer);
    const backgroundCheckRequestReferenceInfoState = useSelector((state: RootState) => state.backgroundCheckRequestReferenceInfoReducer);


    const queryParams = new URLSearchParams(window.location.search)
    // let checkId = queryParams.get('checkId') || ""
    // let requestId = queryParams.get("requestId") || ""
    let refId = queryParams.get("ref") || ""

    const dispatch = useDispatch()

    useEffect(() => {
        getRefInfo()
    }, [])

    let getRefInfo = () => {
        const callback = (data: any) => {
            if (data?.status) {
                if (data?.detail?.submitted) {
                    setFormPage(2)
                }
                setFormStructure(refData?.find(val => val?.endpointId === "professional_reference")?.structure)
                // setFormStructure(refData?.find(val => val?.endpointId === backgroundCheckRequestReferenceInfoState?.resp?.detail.endpoint)?.structure)
            }
            else {
                setFormPage(3)
                setNotifTitle("Error")
                setNotif(data.detail)
                setNotifVal(true)
            }
        };
        let data: any = {
            values: {
                reference_id: refId,
            },
            callback,
        };
        dispatch(backgroundCheckRequestReferenceInfoRequest(data))
    }


    let triggerKeyUpdate = (formType: any, key: any, val: any, id: any, regex: any, label: any, specKey: any, multipleValue: any, required: any) => {

        let keyDataArr: any = payloadDataArr

        if (formType === 'file') {
            var file = val?.target?.files[0]
            let reader = new FileReader()

            if (val?.target?.files?.length > 0) {
                reader.readAsDataURL(file)
                reader.onload = () => {
                    let imgBase: any = reader.result
                    let keyDataIndex = payloadDataArr?.findIndex(item => ((item?.form_value === key) && (item?.field_id === (id))))


                    if (file && (
                        (file.type !== "image/png") &&
                        (file.type !== "image/jpeg") &&
                        (file.type !== "image/jpg") &&
                        (file?.type !== "application/pdf")
                    )) {
                        setNotifTitle('Error')
                        setNotif(`Unsupported ${label} format Uploaded`)
                        setNotifVal(true)
                        // return
                    }
                    else if (file && (file?.size > 15200000)) {
                        // else if (file && (file?.size > 710000)) {

                        setNotifTitle('Error')
                        setNotif(`${label} is larger than 15MB`)
                        setNotifVal(true)

                        setTimeout(() => {
                            setNotif("")
                        }, 3000);
                        // return
                    }
                    else {
                        const callback = (data: any) => {
                            if (data?.status) {
                                if (keyDataIndex !== -1) {
                                    keyDataArr.splice(keyDataIndex, 1)
                                    keyDataArr.push({ "form_type": formType, "form_value": key, "value": data?.detail, "field_id": id, "regex": regex, "file_front_Data": file, "label": label, "multipleValue": multipleValue, "required": required, })
                                }
                                else {
                                    keyDataArr.push({ "form_type": formType, "form_value": key, "value": data?.detail, "field_id": id, "regex": regex, "file_front_Data": file, "label": label, "multipleValue": multipleValue, "required": required, })
                                }

                                setPayloadDataArr(keyDataArr)
                            }
                            else {
                                setNotifTitle("Error")
                                setNotif(data.detail)
                                setNotifVal(true)
                            }
                        };
                        let data: any = {
                            values: {
                                file: imgBase,
                            },
                            callback,
                        };
                        dispatch(backgroundCheckRequestFileUploadRequest(data))

                    }
                }
            }
            else {
                let keyDataIndex = payloadDataArr?.findIndex(item => ((item?.form_value === key) && (item?.field_id === (id))))
                // setFileCheck(imgBase)

                if (keyDataIndex !== -1) {
                    keyDataArr.splice(keyDataIndex, 1)
                }

                setPayloadDataArr(keyDataArr)
            }
        }
        else if (formType === 'tel') {

            let filteredKeyDataArr = keyDataArr?.filter((item: any) => ((item?.form_value === key) && (item?.field_id === (id))))

            if (filteredKeyDataArr?.length > 0) {
                if (specKey === 'phoneCountryCode') {
                    filteredKeyDataArr[0]['phoneCountryCode'] = val
                }
                else {
                    filteredKeyDataArr[0]["value"] = val
                }
            }
            else {
                if (specKey === 'phoneCountryCode') {
                    keyDataArr.push({ "form_type": formType, "form_value": key, "value": "", "field_id": id, "regex": regex, "phoneCountryCode": val, "label": label, "multipleValue": multipleValue, "required": required, })
                }
                else {
                    keyDataArr.push({ "form_type": formType, "form_value": key, "value": val, "field_id": id, "regex": regex, "phoneCountryCode": "", "label": label, "multipleValue": multipleValue, "required": required, })
                }
            }

            setPayloadDataArr(keyDataArr)
        }
        else if(formType ===  "groupedRadio") {

            let valueData = payloadDataArr?.find(item => ((item?.form_value === key) && (item?.field_id === (id)) ))?.value

            // let keyDataIndex = payloadDataArr?.findIndex(item => ((item?.form_value === key) && (item?.field_id === (id)) && (item?.value?.find((newItem:any) => newItem?.tagName === specKey)) ))
            let keyDataIndex = payloadDataArr?.findIndex(item => ((item?.form_value === key) && (item?.field_id === (id)) ))

            let valueDataIndex = valueData?.findIndex((newItem:any) => newItem?.tagName === specKey)

            // console.log(valueData)
            if (keyDataIndex !== -1) {

                keyDataArr.splice(keyDataIndex, 1)
                keyDataArr.push(
                    { "form_type": formType, 
                        "form_value": key, 
                        "value": valueDataIndex !== -1 ? (
                            [
                                ...valueData.slice(0, valueDataIndex),                   // keep items before the index
                                { ...valueData[valueDataIndex], "tagValue":val },            // update the specific object
                                ...valueData.slice(valueDataIndex + 1)                   // keep items after the index
                            ]
                            // [...valueData,{"tagName":specKey, "tagValue":val}]
                        )  : [...valueData, {"tagName":specKey, "tagValue":val}], 

                        // "value": valueDataIndex === -1 && [...valueData, {"tagName":specKey, "tagValue":val}] , 
                        "field_id": id, 
                        "regex": regex, 
                        "label": label, 
                        "multipleValue": multipleValue, 
                        "required": required, 
                    }
                )
                // [].concat(africanCountries, europeanCountries)
            }
            else {
                keyDataArr.push({ "form_type": formType, "form_value": key, "value": [{"tagName":specKey, "tagValue":val}], "field_id": id, "regex": regex, "label": label, "multipleValue": multipleValue, "required": required, })
            }

            setPayloadDataArr(keyDataArr)
        }
        else {
            let keyDataIndex = payloadDataArr?.findIndex(item => ((item?.form_value === key) && (item?.field_id === (id))))

            if (keyDataIndex !== -1) {
                keyDataArr.splice(keyDataIndex, 1)
                keyDataArr.push({ "form_type": formType, "form_value": key, "value": val, "field_id": id, "regex": regex, "label": label, "multipleValue": multipleValue, "required": required, })
            }
            else {
                keyDataArr.push({ "form_type": formType, "form_value": key, "value": val, "field_id": id, "regex": regex, "label": label, "multipleValue": multipleValue, "required": required, })
            }

            setPayloadDataArr(keyDataArr)
        }

    }

    let validateForm = () => {
        console.log(payloadDataArr)

        if ((payloadDataArr?.length < 1) || (formStructure?.filter((val: any) => val?.required === true)?.length !== (payloadDataArr?.filter((val: any) => val?.required === true)?.length))
            ||
            (formStructure?.filter((val: any) => val?.required === true)?.length === (payloadDataArr?.filter((val: any) => val?.required === true)?.length)
                &&
                payloadDataArr.filter((val: any) => val?.required === true)?.some((val: any) => val?.value === "")
            )
        ) {

            setNotifTitle('Error')
            setNotif('Please fill in all necessary fields')
            setNotifVal(true)
            return
        }

        submitForm()
    }

    let submitForm = () => {
        const callback = (data: any) => {
            if (data?.status) {
                setFormPage(2)
            }
            else {
                setNotifTitle("Error")
                setNotif(data.detail)
                setNotifVal(true)
            }
        };
        let data: any = {
            values: {
                // request_id: requestId,
                // check_id: checkId,
                reference_id: refId,
                reference_data: payloadDataArr
            },
            callback,
        };
        dispatch(backgroundCheckRequestReferenceAnswerUploadRequest(data))
    }

    return (
        <div>
            {(notif && notifVal) && <NotificationToast title={notifTitle} message={notif} closeNotif={() => setNotifVal(!notifVal)} />}

            <div className="text-white py-4 mb-5" style={{ background: "#003E51" }}>
                <div className="container">
                    <h5>Complete Reference Form</h5>
                </div>
            </div>

            {backgroundCheckRequestReferenceInfoState?.isLoading && <Mainloader />}

            {!backgroundCheckRequestReferenceInfoState?.isLoading &&
                <div className="container">
                    <div className=' '>
                        {formPage === 1 &&
                            <div className="col-md-6 my-5 mx-auto">

                                {formStructure?.map((form: any, i: number) => (
                                    <div className="mt-2" key={i}>
                                        <label htmlFor={form?.label}>
                                            {form?.label}
                                            {form?.required &&
                                                <span style={{ color: "red" }}> * </span>
                                            }
                                        </label>
                                        {form?.type === "select" ?
                                            <>
                                                <select
                                                    name={form?.form_id}
                                                    className={`form-select`}
                                                    onChange={e => triggerKeyUpdate(form?.type, form?.fieldName, e?.target?.value, form?.fieldId, form?.regex, `${form?.label}`, "", false, (form?.required || false))}
                                                // value={getValueForKey(form?.key) || ""}
                                                >
                                                    <option value="">Select response</option>
                                                    {form?.options?.map((opt: any, k: number) => (
                                                        <option key={k} value={opt?.value}>{opt?.name}</option>
                                                    ))}
                                                </select>
                                            </>
                                            :
                                            form?.type === "textarea" ?
                                                <>
                                                    <textarea rows={3} placeholder={form?.placeholder}
                                                        className="form-control"
                                                        onChange={e => triggerKeyUpdate(form?.type, form?.fieldName, e?.target?.value, form?.fieldId, form?.regex, `${form?.label}`, "", false, (form?.required || false))}
                                                    // value={getValueForKey(form?.key) || ""}
                                                    ></textarea>
                                                </>
                                                :
                                                form?.type === "radio" ?
                                                    <>
                                                        {form?.options?.map((tagVal: any, ind: number) => (
                                                            <div className="col">
                                                                <input type="radio" className='me-2' id={tagVal} name={form?.tag} value={tagVal} />
                                                                <small>{tagVal}</small><br />
                                                            </div>
                                                        ))}
                                                    </>
                                                    :
                                                    form?.type === "groupedRadio" ?
                                                        <>
                                                            <div className="p-2 rounded-top shadow-sm mt-1" style={{background:"#eee"}}>
                                                                {/* <div className="card-body "> */}
                                                                    <div className="col-md-8 ms-auto">
                                                                        <div className="row">
                                                                            {form?.tagOptions?.map((tagOptionVal: any, ind: number) => (
                                                                                <div className="col" key={ind}>
                                                                                    <small>{tagOptionVal}</small><br />
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>

                                                                {/* </div> */}
                                                            </div>
                                                            {form?.options?.map((tagVal: any, ind: number) => (
                                                                <div className="card border-0 shadow-sm mt-1" key={ind}>
                                                                    <div className="card-body">
                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <small>{tagVal?.tag}</small>
                                                                            </div>
                                                                            <div className="col-md-8">
                                                                                <div className="row">
                                                                                    {form?.tagOptions?.map((tagValLevelTwo: any, idx: number) => (
                                                                                        <div className="col" key={idx}>
                                                                                            <input type="radio" className='me-2' id={tagValLevelTwo} name={tagVal?.tag} value={tagValLevelTwo}
                                                                                                onClick={e => triggerKeyUpdate(form?.type, form?.fieldName, tagValLevelTwo, form?.fieldId, form?.regex, `${form?.label}`, tagVal?.tag , false, (form?.required || false))}
                                                                                             />
                                                                                            {/* <small>{tagValLevelTwo}</small><br /> */}
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </>
                                                        :
                                                        <>
                                                            <input type={form?.type}
                                                                className={`form-control p-2`} placeholder={form?.placeholder}
                                                                name={form?.form_id}
                                                                onChange={e => triggerKeyUpdate(form?.type, form?.fieldName, e?.target?.value, form?.fieldId, form?.regex, `${form?.label}`, "", false, (form?.required || false))}
                                                            // value={getValueForKey(form?.key) || ""}
                                                            />
                                                        </>
                                        }
                                    </div>
                                ))}

                                <button className='btn btn-deep-green px-5 my-5 w-100' onClick={validateForm} >
                                    {(backgroundCheckRequestReferenceAnswerUploadState.isLoading) ? (
                                        <div>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    ) : (
                                        'Proceed'
                                    )}
                                </button>
                            </div>
                        }

                        {formPage === 2 &&
                            <div>
                                <div className="col-md-6 col-lg-4 mx-auto">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="main-modal-body text-center">
                                                <div className="col-md-6 mx-auto mt-4">
                                                    <Player
                                                        src={successVerifGif}
                                                        className="player"
                                                        loop
                                                        autoplay
                                                    />
                                                </div>
                                                <div className="my-5">
                                                    <h4>Thank You</h4>
                                                    <p className='my-3'>
                                                        You have successfully submitted your information.
                                                        {/* A summary notification has been forwarded to your email. */}
                                                        Please close this tab or proceed to our website
                                                        {window.location.host.includes("peleza") ?
                                                            <a href={"https://peleza.com"} className='link link-underline mx-1'>
                                                                here
                                                            </a>
                                                            :
                                                            <a href={"https://prembly.com/backgroundCheck"} className='link link-underline mx-1'>
                                                                here
                                                            </a>
                                                        }
                                                        to know more about our solutions.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {formPage ===  3 &&
                            <>
                                <EmptyStateComp
                                    title={'Form Error'}
                                    ctaAction={() => { }}
                                    desc={backgroundCheckRequestReferenceInfoState?.error || 'You are most likely seeing this error because you have entered an invalid link. Please reconfirm your link'}
                                    ctaValue={''}
                                />
                            </>
                        }

                        {/* {allForm?.length > 0 && (
                        <div className="col-md-5 mt-5">
                            <form onSubmit={e => e.preventDefault()} autoComplete="off" className=''>
                                {currentForm && currentForm[1]?.forms?.map((form: any, i: number) => (
                                    <span key={i}>
                                        {findFirstUniqueValue(currentForm[1]?.forms, "field_title")?.map((title: any, ind: number) => {
                                            if (title?.key === form?.key) {
                                                return (
                                                    <h5 className={ind !== 0 ? "mt-5" : ""} key={ind}>{title?.field_title}</h5>
                                                )
                                            }
                                        })}

                                        {findFirstUniqueValue(currentForm[1]?.forms, "newlyUnique")?.map((title: any, ind: number) => {
                                            if (title?.key === form?.key) {
                                                return (
                                                    <hr key={ind} className='mt-5' />
                                                )
                                            }
                                        })}


                                        <div className="mt-1" key={i}>
                                            <label htmlFor={form?.form_label}>
                                                {form?.form_value === "xds_form" &&
                                                    <>
                                                        {`${form?.form_label} ${form?.description ?
                                                            ("(" + (form?.description.substring(0, 91)))
                                                            : ""}`
                                                        }
                                                        {form?.description &&
                                                            <a style={{ color: "#0489E5" }} href={form?.description.substring(93, 175)} target='_blank' rel="noreferrer" className='link link-underline'>
                                                                View here
                                                            </a>
                                                        }
                                                        {")"}
                                                    </>
                                                }
                                                {form?.form_value !== "xds_form" &&
                                                    <>
                                                        {`${form?.form_label} ${form?.description ? ("(" + form?.description + ")") : ""}`}
                                                    </>
                                                }

                                                {form?.required === "True" &&
                                                    <span style={{ color: "red" }}> * </span>
                                                }
                                            </label>

                                            {form?.form_type === "select" ?
                                                <select
                                                    name={form?.form_id}
                                                    className={`form-select ${errorDataArr?.includes(form?.form_id) && "input-error"}`}
                                                    onChange={e => triggerKeyUpdate(currentForm[0], form?.endpoint, form?.form_type, form?.form_value, e?.target?.value, form?.form_id, form?.key, form?.regex, `${form?.form_label}`, "", (form?.newlyAdded || false), (currentForm[1]?.multiple || false), (form?.newlyUnique || ""), (form?.required || false))}
                                                    autoComplete="off"
                                                    // value={getValueForKey(currentForm[0], form?.form_value, form.form_id + i) || ""}
                                                    value={getValueForKey(form?.key) || ""}
                                                >
                                                    <option value="">Select {form?.form_label}</option>
                                                    {form?.select_value?.map((opt: any, k: number) => (
                                                        <option key={k} value={opt?.value}>{opt?.name}</option>
                                                    ))}
                                                </select>
                                                :
                                                form?.form_type === "file" ?
                                                    <div>
                                                        {!getValueForKey(form?.key) &&
                                                            <div className={`file-input-area p-2 ${errorDataArr?.includes(form?.form_id) && "input-error"}`}>
                                                                {(activeUploadingKey === form?.key) && uploadFileState?.isLoading ? (
                                                                    <div>
                                                                        <Spinner
                                                                            as="span"
                                                                            animation="border"
                                                                            size="sm"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        />
                                                                        <span className="sr-only">Uploading...</span>
                                                                    </div>
                                                                ) : (
                                                                    <div className="">
                                                                        <input type={form?.form_type}
                                                                            placeholder={form?.form_label}
                                                                            name={form?.form_id}
                                                                            onChange={e => {
                                                                                setActiveUploadingKey(form?.key)
                                                                                triggerKeyUpdate(currentForm[0], form?.endpoint, form?.form_type, form?.form_value, e, form?.form_id, form?.key, "", `${form?.form_label}`, "", (form?.newlyAdded || false), (currentForm[1]?.multiple || false), (form?.newlyUnique || ""), (form?.required || false))
                                                                            }
                                                                            }
                                                                            autoComplete="off"
                                                                            accept=".pdf, .png, .jpg, .jpeg"
                                                                            value={getValueForKey(form?.key) || ""}
                                                                            draggable={false}
                                                                        />
                                                                        <small>Maximum file size: 15MB</small>
                                                                        <small>Supported file types: (.pdf, .png, .jpg, .jpeg).</small>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        }

                                                        {getValueForKey(form?.key) &&
                                                            <div style={{ backgroundColor: "#eaeef6" }} className='py-2 px-3'>
                                                                <div className="row align-items-center">
                                                                    <div className="col-md-8 d-flex align-items-center">
                                                                        <i className="ri-image-2-line ri-xl me-2" />
                                                                        <span>
                                                                            <p className="p-0 m-0" style={{ fontSize: "14px" }}>{getValueForKey(form?.key)?.name}</p>
                                                                            <p className="p-0 m-0" style={{ fontSize: "13px", color: "#8D8D8D" }}>
                                                                                Size: {getValueForKey(form?.key).size / 1000} KB
                                                                            </p>
                                                                        </span>

                                                                    </div>
                                                                    <div className="col-md-4 d-flex justify-content-md-end">
                                                                        <span>
                                                                            <button className="btn btn-danger"
                                                                                onClick={e => triggerKeyUpdate(currentForm[0], form?.endpoint, form?.form_type, form?.form_value, "", form?.form_id, form?.key, "", `${form?.form_label}`, "", (form?.newlyAdded || false), (currentForm[1]?.multiple || false), (form?.newlyUnique || ""), (form?.required || false))}
                                                                                style={{ border: "None", borderRadius: "5px" }}
                                                                            >
                                                                                <span className="d-flex align-items-center">
                                                                                    <i className="ri-delete-bin-3-line ri-xl me-2 " />
                                                                                    Remove
                                                                                </span>
                                                                            </button>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                    :
                                                    form?.form_type === "tel" ?
                                                        // <div className="input-group mb-3">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <select className={`form-select ${errorDataArr?.includes(form?.form_id) && "input-error"}`} value={getValueForPhoneCountry(form?.key) || ""}
                                                                    style={{ borderRadius: "5px 0px 0px 5px", maxWidth: "150px" }}
                                                                    onChange={e => triggerKeyUpdate(currentForm[0], form?.endpoint, form?.form_type, form?.form_value, e.target.value, form?.form_id, form?.key, form?.regex, `${form?.form_label}`, "phoneCountryCode", (form?.newlyAdded || false), (currentForm[1]?.multiple || false), (form?.newlyUnique || ""), (form?.required || false))}
                                                                >
                                                                    {countryList?.sort((a: any, b: any) => a?.code - b?.code)?.map((country, i) => (
                                                                        <option key={i} value={country?.code} className='add-ellipsis'>
                                                                            {country?.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <input type={form?.form_type}
                                                                className={`form-control ${errorDataArr?.includes(form?.form_id) && "input-error"}`} placeholder={form?.form_label}
                                                                name={form?.form_id}
                                                                onChange={e => triggerKeyUpdate(currentForm[0], form?.endpoint, form?.form_type, form?.form_value, removeLetters(e.target.value), form?.form_id, form?.key, form?.regex, `${form?.form_label}`, "", (form?.newlyAdded || false), (currentForm[1]?.multiple || false), (form?.newlyUnique || ""), (form?.required || false))}
                                                                autoComplete="off"
                                                                // value={getValueForKey(currentForm[0], form?.form_value, form.form_id + i) || ""}
                                                                value={getValueForKey(form?.key) || ""}
                                                            />
                                                        </div>
                                                        :
                                                        <>
                                                            <input type={form?.form_type}
                                                                className={`form-control p-2 ${errorDataArr?.includes(form?.form_id) && "input-error"}`} placeholder={form?.form_label}
                                                                name={form?.form_id}
                                                                onChange={e => triggerKeyUpdate(currentForm[0], form?.endpoint, form?.form_type, form?.form_value, e.target.value, form?.form_id, form?.key, form?.regex, `${form?.form_label}`, "", (form?.newlyAdded || false), (currentForm[1]?.multiple || false), (form?.newlyUnique || ""), (form?.required || false))}
                                                                autoComplete="off"
                                                                value={getValueForKey(form?.key) || ""}
                                                            />
                                                        </>
                                            }

                                            {(errorDataArr?.includes(form?.form_id)) &&
                                                <small className='text-danger'>Missing field required</small>
                                            }
                                        </div>
                                    </span>
                                )
                                )}


                            </form>
                        </div>
                    )} */}

                    </div>
                </div>
            }
        </div>
    )
}
